import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ChooseReport.css";
import { IoIosCheckmarkCircle, IoIosCloseCircle} from "react-icons/io";
import Parse from "html-react-parser";
import { BsFillSkipEndCircleFill } from 'react-icons/bs';
import { useTranslation } from "react-i18next";

function ChooseReport({ data }) {

  const { t } = useTranslation();

  const chooseArray = JSON.parse(data?.QUS_OPTION);

  const correctAnswer = Object.values(JSON.parse(data?.QUS_CORRECT_RESPONSE));


  let arr = Object.values(JSON.parse(data.QUS_CORRECT_RESPONSE)).map((item) => {
    if (item.includes("src")) {
      let startIndex = item.indexOf("src") + 5;
      let endIndex = item.indexOf('"', startIndex);
      return (
        <img
          src={`https://test4you.me${item.substring(startIndex, endIndex)}`}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      );
   
    } else {
      return Parse(`${item}`);
    }
  });



  let str = data.QUESTION_TEXT;
  let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');


  let strAnswer = data.ANSWER_EXPLANATION;
  let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g,'src="https://test4you.me/');



  return (
    <div className="choose-question-report d-flex  justify-content-center">
      <Container>
        <div className="ques pt-5 pb-2">
          <div className="d-flex align-items-start justify-content-between head-ques">
            
          <h5 className="preview"> {data.QUESTION_TEXT.includes('src="/public')? Parse(`${newStr}`): Parse(`${data.QUESTION_TEXT}`)} </h5>

          {data.ResultSuccess === "correct" && (
              <p className="correct-tag">
                <IoIosCheckmarkCircle className="true-icon" /> {t("correct-answer")}
              </p>
            )}
            {data.ResultSuccess === "wrong" && (
              <p className="wrong-tag">
                <IoIosCloseCircle className="false-icon" /> {t("incorrect-answer")}
              </p>
            )}
            {data.ResultSuccess === "" && (
              <p className="skip-tag">
                <BsFillSkipEndCircleFill className='skip-icon' /> {t("question-skipped")}
              </p>
            )}
          </div>
      
          {data.QUESTION_FILE_NAME && (
            <audio
              src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${data.QUESTION_FILE_NAME}`}
              controls
            />
          )}

          <Row>
            {Object.values(chooseArray).map((item, i) => {
              if (item.includes("src")) {
                let startIndex = item.indexOf("src") + 5;
                let endIndex = item.indexOf('"', startIndex);
                return (
                  <Col key={i} md={3}>
                    <div
                      className={item == correctAnswer ? "true-check" :"form-check" && item == data.userAnswer ?"false-check" :"form-check"}
                      style={{ height: "100%", padding: "10px 0" }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="answer"
                        id={`flexRadioDefault$${i}`}
                        value={item}
                        
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexRadioDefault${i}`}
                        style={{ height: "100%" }}
                      >
                        <img
                          src={
                            item.includes('src="/public')
                              ? `https://test4you.me${item.substring(
                                  startIndex,
                                  endIndex
                                )}`
                              : `${item.substring(startIndex, endIndex)}`
                          }
                          alt=""
                          style={{ maxWidth: "100%" }}
                        />
                      </label>
                    </div>
                  </Col>
                );
              } else {
                return (
                  <Col key={i} md={6}>
                    <div className={item == correctAnswer ? "true-check" :"form-check" && item == data.userAnswer && data.ResultSuccess === "wrong" ? "false-check" :"form-check"}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="answer"
                        id={`flexRadioDefault${i}`}
                        value={item}
                      
                      />
                      <label
                        className="form-check-label mathLabel"
                        htmlFor={`flexRadioDefault${i}`}
                      >
                        {Parse(`${item}`)}
                      </label>
                    </div>
                  </Col>
                );
              }
            })}

            {data.AnsStatus === "done" && data.ResultSuccess === "correct" && (
              <>
                <div className="true-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className="explain-answer explain">
                    {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}
            {data.AnsStatus === "done" && data.ResultSuccess === "wrong" && (
              <>
                <div className="false-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className="explain-answer explain">
                    {data.ANSWER_EXPLANATION.includes('src="/public')
                      ? Parse(`${newStrAnswer}`)
                      : Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}

            
{data.AnsStatus === "skip" && data.ResultSuccess === "" && (
              <>
                <div className="skip-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className="explain-answer explain">
                    {data.ANSWER_EXPLANATION.includes('src="/public')
                      ? Parse(`${newStrAnswer}`)
                      : Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default ChooseReport;
