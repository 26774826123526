import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import Parse from "html-react-parser";
import { BsFillSkipEndCircleFill } from 'react-icons/bs';
import { useTranslation } from "react-i18next";

function MatchingReport({ data}) {

  const { t } = useTranslation();

  const userAnswerArray = data.userAnswer && JSON.parse(data.userAnswer);

    const chooseArray = JSON.parse(data?.QUS_OPTION)

    const optionsArray = Object.values(chooseArray)

    const [pairs, setPairs] = useState([]);


    const str = data.QUS_CORRECT_RESPONSE
    const filterStr = str.replace(/{/g, '[').replace(/}/g, ']').replace(/"[0-9]":/g, '');
    const endARRoptions = JSON.parse(filterStr);

    let str1 = data.QUESTION_TEXT;
    let newStr = str1.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');
  
  
    let strAnswer = data.ANSWER_EXPLANATION;
    let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g,'src="https://test4you.me/');

    return (
        <div className='choose-question-report d-flex  justify-content-center'>
            <Container >

                <div className='ques pt-5 pb-2' style={{ width: "100%" }}>
                <div className="d-flex align-items-start justify-content-between head-ques">
                <h5 className="preview"> {data.QUESTION_TEXT.includes('src="/public')? Parse(`${newStr}`): Parse(`${data.QUESTION_TEXT}`)} </h5>
  
                {data.ResultSuccess === "correct" && (
              <p className="correct-tag">
                <IoIosCheckmarkCircle className="true-icon" /> {t("correct-answer")}
              </p>
            )}
            {data.ResultSuccess === "wrong" && (
              <p className="wrong-tag">
                <IoIosCloseCircle className="false-icon" /> {t("incorrect-answer")}
              </p>
            )}
            {data.ResultSuccess === "" && (
              <p className="skip-tag">
                <BsFillSkipEndCircleFill className='skip-icon' /> {t("question-skipped")}
              </p>
            )}
            </div>

                    {data.QUESTION_FILE_NAME && (
                        <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${data.QUESTION_FILE_NAME}`} controls />
                    )}



                    <div className="matching-question-container">
                        <Row style={{ width: "100%" ,margin:"0"}}>

                            <Col md={4}>
                                <div className="options-container">
                                    {chooseArray.listname && <h5 className='head-matching'>{chooseArray.listname[0]}</h5>}
                                    {Object.values(chooseArray.list.columnA).map((item, i) => (
                                        <div
                                            key={i}
                                            className={pairs.map(p => p.leftOption).includes(item) ? "select-option" : "option-drag"}
                                            draggable
                                        >
                                         {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g, ' ')} </p> }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={4}>
                                <div
                                    className="matching-container"

                                >
                                    {chooseArray.listname && <h5 className='head-matching'>{chooseArray.listname[1]}</h5>}
                                    {Object.values(chooseArray.list.columnB).map((item, i) => (
                                        <div key={i} className={pairs.map(p => p.rightOption).includes(item) ? "select-option" : "option"}>
                                          {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g, ' ')} </p> }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={4}>
                                {userAnswerArray.length > 0 && (
                                    <div className="pairs-container">
                                        <h5 className='head-matching'>التوصيل</h5>
                                        {userAnswerArray.flatMap((pair, i) => (
                                            <div key={i} className={endARRoptions.map(el=>el.toString()).includes(pair.toString())  ? "pair match-option true-matching" :"pair match-option false-matching"}>
                                                <div>   <span>{pair.toString().replace(',', ' ⬅ ').replace(/\#/g, ' ')}</span>
                                                   </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>

                    {data.AnsStatus === "done" && data.ResultSuccess === "correct" && (
              <>
                <div className="true-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className='explain'>
                  {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}
            {data.AnsStatus === "done" && data.ResultSuccess === "wrong" && (
              <>
                <div className="false-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className='explain'>
                  {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}

            
{data.AnsStatus === "skip" && data.ResultSuccess === "" && (
              <>
                <div className="skip-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className='explain'>
                  {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}

                </div>

            </Container>
        </div>
    )
}

export default MatchingReport