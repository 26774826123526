import React from "react";
import "./BriefSection.css";
import arrowImg from "../../Assets/images/Sketch-annotation.png";
import trueImg from "../../Assets/images/true-card.png";
import hugeLeftImg from "../../Assets/images/Huge-icon11.png";
import hugeRightImg from "../../Assets/images/Huge-icon22.png";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BriefSection() {

  const { t } = useTranslation();

  return (
    <div className="brief-parent">
      <Container>
        <Container className="brief">
          <img src={arrowImg} alt="" />
          <div className="info-sec">
            <h5> {t("ready-to-exam")} </h5>
            <p>
              {t("ready-info")}
            </p>
            <div className="d-flex align-items-center justify-content-center my-4">
              <Link to="/register">
                <button className="mx-3 create-btn"> {t("free-register")} </button>
              </Link>
              <Link to="/exams">
                <button className="mx-3 try-btn"> {t("browse-exam")} </button>
              </Link>
            </div>
          </div>
        </Container>
        <Container className="parent-sec">
          <img className="huge1" src={hugeLeftImg} alt="" />
          <img className="huge2" src={hugeRightImg} alt="" />
          <Row className="allCards-stat">
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title> {t("most-question")} </Card.Title>
                  <Card.Text>
                    {t("most-description")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title> {t("true-question")} </Card.Title>
                  <Card.Text>
                    {t("true-description")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Card.Title> {t("personal-repo")} </Card.Title>
                  <Card.Text>
                    {t("personal-description")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default BriefSection;
