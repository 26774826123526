import React from 'react'
import "./ReportSummary.css"
import { Col, Container, Row } from 'react-bootstrap'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend, PointElement, LineElement, Filler, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { PolarArea, Radar, Bar ,Pie} from 'react-chartjs-2';
import clockImg from "../../Assets/images/time _clock.png"
import ProgressProvider from "../ProgressProviderAnimation/ProgressProvider";
import { useTranslation } from "react-i18next";
import { FaUserPen } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import Loader from '../Loader/Loader';


function ReportSummary({ reportDataQues, reportDataPublic, reportDataTime, dataCombined, leoCombined, activeLeoBtn, handleShowLeo, loadingComb, levelName,mainExamData ,levelDescription}) {

    const { t } = useTranslation();

    const examType = localStorage.getItem("yaqees-examType");

    const userName = JSON.parse(localStorage.getItem("yaqees-user"))?.username;

    const time = reportDataTime;

    const sumQuesMarks = reportDataQues.map(item => item.QUES_MARK).reduce(function (x, y) {
        return +x + +y;
    }, 0);

    const QuesMarksCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").map(item => item.QUES_MARK).reduce(function (x, y) {
        return +x + +y;
    }, 0);

    const timeAvrage = Math.round((+(reportDataTime[0] * 3600) + +(reportDataTime[1] * 60) + +(reportDataTime[2])) / reportDataQues.length);
    
    const timeAvrageCombined = Math.round((+(dataCombined?.time?.split(":")[0] * 3600) + +(dataCombined?.time?.split(":")[1] * 60) + +(dataCombined?.time?.split(":")[2])) / mainExamData.question_count)

    const percentage = reportDataPublic.degree;

    const allQues = reportDataQues.length
    const correct = reportDataQues.filter(item => item.ResultSuccess === "correct").length
    const wrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").length
    const skip = reportDataQues.filter(item => item.ResultSuccess === "").length

    const correctAnswersPercentage = Math.round((correct / allQues) * 100);
    const wrongAnswersPercentage = Math.round((wrong / allQues) * 100);
    const skipAnswersPercentage = Math.round((skip / allQues) * 100);


    const veryEasyCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "1").length
    const veryEasyWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "1").length
    const veryEasySkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "1").length
    const allVeryEasy = reportDataQues.filter(item => item.DIFFICULTY === "1").length

    const easyCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "2").length
    const easyWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "2").length
    const easySkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "2").length
    const allEasy = reportDataQues.filter(item => item.DIFFICULTY === "2").length

    const normalCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "3").length
    const normalWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "3").length
    const normalSkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "3").length
    const allNormal = reportDataQues.filter(item => item.DIFFICULTY === "3").length

    const hardCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "4").length
    const hardWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "4").length
    const hardSkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "4").length
    const allHard = reportDataQues.filter(item => item.DIFFICULTY === "4").length

    const veryHardCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "5").length
    const veryHardWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "5").length
    const veryHardSkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "5").length
    const allVeryHard = reportDataQues.filter(item => item.DIFFICULTY === "5").length


    const remember = reportDataQues.filter(item => item.COGNITIVE_ID === "10").length
    const understand = reportDataQues.filter(item => item.COGNITIVE_ID === "11").length
    const analysis = reportDataQues.filter(item => item.COGNITIVE_ID === "13").length
    const installation = reportDataQues.filter(item => item.COGNITIVE_ID === "14").length
    const application = reportDataQues.filter(item => item.COGNITIVE_ID === "12").length
    const call = reportDataQues.filter(item => item.COGNITIVE_ID === "6").length
    const skills = reportDataQues.filter(item => item.COGNITIVE_ID === "7").length
    const strategic = reportDataQues.filter(item => item.COGNITIVE_ID === "8").length
    const extended = reportDataQues.filter(item => item.COGNITIVE_ID === "9").length
    const calendar = reportDataQues.filter(item => item.COGNITIVE_ID === "15").length
    const creativity = reportDataQues.filter(item => item.COGNITIVE_ID === "16").length
    const innovation = reportDataQues.filter(item => item.COGNITIVE_ID === "17").length

    const rememberTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "10").length
    const understandTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "11").length
    const analysisTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "13").length
    const installationTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "14").length
    const applicationTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "12").length
    const callTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "6").length
    const skillsTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "7").length
    const strategicTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "8").length
    const extendedTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "9").length
    const calendarTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "15").length
    const creativityTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "16").length
    const innovationTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "17").length


    ChartJS.register(RadialLinearScale, ArcElement, PointElement, LineElement, CategoryScale, LinearScale, BarElement, Filler, Tooltip, Legend);
    ChartJS.defaults.font.family = "'Noto Naskh Arabic', sans-serif";
    ChartJS.defaults.font.weight = "bold";

    const data = {
        labels: [` ${t("correct-answers")} ${correct}`, ` ${t("incorrect-answers")} ${wrong}`, ` ${t("skip-done")} ${skip}`],
        datasets: [
            {
                data: [`${correctAnswersPercentage}`, `${wrongAnswersPercentage}`, `${skipAnswersPercentage}`],
                backgroundColor: [
                    '#4DB847',
                    '#ED254E',
                    '#D9D9D9',
                ],
                borderWidth: 3,
            },
        ],
    };


    const data3 = {
        labels: [` ${t("very-easy")} (${allVeryEasy})`, ` ${t("easy")} (${allEasy})`, ` ${t("medium")} (${allNormal})`, ` ${t("hard")} (${allHard})`, ` ${t("very-hard")} (${allVeryHard})`],
        datasets: [
            {
                label: ` ${t("correct-answer")} `,
                data: [veryEasyCorrect, easyCorrect, normalCorrect, hardCorrect, veryHardCorrect],
                backgroundColor: '#4DB847',
            },
            {
                label: ` ${t("incorrect-answer")} `,
                data: [veryEasyWrong, easyWrong, normalWrong, hardWrong, veryHardWrong],
                backgroundColor: '#ED254E',
            },
            {
                label: ` ${t("skip-done")} `,
                data: [veryEasySkip, easySkip, normalSkip, hardSkip, veryHardSkip],
                backgroundColor: '#D9D9D9',
            },
        ],
    };


    const rememberData = remember > 0 ? remember : null;
    const rememberLabel = remember > 0 ? ` ${t("remember")} ` : null;

    const understandData = understand > 0 ? understand : null;
    const understandLabel = understand > 0 ? ` ${t("understand")} ` : null;

    const applicationData = application > 0 ? application : null;
    const applicationLabel = application > 0 ? ` ${t("implementation")} ` : null;

    const analysisData = analysis > 0 ? analysis : null;
    const analysisLabel = analysis > 0 ? ` ${t("analysis")} ` : null;

    const installationData = installation > 0 ? installation : null;
    const installationLabel = installation > 0 ? ` ${t("composition")} ` : null;

    const calendarData = calendar > 0 ? calendar : null;
    const calendarLabel = calendar > 0 ? ` ${t("correction")} ` : null;

    const creativityData = creativity > 0 ? creativity : null;
    const creativityLabel = creativity > 0 ? ` ${t("creativity")} ` : null;

    const innovationData = innovation > 0 ? innovation : null;
    const innovationLabel = innovation > 0 ? ` ${t("innovation")} ` : null;

    const callData = call > 0 ? call : null;
    const callLabel = call > 0 ? ` ${t("summoning")} ` : null;

    const extendedData = extended > 0 ? extended : null;
    const extendedLabel = extended > 0 ? ` ${t("extended-thinking")} ` : null;

    const skillsData = skills > 0 ? skills : null;
    const skillsLabel = skills > 0 ? ` ${t("concepts-skills")} ` : null;

    const strategicData = strategic > 0 ? strategic : null;
    const strategicLabel = strategic > 0 ? ` ${t("strategic-thinking")} ` : null;

    const data4 = {
        labels: [`${rememberLabel} (${rememberData})`, `${understandLabel} (${understandData})`, `${applicationLabel} (${applicationData})`, `${analysisLabel} (${analysisData})`, `${installationLabel} (${installationData})`, `${calendarLabel} (${calendarData})`, `${creativityLabel} (${creativityData})`, `${innovationLabel} (${innovationData})`, `${callLabel} (${callData})`, `${extendedLabel} (${extendedData})`, `${skillsLabel} (${skillsData})`, `${strategicLabel} (${strategicData})`].filter(label => label !== `${null} (${null})`),
        datasets: [
            {
                data: [rememberTrue, understandTrue, applicationTrue, analysisTrue, installationTrue, calendarTrue, creativityTrue, innovationTrue, callTrue, extendedTrue, skillsTrue, strategicTrue].filter(label => label !== null),
                backgroundColor: '#005C9A',
            },
        ],
    };

    const data5 = {
        labels: [` ${t("correct-answers")} ${mainExamData.question_correct}`, ` ${t("incorrect-answers")} ${mainExamData.question_wrong }`, ` ${t("skip-done")} ${mainExamData.question_skipped}`],
        datasets: [
            {
                data: [`${Math.round((mainExamData.question_correct / mainExamData.question_count) * 100)}`, `${Math.round((mainExamData.question_wrong / mainExamData.question_count) * 100)}`, `${Math.round((mainExamData.question_skipped / mainExamData.question_count) * 100)}`],
                backgroundColor: [
                    '#4DB847',
                    '#ED254E',
                    '#D9D9D9',
                ],
                borderWidth: 3,
            },
        ],
    };


    return (
        <div className='test-report'>
            <Container>
                <div className='head-title-repo my-3'>
            <div className="textLine mb-3">
        <h5 className="headTitle"> {t("summary")} </h5>
        <hr className="hrLine" />
      </div>

      <div className="card mt-3 mb-4">
        <div className="card-body">
        <p className='repo-desc'> تحليل أداء الطالب وفقا للأهداف المعرفية ومستويات سهولة و صعوبة الأسئلة . </p>
        </div>
      </div>
      </div>
                {examType === "combined" ? <><Row className='combined-repo mt-2'>
                    <Col md={12} className='mb-3'>
                        <h5> <span> <FaUserPen /> </span> {userName} </h5>
                        <h6> <span> <MdDateRange /> </span> {new Date(dataCombined.date).toLocaleDateString("ar-EG", {
                            weekday: 'long',
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                            timeZone: 'Africa/Cairo'
                        })}</h6>
                    </Col>
                    <Col md={9}>
                        <div className='global-result mb-4'>
                            <div className='bord-btm'>
                                <h4> مستوي الطالب : {dataCombined.degree > 0 && dataCombined.degree <= 10 ? `المبتدئ الأدني` : dataCombined.degree > 11 && dataCombined.degree <= 20 ? `المبتدئ الأوسط` : dataCombined.degree > 21 && dataCombined.degree <= 30 ? `المبتدئ الأعلي` : dataCombined.degree > 31 && dataCombined.degree <= 40 ? `المتوسط الأدني` : dataCombined.degree > 41 && dataCombined.degree <= 50 ? `المتوسط الأوسط` : dataCombined.degree > 51 && dataCombined.degree <= 60 ? `المتوسط الأعلي` : dataCombined.degree > 61 && dataCombined.degree <= 70 ? `المتقدم الأدني` : dataCombined.degree > 71 && dataCombined.degree <= 80 ? `المتقدم الأوسط` : dataCombined.degree > 81 && dataCombined.degree <= 90 ? `المتقدم الأعلي` : dataCombined.degree > 91 && dataCombined.degree <= 100 ? `المتميز (المتقن)` : "" } </h4>
                            </div>

                            <div className='row m-2 align-items-stretch my-3'>
                                <div className='col-lg-2 bord-lft'>
                                    <div className='all-degree' style={{ background: dataCombined.degree >= 0 && dataCombined.degree < 50 ? `#E80C53` : dataCombined.degree >= 50 ? `#4DB847` : "" }}>
                                        <div className='sec1'>
                                            <p className='text-center py-2'> المجموع النهائي </p>
                                        </div>
                                        <p className='text-center py-2 deg-num'> % {dataCombined.degree} </p>
                                    </div>
                                </div>
                                <div className='col-lg-10'>
                                    <div className='row align-items-stretch justify-content-center g-5'>
                                        {dataCombined?.placement_exam?.map(item => (
                                            <div className='col-lg-2' key={item.id}>
                                                <div className='curs-comb' onClick={() => handleShowLeo(item.attempt_code, item?.info?.main_title ,item.exam_id ,item.degree)}>
                                                    <CircularProgressbar
                                                        minValue
                                                        value={item.degree}
                                                        text={`${item.degree}%`}
                                                        strokeWidth={7}
                                                        styles={buildStyles({
                                                            pathColor: item.degree > 0 && item.degree < 50 ? `#E80C53` : item.degree >= 50 ? `#4DB847` : "",
                                                            textColor: "#1B1A1D",
                                                            trailColor: "#d6d6d67d",
                                                        })}
                                                        className="circle-perc"
                                                    />
                                                    <p className={activeLeoBtn === item.attempt_code ? 'm-0 mt-2 circle-text-active' : 'm-0 mt-2 circle-text'}> {item?.info?.main_title} </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {loadingComb ? <Loader /> :
                            <div className='learn-outcome global-result mb-4'>
                                <div className='bord-btm'>
                                    <h4> {levelName} <span> {levelDescription && `( ${levelDescription?.title} )`}  </span> </h4>
                                    <p> {levelDescription && levelDescription.description} </p>
                                </div>
                                {leoCombined.length > 0 ? leoCombined.map(item => (
                                    <div className='global-result d-flex align-items-center m-3'>
                                        <p className='m-2 w-100'> {item.lo_name} </p>
                                        <div class="progress  w-100" role="progressbar" aria-label="Example with label" aria-valuenow={item.degree} aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-bar" style={{ width: `${item.degree}%` , background: item.degree > 0 && item.degree < 50 ? `#E80C53` : item.degree >= 50 ? `#4DB847` : "" }}> % {item.degree} </div>
                                        </div>
                                    </div>
                                )) : <div> <p className='notfound-leo'> لاتوجد مهارات لهذا المستوي </p> </div>}

                            </div>
                        }
                    </Col>

                    <Col md={3}>
                        <div className='analysis-charts time-acc repo-chart mb-3'>
                            <div>
                                <h5> {t("time-investment")} </h5>
                                <p> {t("description-time")} </p>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={clockImg} alt="" />
                            </div>
                            <div className='time-test-info'>
                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <h6> {t("time-taken")} </h6>
                                    <h5>{dataCombined?.time?.split(":")[1]} {t("minute")}  {dataCombined?.time?.split(":")[2]} {t("second")}  </h5>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mt-3'>
                                    <h6> {t("average-time")} </h6>
                                    <h5>{timeAvrage} {t("second")}</h5>
                                </div>
                            </div>
                        </div>

                        <div className='analysis-charts time-acc repo-chart mb-3'>
                            <div>
                                <h5> {t("performance-analysis")} </h5>
                                <p> {t("description-preformance")} </p>
                            </div>
                            <hr />
                        
                            <Pie data={data5} options={{
                                    plugins: {
                                        legend: {
                                            labels: {
                                                font: {
                                                    family: "'Noto Naskh Arabic', sans-serif",
                                                },
                                                color: "black",
                                            },
                                            position: "bottom"
                                        }
                                    },
                                }}/>
                        </div>
                    </Col>
                </Row> </> : <Row>
                    <Col md={4}>
                        <div className='analysis-charts repo-chart'>
                            <div>
                                <h5> {t("degree")} </h5>
                                <p> {t("description-degree")} </p>
                            </div>
                            <hr />
                            <div style={{ width: 200, margin: "40px auto" }}>
                                <ProgressProvider valueStart={0} valueEnd={percentage}>
                                    {value => <CircularProgressbar minValue value={value} text={`${value}%`} strokeWidth={8} styles={buildStyles({ pathColor: `#005C9A`, textColor: '#1B1A1D', trailColor: '#d6d6d67d' })} />}
                                </ProgressProvider>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p> {t("your-grades")} <span>{QuesMarksCorrect}</span></p>
                                <p> {t("highest-degree")} <span>{sumQuesMarks}</span></p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='analysis-charts repo-chart'>
                            <div>
                                <h5> {t("performance-analysis")} </h5>
                                <p> {t("description-preformance")} </p>
                            </div>
                            <hr />
                            <div>
                                <PolarArea data={data} options={{
                                    plugins: {
                                        legend: {
                                            labels: {
                                                font: {
                                                    family: "'Noto Naskh Arabic', sans-serif",
                                                },
                                                color: "black",
                                            },
                                            position: "bottom"
                                        }
                                    },
                                }} />
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='analysis-charts time-acc repo-chart'>
                            <div>
                                <h5> {t("time-investment")} </h5>
                                <p> {t("description-time")} </p>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={clockImg} alt="" />
                            </div>
                            <div className='time-test-info'>
                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <h6> {t("time-taken")} </h6>
                                    <h5>{time[1]} {t("minute")}  {time[2]} {t("second")}  </h5>
                                </div>

                                <div className='d-flex align-items-center justify-content-between mt-3'>
                                    <h6> {t("average-time")} </h6>
                                    <h5>{timeAvrage} {t("second")}</h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className='analysis-charts ques-degre-chart'>
                            <div>
                                <h5> {t("difficulty-degree")} </h5>
                                <p> {t("description-difficulty")} </p>
                            </div>
                            <hr />
                            <div>
                                <Bar data={data3} options={{
                                    maintainAspectRatio: false,
                                    devicePixelRatio: 2,
                                    fill: true,
                                    plugins: {
                                        legend: {
                                            labels: {
                                                font: {
                                                    family: "'Noto Naskh Arabic', sans-serif",
                                                },
                                                color: "black",
                                            },
                                            rtl: true,
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                color: "black",
                                            },
                                        },
                                        y: {
                                            ticks: {
                                                color: "black",
                                            },
                                        }
                                    }

                                }} />
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='analysis-charts ques-degre-chart level-chart'>
                            <div>
                                <h5> {t("cognitive-level")} </h5>
                                <p> {t("description-cognitive")} </p>
                            </div>
                            <hr />
                            <div>
                                <Bar data={data4} options={{
                                    maintainAspectRatio: false,
                                    devicePixelRatio: 2,
                                    fill: true,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                color: "black",
                                            },
                                        },
                                        y: {
                                            ticks: {
                                                color: "black",
                                            },
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                    </Col>
                </Row>}

            </Container>
        </div>
    )
}

export default ReportSummary
