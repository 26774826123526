import React from 'react'
import "./SubscripSection.css"
import { Container } from 'react-bootstrap'
import img1 from "../../Assets/images/Sketch-1.png"
import img2 from "../../Assets/images/Sketch-2.png"
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

function SubscripSection() {

  const { t } = useTranslation();

  return (
    <div className='subscrip-parent'>
      <Container>
        <div className='subscrip'>
          <img src={img1} alt='' />
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <h5 className='my-4'> {t("yaqees-subscribe")} </h5>
            <div className='my-5 btns'>
              <Link to="/register">
                <button className='mx-2 create-account-btn'> {t("free-register")} </button>
              </Link>
              <Link to="/exams">
              <button className='mx-2 try-exam-btn'> {t("browse-exam")} </button>
              </Link>
            </div>
          </div>
          <img src={img2} alt='' />
        </div>
      </Container>
    </div>
  )
}

export default SubscripSection
