import React, { useState ,useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'
import {  IoIosCloseCircle } from 'react-icons/io';
import Parse from "html-react-parser";


function MatchingSkip({ showQuestion, checked, setChecked ,setSelectedAnswer ,setCorrectOptions}) {

    const chooseArray = JSON.parse(showQuestion?.QUS_OPTION)

    const [pairs, setPairs] = useState([]);


    const handleDragStart = (event, leftOption) => {
        event.dataTransfer.setData('text/plain', leftOption);
    };

    const handleDrop = (event, item) => {
        event.preventDefault();
        const leftOption = event.dataTransfer.getData('text/plain');
        const rightOption = item;
        const updatedPairs = [...pairs, { leftOption, rightOption }];
        const pairExists = pairs.map(p => p.leftOption);
        const pairExists2 = pairs.map(p => p.rightOption);
        if (pairExists.includes(leftOption) || pairExists2.includes(rightOption)) {
            // Push the pair into the pairs array only if it doesn't already exist
            return null
        } else {
            setPairs(updatedPairs);
            setSelectedAnswer(JSON.stringify(updatedPairs.map(item => [[item.leftOption], [item.rightOption]])));
            setCorrectOptions(!endARRoptions.map(element =>updatedPairs.map(item => [[item.leftOption], [item.rightOption]]).toString().includes(element.toString())).includes(false) ? "true" :"");
        }

    };



    const handleDragOver = event => {
        event.preventDefault();
    };

    const removeItem = (id) => {
        const filter = pairs.filter(item => item.leftOption !== id);

        setPairs(filter)
        setSelectedAnswer(JSON.stringify(filter));

    }
    const str = showQuestion.QUS_CORRECT_RESPONSE
    const filterStr = str.replace(/{/g, '[').replace(/}/g, ']').replace(/"[0-9]":/g, '');
    const endARRoptions = JSON.parse(filterStr);

    let str1 = showQuestion.QUESTION_TEXT
    let newStr = str1.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

    useEffect(() => {

        const elements2 = document.getElementsByClassName("preview");
    
        const elements3 = document.getElementsByClassName("mathLabel");
    
        for (let i = 0; i < elements2.length; i++) {
          const element = elements2[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
        }
    
        for (let i = 0; i < elements3.length; i++) {
          const element = elements3[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
    
        }
    
    
      }, []);
    
    return (
                <div className='ques pb-2' style={{ width: "100%" }}>
       <h5>{showQuestion.QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${showQuestion.QUESTION_TEXT}`)}</h5>

                    {showQuestion.QUESTION_FILE_NAME && (
                        <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${showQuestion.QUESTION_FILE_NAME}`} controls />
                    )}



                    <div className="matching-question-container">
                        <Row style={{ width: "100%" }}>

                            <Col md={4}>
                                <div className="options-container">
                                    {chooseArray.listname && <h5 className='head-matching'>{chooseArray.listname[0]}</h5>}
                                    {Object.values(chooseArray.list.columnA).map((item, i) => (
                                        <div
                                            key={i}
                                            className={pairs.map(p => p.leftOption).includes(item) ? "select-option" : "option-drag"}
                                            draggable
                                            onDragStart={event => handleDragStart(event, item)}
                                        >
                                       {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g, ' ')} </p> }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={4}>
                                <div
                                    className="matching-container"

                                    onDragOver={event => handleDragOver(event)}
                                >
                                    {chooseArray.listname && <h5 className='head-matching'>{chooseArray.listname[1]}</h5>}
                                    {Object.values(chooseArray.list.columnB).map((item, i) => (
                                        <div key={i} className={pairs.map(p => p.rightOption).includes(item) ? "select-option" : "option"} onDrop={event => handleDrop(event, item)}>
                                       {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g, ' ')} </p> }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={4}>
                                {pairs.length > 0 && (
                                    <div className="pairs-container">
                                        <h5 className='head-matching'>التوصيل</h5>
                                        {pairs.map((pair, i) => (
                                            <div key={i} className="pair match-option">
                                                <div>   <span>{pair.leftOption.toString().includes("jpg") || pair.leftOption.toString().includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${pair.leftOption.toString().replace(/\#/g,'')}`} alt="" />  :  <p> {pair.leftOption.toString().replace(/\#/g,'')} </p> }</span>
                                                    <span className="pair-arrow"> ⬅ </span>
                                                    <span>{pair.rightOption.toString().includes("jpg") || pair.rightOption.toString().includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${pair.rightOption.toString().replace(/\#/g,'')}`} alt="" />  :  <p> {pair.rightOption.toString().replace(/\#/g,'')} </p> }</span></div>
                                                <IoIosCloseCircle onClick={() => removeItem(pair.leftOption)} className='cancel-match-icon' />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
    )
}

export default MatchingSkip