import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "./NavBarr.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/images/logo.png";
import { useTranslation } from "react-i18next";
import LanguageButton from "../LanguageButton/LangaugeButton"

function NavBarr() {
  const [navbar, setNavbar] = useState(false);

  const { t } = useTranslation();

  const changeNavColor = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeNavColor();
    window.addEventListener("scroll", changeNavColor);
  });

  const location = useLocation();


  return (
    <Navbar expand="lg" className={navbar ? "navbar-dark" : "navbar"}>
      <Container>
        <Link className="mob-logo" to="/">
          <img className="logo" src={logo} alt="" />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Link className="not-mob-logo" to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <Nav className="d-flex justify-content-center">
            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/" ? "active-link" : "links"}
                to="/"
              >
                {t("home")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/exams" ? "active-link" : "links"}
                to="/exams"
              >
                {t("all-exams")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/packages" ? "active-link" : "links"}
                to="/packages"
              >
                {t("combined-tests")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/blog" ? "active-link" : "links"}
                to="/blog"
              >
                {t("blog")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/about" ? "active-link" : "links"}
                to="/about"
              >
                {t("about")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/contact-us" ? "active-link" : "links"}
                to="/contact-us"
              >
                {t("contact-us")}
              </Link>
            </div>
          </Nav>
          <div className="d-flex align-items-center justify-content-between">
            <LanguageButton />
            <div className="d-flex align-items-center">
              <Link to="/login">
                <button className="create-account"> {t("login")} </button>
              </Link>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarr;
