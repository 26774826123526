import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { MdAccessTime } from "react-icons/md";
import { MdOutlineCheckCircleOutline ,MdRadioButtonUnchecked ,MdRadioButtonChecked } from 'react-icons/md';
import Timer from '../Components/Timer/Timer';
import { addTestAnswers } from '../Redux/Reducers/reviewsAnswerSlice';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import axios from "axios";
import ChooseQues from '../Components/QuestionsTypes/ChooseQues/ChooseQues'
import TrueFalseQues from '../Components/QuestionsTypes/TrueFalseQues/TrueFalseQues';
import MultiChooseQues from '../Components/QuestionsTypes/MultiChooseQues/MultiChooseQues';
import PickFromListQues from '../Components/QuestionsTypes/PickFromListQues/PickFromListQues';
import DragDropQues from '../Components/QuestionsTypes/DragDropQues/DragDropQues';
import OrdaringQues from '../Components/QuestionsTypes/OrdaringQues/OrdaringQues';
import MatchingQues from '../Components/QuestionsTypes/MatchingQues/MatchingQues';
import { useTranslation } from "react-i18next";

function TestQuestions() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [changeBtn, setChangeBtn] = useState(1);

    const [data, setData] = useState([]);

    const [selectedAnswer, setSelectedAnswer] = useState('')

    const [checked, setChecked] = useState("");

    const [index, setIndex] = useState(0);

    const [loading, setLoading] = useState(false);

    const [showAnswer, setShowAnswer] = useState(false);

    const [quesNumber, setQuesNumber] = useState(index + 1);

    const progressValue = quesNumber / data?.length * 100;

    const [correctOptions, setCorrectOptions] = useState("");

    const [storeQuestions, setStoreQuestions] = useState([]);

    const [pairs, setPairs] = useState([]);

    const [combinedExamData, setCombinedExamData] = useState([]);

    const [showQuestionSection, setShowQuestionSection] = useState(true);

    const { t } = useTranslation();

    const examId = localStorage.getItem("yaqees-examId")

    const examTitle = localStorage.getItem("yaqees-examTitle")

    const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

    const examType = localStorage.getItem("yaqees-examType")

    const examLevelsData = JSON.parse(localStorage.getItem("yaqees-examLevels"))

    localStorage.removeItem("yaqees-disabled")

    useEffect(() => {
        examType === "combined" ? (async () => {
            try {
                setLoading(true);
                const getLevelId = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/GetCombinedLevelAndAttempt/${examId}`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Username : "administrator",
                        Password : "yaqess123456",
                        Authorization: "Bearer " + token,
                        token: token,
                    },
                });
                const result = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/examPlacementQuestion?id=${getLevelId.data.data.levels[0].placement_level_id}&exam_attempt=${getLevelId.data.data.attempt_id}`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Username : "administrator",
                        Password : "yaqess123456",
                        Authorization: "Bearer " + token,
                        token: token,
                    },
                });
                localStorage.setItem("yaqees-examAttempt" ,getLevelId.data.data.attempt_id)
                localStorage.setItem("yaqees-levelAttempt" ,result.data.data.level_attempt)
                setData(result.data.data.questions);
                setCombinedExamData(getLevelId.data.data.levels)
                localStorage.setItem("yaqees-levels-length",getLevelId.data.data.levels.length)
                setLoading(false);
            } catch (err) {
            }
        })() : (async () => {
            try {
                setLoading(true);
                const result = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/exam-question/${examId}`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Username : "administrator",
                        Password : "yaqess123456",
                        Authorization: "Bearer " + token,
                        token: token,
                    },
                });
                localStorage.setItem("yaqees-examAttempt" ,result.data.data.ExamAttempt)
                setData(result.data.data.questions);
                setCombinedExamData([])
                setLoading(false);
            } catch (err) {
            }
        })()

    }, []);



    const handleNext = () => {

        setStoreQuestions([...storeQuestions, Object.assign(data[index], { status: "done" }, { userAnswer: selectedAnswer }, { result: selectedAnswer === correctOptions || JSON.stringify(selectedAnswer) === correctOptions || correctOptions === "true" ? "correct" : "wrong" })])
        setShowQuestionSection(false);
        if (data.length - 1 === index) {
            navigate("/review-exam-answers")
            dispatch(addTestAnswers([...storeQuestions, Object.assign(data[data.length - 1], { status: "done" }, { userAnswer: selectedAnswer }, { result: selectedAnswer === correctOptions || JSON.stringify(selectedAnswer) === correctOptions || correctOptions === "true" ? "correct" : "wrong" })]))
        } else {
            setChangeBtn(1);
            setIndex(index + 1);
            setQuesNumber(quesNumber + 1)
            setChecked("");
            setShowAnswer(false);
            setSelectedAnswer("")
            setCorrectOptions("")
            setPairs([])
            const parentElement = document.getElementById("drop-target");
            if (parentElement) {
                while (parentElement.firstChild) {
                  parentElement.removeChild(parentElement.firstChild);
                }
              }else{
                return null
              }
        }

    }

    const handleSkip = () => {
        setStoreQuestions([...storeQuestions, Object.assign(data[index], { status: "skip" }, { userAnswer: "" }, { result: "" })])

        if (data.length - 1 === index) {
            navigate("/review-exam-answers")
            dispatch(addTestAnswers([...storeQuestions, Object.assign(data[data.length - 1], { status: "skip" }, { userAnswer: "" }, { result: "" })]))
        } else {
            setIndex(index + 1);
            setQuesNumber(quesNumber + 1)
        }
        setShowQuestionSection(false);
    }


    const handleBack = () => {
        if (index > 0) {
            setIndex(index - 1);
            setQuesNumber(quesNumber - 1)
            storeQuestions.splice(-1)
            setChangeBtn(1)
            setChecked("");
            setSelectedAnswer("")
            setCorrectOptions("")
        } else {
            return null
        }

    }


    return (
        <div className='test-question'  style={{fontFamily: "Noto Naskh Arabic"}}>
            {loading ? <div className='d-flex align-items-center justify-content-center parent-spinner'><div className="spinner">  <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div></div></div> : <>  <div className='head-question'>
                    <Container className=' align-items-center justify-content-between header-ques'>
                        <div className='d-flex align-items-center justify-content-between parent-timer'>
                            <MdAccessTime className='timer-icon' />
                            <Timer />
                        </div>
                        <div className='header-title'><h5>{examTitle.length > 70 ? examTitle.substring(0, 70) + " .... " : examTitle} </h5> {combinedExamData && <p style={{marginTop: "5px",textAlign: "center"}}>{combinedExamData[0]?.info?.main_title}</p>}  </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p> {t("question")} <span>{quesNumber}</span> </p>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: `${progressValue}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p> {t("from")} <span>{data?.length}</span> </p>
                        </div>
                    </Container>
                </div>
                {data[index]?.QUS_TYPE === 5 && <ChooseQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                {data[index]?.QUS_TYPE === 8 && <TrueFalseQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                {data[index]?.QUS_TYPE === 6 && <MultiChooseQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                {data[index]?.QUS_TYPE === 10 && <PickFromListQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                {data[index]?.QUS_TYPE === 11 && <DragDropQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                {data[index]?.QUS_TYPE === 7 && <OrdaringQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                {data[index]?.QUS_TYPE === 4 && <MatchingQues data={data} index={index} setChangeBtn={setChangeBtn} checked={checked} setChecked={setChecked} showAnswer={showAnswer} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} pairs={pairs} setPairs={setPairs} type="test" setShowQuestionSection={setShowQuestionSection} showQuestionSection={showQuestionSection}/>}
                <div className='footer-question'>
                    <Container className='d-flex align-items-center justify-content-between'>
                        <div>
                            <button className='prev-btn' onClick={handleBack}> {t("previous")} </button>
                        </div>
                        <div className='d-flex align-items-center justify-content-between footer-levels'>{examLevelsData && examLevelsData.map(item => (
                            <div key={item.id} className='d-flex align-items-center justify-content-start mx-3 exam-level-title'>
                                {combinedExamData.map(item=>item.placement_level_id).includes(item.exam_id) ? item.exam_id === combinedExamData[0]?.placement_level_id ? <MdRadioButtonChecked className="exam-level-icon2"/> : <MdRadioButtonUnchecked className="exam-level-icon"/> : <MdOutlineCheckCircleOutline className="exam-level-icon3" />}
                                <p className='mx-2 level-title'>{item.main_title}</p>
                                </div>
                        ))}</div>
                        <div>
                            {changeBtn === 1 && <button className='skip-btn' onClick={handleSkip}> {t("skip")} </button>}
                            {changeBtn === 2 && <button className='next-btn' onClick={handleNext}> {t("next")} </button>}
                        </div>
                    </Container>
                </div></>}

        </div>

    )
}

export default TestQuestions
