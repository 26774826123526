import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import moment from "moment";
import 'moment/locale/ar';
import { FcClock } from "react-icons/fc";
import { Link } from 'react-router-dom';
import Parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import Pagination from '../Components/Pagination/Pagination';
import Loader from '../Components/Loader/Loader';


function Blog() {

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [totalCount, setTotalCount] = useState("");

    const [perPage, setPerPage] = useState("");

    const [submitting, setSubmitting] = useState(false);

    const { t } = useTranslation();

    const langDir = localStorage.getItem("yaqees-language")


    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/blogs`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Username : "administrator",
                        Password : "yaqess123456",
                    },
                });
                setData(result.data.data.data);
                setTotalCount(result.data.total);
                setPerPage(result.data.per_page);
                setLoading(false);
            } catch (err) {

            }
        })();
    }, []);


    const getPage = async (page) => {
        setLoading(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/blogs&page=${page}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Username : "administrator",
                Password : "yaqess123456",
            },
        });
        setData(result.data.data.data);
        setTotalCount(result.data.total);
        setPerPage(result.data.per_page);
        setLoading(false);

    };

    return (
        <div className='parent-blog'>
            <Container>
                <h5> {t("blog")} </h5>
                {loading ? <Loader/> :
                    <div className='blog-section my-3'>
                        <Row>
                            {data.map(item => (
                                <Col key={item.id} md={3}>
                                     <Link to={`/blog-info/${item.id}`}>
                                    <Card >
                                        <Card.Img variant="top" src={item.image} />
                                        <Card.Body>
                                            <Card.Title>{item.title}</Card.Title>
                                            <Card.Text>
                                                {Parse(`${item.content}`)}
                                            </Card.Text>
                                            <div className='card-time'>
                                                <p>{langDir === "ar" ? moment(item.created_date).locale('ar').fromNow() : moment(item.created_date).locale('en').fromNow()}</p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </div>
                }
                {totalCount > perPage && (
                    <Pagination
                        getPage={getPage}
                        totalCount={totalCount}
                        perPage={perPage}
                    />
                )}
            </Container>
        </div>
    )
}

export default Blog
