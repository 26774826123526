import React ,{ useState } from "react";
import { Container } from "react-bootstrap";
import { MdAccessTime ,MdOutlineIndeterminateCheckBox} from "react-icons/md";
import ReviewAnswersTest from "../Components/ReviewAnswersTest/ReviewAnswersTest";
import { useNavigate } from "react-router-dom";
import TimerReview from "../Components/TimerReview/TimerReview";
import { useSelector } from "react-redux";
import axios from "axios";
import { Button } from "antd";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";


function ConfirmYourAnswersTest() {

  const [submitting, setSubmitting] = useState(false);

  const [show, setShow] = useState(false);

  const [degree, setDegree] = useState("");

  const handleClose = () => setShow(false);

  const { t } = useTranslation();

  const examTitle = localStorage.getItem("yaqees-examTitle");

  const reduxData = useSelector((state) => state.review.testData);

  const examId = localStorage.getItem("yaqees-examId");

  const examType = localStorage.getItem("yaqees-examType")

    const examAttempt = localStorage.getItem("yaqees-examAttempt")

    const levelAttempt = localStorage.getItem("yaqees-levelAttempt")&&localStorage.getItem("yaqees-levelAttempt")

  const navigate = useNavigate();

  const disabledBtn = localStorage.getItem("yaqees-disabled");

  const handleResult = () => {

    const time = JSON.parse(localStorage.getItem("fullTimer"));

    const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

    const sumQuesMarks = reduxData
      .map((item) => item.QUES_MARK)
      .reduce(function (x, y) {
        return x + y;
      }, 0);

    const QuesMarksCorrect = reduxData
      .filter((item) => item.result === "correct")
      .map((item) => item.QUES_MARK)
      .reduce(function (x, y) {
        return x + y;
      }, 0);

    const percentage = Math.round((QuesMarksCorrect / sumQuesMarks) * 100);

    setDegree(percentage)

    if (examType === "combined") {
      (async () => {
        try {
          setSubmitting(true);
          const result = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/save-student-scores`,
            headers: {
              Accept: "application/json",
              Username : "administrator",
              Password : "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
            data: {
              time: `${time.hours}:${time.minutes}:${time.seconds}`,
              degree: percentage,
              exam_id: examId,
              result: percentage >= 50 ? "success" : "failed",
              questions: reduxData,
              exam_attempt: examAttempt,
              exam_type: examType,
              level_attempt: levelAttempt,
              answer_type:"test",
            },
          });
          localStorage.setItem("yaqees-attempId", result.data)
          setSubmitting(false);
          setShow(true);
        } catch (err) {
        }
      })();
    } else {
      (async () => {
        try {
          setSubmitting(true);
          const result = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/save-student-scores`,
            headers: {
              Accept: "application/json",
              Username : "administrator",
              Password : "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
            data: {
              time: `${time.hours}:${time.minutes}:${time.seconds}`,
              degree: percentage,
              exam_id: examId,
              result: percentage >= 50 ? "success" : "failed",
              questions: reduxData,
              exam_attempt: examAttempt,
              exam_type: examType,
              level_attempt: 0,
              answer_type:"test",
            },
          });
          localStorage.setItem("yaqees-attempId",result.data)
          navigate("/exam-report");
          setSubmitting(false);
          localStorage.setItem("yaqees-disabled","disabled")
        } catch (err) {
        }
      })();
    }
  }

  return (
    <div className="review-answers"  style={{fontFamily: "Noto Naskh Arabic"}}>
      <div className="head-question">
        <Container className=" align-items-center justify-content-between header-ques">
          {!disabledBtn && <div className="d-flex align-items-center justify-content-between parent-timer">
            <MdAccessTime className="timer-icon" />
            <TimerReview />
          </div>
          }
          <div className="header-title">
            <h5>{examTitle}</h5>
          </div>
          <div className="confirm-answer">
          <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleResult}
                disabled ={disabledBtn}
              >
                {!submitting && t("confirm-answers") }
              </Button>
          </div>
        </Container>
      </div>
      <div>
        <ReviewAnswersTest />
      </div>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          style={{ zIndex: "9999999999999999999" }}
        >
          <Modal.Body>
          {degree >= 50  && localStorage.getItem("yaqees-levels-length") > 1 && localStorage.getItem("yaqees-success_required") === "YES" && <p className="result-message"><span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span> {t("exam-passed")} <span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span></p>}
          {degree >= 50 && localStorage.getItem("yaqees-levels-length") <= 1 && localStorage.getItem("yaqees-success_required") === "YES" && <p className="result-message"> <span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span> {t("allexam-passed")} <span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span></p>}
          {degree < 50  && localStorage.getItem("yaqees-success_required") === "YES" && <p className="result-message"><span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span> {t("pass-exam")} <span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span></p>}
          {localStorage.getItem("yaqees-success_required") === "NO" && <p className="result-message"><span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span> {t("move")} <span className="mx-2"> <MdOutlineIndeterminateCheckBox/></span></p>}
          </Modal.Body>
          <Modal.Footer className="footer-message">
          {degree >= 50  && localStorage.getItem("yaqees-levels-length") > 1 && localStorage.getItem("yaqees-success_required") === "YES" && <button className="message-review-btn" onClick={()=>navigate(`/exam-questions/${examId}`)}> {t("next-level")} </button>}
          {degree >= 50 && localStorage.getItem("yaqees-levels-length") <= 1 && localStorage.getItem("yaqees-success_required") === "YES" && <button className="message-review-btn" onClick={()=>navigate('/exam-report')}> {t("reports")} </button>}
          {degree < 50  && localStorage.getItem("yaqees-success_required") === "YES" && <button className="message-review-btn" onClick={()=>{navigate('/exam-report');localStorage.setItem("yaqees-disabled","disabled")}}> {t("reports")} </button>}
          {localStorage.getItem("yaqees-levels-length") > 1 && localStorage.getItem("yaqees-success_required") === "NO" && <button className="message-review-btn" onClick={()=>navigate(`/exam-questions/${examId}`)}> {t("next-level")} </button>}
          {localStorage.getItem("yaqees-levels-length") <= 1 && localStorage.getItem("yaqees-success_required") === "NO" && <button className="message-review-btn" onClick={()=>navigate('/exam-report')}> {t("reports")} </button>}
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default ConfirmYourAnswersTest;
