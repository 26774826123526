import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Login.css";
import img1 from "../../Assets/images/login1.png";
import img2 from "../../Assets/images/login2.png";
import img3 from "../../Assets/images/login3.png";
import img4 from "../../Assets/images/login4.png";
import img5 from "../../Assets/images/login5.png";
import img6 from "../../Assets/images/login6.png";
import { Link, useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { AiFillLinkedin, AiFillFacebook } from "react-icons/ai";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import { gapi } from "gapi-script";
import Loader from "../Loader/Loader";


function Login({ setIsLoggedIn }) {

  const [user, setUser] = useState({ email: "", password: "" });

  const [submitting, setSubmitting] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const headers = {
    Accept: "application/json",
    Username : "administrator",
    Password : "yaqess123456",
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login`, user ,{headers})
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setUser({ email: "", password: "" });

        localStorage.setItem("yaqees-user", JSON.stringify(res.data.data));
        setIsLoggedIn(localStorage.getItem("yaqees-user"));
        navigate("/");
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });

  };


  const handleForgetPassword = () => {
    navigate("/forget-password")
  }

  const responseFacebook = async (response) => {
    try {
      if (response.status !== 'unknown') {
        setLoading(true);
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/facebook/callback`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
          },
          data: { facebook_id: response.id, name: response.name, image: `https://graph.facebook.com/${response.id}/picture?type=normal`, email: response.email },
        });
        localStorage.setItem("yaqees-user", JSON.stringify(result.data.data));
        setIsLoggedIn(localStorage.getItem("yaqees-user"));
        navigate("/");
        setLoading(false);
      } else {
        return null;
      }
    } catch (error) {
    }

  };

  const responseGoogle = async(response) => {
     try {
      if (response.status !== 'unknown') {
        setLoading(true);
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/google/callback`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
          },
          data: { google_id: response.googleId, name: response?.profileObj?.name, image: response?.profileObj?.imageUrl, email: response?.profileObj?.email },
        });
        localStorage.setItem("yaqees-user", JSON.stringify(result.data.data));
        setIsLoggedIn(localStorage.getItem("yaqees-user"));
        navigate("/");
        setLoading(false);
      } else {
        return null;
      }
    } catch (error) {
    }

  };

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: "152391579902-5fir19lrl4iidhecl8c5jhuplf9ts5h8.apps.googleusercontent.com" })
    });
  }, [])


  return (
    <div className="login">
      {loading && <Loader/>}
      <Row>
        <Col
          md={3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
        </Col>

        <Col md={6}>
          <Container className="login-sec">
            <h5>أهلا بك في منصة يقيس</h5>
            <p>قم بتسجيل الدخول للاستمرار في رحلتك العلمية</p>
            <div className="parent-social">
              <div className="social-btns">
                <GoogleLogin
                  clientId="152391579902-5fir19lrl4iidhecl8c5jhuplf9ts5h8.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <button onClick={renderProps.onClick}> <FcGoogle className="google-icon" /> استمرار بـ جوجل </button>
                  )}
                />
                <FacebookLogin
                  appId="8044683925558844"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  render={renderProps => (
                    <button onClick={renderProps.onClick}><AiFillFacebook /> استمرار بـ فيسبوك</button>
                  )}
                />
              </div>

              <h6>او</h6>
            </div>
            <form
              className="d-flex flex-column align-items-center"
              onSubmit={handleSubmit}
            >
              <div className="form-input">
                <span>
                  <MdEmail />
                </span>

                <input
                  type="email"
                  placeholder="البريد الالكتروني"
                  value={user.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="form-input">
                <span>
                  <RiLockPasswordFill />
                </span>

                <input
                  name="password"
                  type="password"
                  placeholder="كلمة المرور"
                  value={user.password}
                  onChange={handleChange}
                />
              </div>
              <Button
                htmlType="submit"
                loading={submitting}
                className="btn-login mx-auto mt-4"
              >
                {!submitting && "تسجيل الدخول"}
              </Button>

              <h4 onClick={handleForgetPassword}>نسيت كلمة المرور؟</h4>
              <label className="mx-auto my-4">
                ليس لديك حساب ؟
                <Link to="/register" style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      cursor: "pointer",
                      padding: "0 10px",
                      fontWeight: "700",
                      color: "#005C9A",
                    }}
                  >
                    انشاء الحساب
                  </span>
                </Link>
              </label>
            </form>

            <div className="mobile-social">
              <h6>او</h6>
              <div className="social-btns">
                <GoogleLogin
                  clientId="152391579902-5fir19lrl4iidhecl8c5jhuplf9ts5h8.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <button onClick={renderProps.onClick}> <FcGoogle className="google-icon" /> استمرار بـ جوجل </button>
                  )}
                />
                <FacebookLogin
                  appId="8044683925558844"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  render={renderProps => (
                    <button onClick={renderProps.onClick}><AiFillFacebook /> استمرار بـ فيسبوك</button>
                  )}
                />
              </div>
            </div>
          </Container>
        </Col>

        <Col
          md={3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </Col>
      </Row>
    </div>
  );
}

export default Login;
