import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Accordion from 'react-bootstrap/Accordion';
import { GiGraduateCap } from 'react-icons/gi';
import { FiBookOpen } from 'react-icons/fi';
import { BsQuestionOctagon } from 'react-icons/bs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import imgPdf from "../Assets/images/pdf-img.png"
import Loader from '../Components/Loader/Loader';
import { FaUserPen } from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import clockImg from "../Assets/images/time _clock.png"
import {Pie ,Bar} from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend, PointElement, LineElement, Filler, CategoryScale, LinearScale, BarElement } from 'chart.js';



function PrintReports() {

  const [reportDataQues, setReportDataQues] = useState([]);

  const [reportDataPublic, setReportDataPublic] = useState([]);

  const [examLevelTitles, setExamLevelTitles] = useState([]);

  const [activeLevelBtns, setActiveLevelBtns] = useState("");

  const [reportDataTime, setReportDataTime] = useState([]);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const [mainExamData, setMainExamData] = useState([]);

  const [dataCombined, setDataCombined] = useState([]);

  const [leoData, setLeoData] = useState([]);

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const { attempId } = useParams();

  const { examType } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    examType === "combined" ?
      (async () => {
        try {
          setLoading(true)
          const getLevels = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getAttemptForCombinedExam/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
          });
          setExamLevelTitles(getLevels.data.data)
          setActiveLevelBtns(getLevels.data.data[0].level_attempt)
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/get-user-response?attempt_id=${getLevels.data.data[0].level_attempt}&exam_type=${examType}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },

          });
          const result2 = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getCombinedExamQuestionReport/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },

          });
          setReportDataQues(result.data.data.questionsWithAnswers);
          setReportDataPublic(result.data.data.attempt_data);
          setReportDataTime(result.data.data.attempt_data.time.split(":"));
          setMainExamData(result2.data.data)
          setLoading(false)
        } catch (err) {
        }
      })() : (async () => {
        try {
          setLoading(true)
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/get-user-response?attempt_id=${attempId}&exam_type=${examType}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },

          });
          setReportDataQues(result.data.data.questionsWithAnswers);
          setReportDataPublic(result.data.data.attempt_data);
          setReportDataTime(result.data.data.attempt_data.time.split(":"))
          setLoading(false)
        } catch (err) {
        }
      })()
  }, []);

  useEffect(() => {
    examType === "combined" ?
      (async () => {
        try {
          const getLevels = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getAttemptForCombinedExam/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
          });
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getLoReport/${getLevels.data.data[0].level_attempt}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
            },
          });
          setData(result.data.data);
        } catch (err) {
        }
      })() : (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getLoReport/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
            },
          });
          setData(result.data.data);
        } catch (err) {
        }
      })()
  }, []);

  useEffect(() => {
    examType === "combined" &&
      (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getCombinedAttemptDetail/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
            },
          });
          const result2 = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getCombinedExamLoReport/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
            },
          });
          setDataCombined(result.data.data[0]);
          setLeoData(result2.data.data)
        } catch (err) {
        }
      })()
  }, []);


  const time = reportDataTime;

  const sumQuesMarks = reportDataQues.map(item => item.QUES_MARK).reduce(function (x, y) {
    return +x + +y;
  }, 0);

  const QuesMarksCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").map(item => item.QUES_MARK).reduce(function (x, y) {
    return +x + +y;
  }, 0);

  const timeAvrage = Math.round((+(reportDataTime[0] * 3600) + +(reportDataTime[1] * 60) + +(reportDataTime[2])) / reportDataQues.length)

  const percentage = reportDataPublic.degree;

  const allQues = reportDataQues.length
  const correct = reportDataQues.filter(item => item.ResultSuccess === "correct").length
  const wrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").length
  const skip = reportDataQues.filter(item => item.ResultSuccess === "").length

  const correctAnswersPercentage = Math.round((correct / allQues) * 100);
  const wrongAnswersPercentage = Math.round((wrong / allQues) * 100);
  const skipAnswersPercentage = Math.round((skip / allQues) * 100);


  const veryEasyCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "1").length
  const veryEasyWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "1").length
  const veryEasySkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "1").length
  const allVeryEasy = reportDataQues.filter(item => item.DIFFICULTY === "1").length

  const easyCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "2").length
  const easyWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "2").length
  const easySkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "2").length
  const allEasy = reportDataQues.filter(item => item.DIFFICULTY === "2").length

  const normalCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "3").length
  const normalWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "3").length
  const normalSkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "3").length
  const allNormal = reportDataQues.filter(item => item.DIFFICULTY === "3").length

  const hardCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "4").length
  const hardWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "4").length
  const hardSkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "4").length
  const allHard = reportDataQues.filter(item => item.DIFFICULTY === "4").length

  const veryHardCorrect = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.DIFFICULTY === "5").length
  const veryHardWrong = reportDataQues.filter(item => item.ResultSuccess === "wrong").filter(item => item.DIFFICULTY === "5").length
  const veryHardSkip = reportDataQues.filter(item => item.ResultSuccess === "").filter(item => item.DIFFICULTY === "5").length
  const allVeryHard = reportDataQues.filter(item => item.DIFFICULTY === "5").length


  const remember = reportDataQues.filter(item => item.COGNITIVE_ID === "10").length
  const understand = reportDataQues.filter(item => item.COGNITIVE_ID === "11").length
  const analysis = reportDataQues.filter(item => item.COGNITIVE_ID === "13").length
  const installation = reportDataQues.filter(item => item.COGNITIVE_ID === "14").length
  const application = reportDataQues.filter(item => item.COGNITIVE_ID === "12").length
  const call = reportDataQues.filter(item => item.COGNITIVE_ID === "6").length
  const skills = reportDataQues.filter(item => item.COGNITIVE_ID === "7").length
  const strategic = reportDataQues.filter(item => item.COGNITIVE_ID === "8").length
  const extended = reportDataQues.filter(item => item.COGNITIVE_ID === "9").length
  const calendar = reportDataQues.filter(item => item.COGNITIVE_ID === "15").length
  const creativity = reportDataQues.filter(item => item.COGNITIVE_ID === "16").length
  const innovation = reportDataQues.filter(item => item.COGNITIVE_ID === "17").length

  const rememberTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "10").length
  const understandTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "11").length
  const analysisTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "13").length
  const installationTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "14").length
  const applicationTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "12").length
  const callTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "6").length
  const skillsTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "7").length
  const strategicTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "8").length
  const extendedTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "9").length
  const calendarTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "15").length
  const creativityTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "16").length
  const innovationTrue = reportDataQues.filter(item => item.ResultSuccess === "correct").filter(item => item.COGNITIVE_ID === "17").length

  const rememberData = remember > 0 ? remember : null;
  const rememberLabel = remember > 0 ? "تذكر" : null;

  const understandData = understand > 0 ? understand : null;
  const understandLabel = understand > 0 ? "فهم" : null;

  const applicationData = application > 0 ? application : null;
  const applicationLabel = application > 0 ? "تطبيق" : null;

  const analysisData = analysis > 0 ? analysis : null;
  const analysisLabel = analysis > 0 ? "تحليل" : null;

  const installationData = installation > 0 ? installation : null;
  const installationLabel = installation > 0 ? "تركيب" : null;

  const calendarData = calendar > 0 ? calendar : null;
  const calendarLabel = calendar > 0 ? "تقويم" : null;

  const creativityData = creativity > 0 ? creativity : null;
  const creativityLabel = creativity > 0 ? "ابداع" : null;

  const innovationData = innovation > 0 ? innovation : null;
  const innovationLabel = innovation > 0 ? "الابتكار" : null;

  const callData = call > 0 ? call : null;
  const callLabel = call > 0 ? "الاستدعاء" : null;

  const extendedData = extended > 0 ? extended : null;
  const extendedLabel = extended > 0 ? "التفكير الممتد" : null;

  const skillsData = skills > 0 ? skills : null;
  const skillsLabel = skills > 0 ? "المفاهيم-المهارات" : null;

  const strategicData = strategic > 0 ? strategic : null;
  const strategicLabel = strategic > 0 ? "التفكير الاستراتيجي" : null;


  const reportCongective = [`${rememberLabel} (${rememberTrue} / ${rememberData})`, `${understandLabel} (${understandTrue} / ${understandData})`, `${applicationLabel} (${applicationTrue} / ${applicationData})`, `${analysisLabel} (${analysisTrue} / ${analysisData})`, `${installationLabel} (${installationTrue} / ${installationData})`, `${calendarLabel} (${calendarTrue} / ${calendarData})`, `${creativityLabel} (${creativityTrue} / ${creativityData})`, `${innovationLabel} (${innovationTrue} / ${innovationData})`, `${callLabel} (${callTrue} / ${callData})`, `${extendedLabel} (${extendedTrue} / ${extendedData})`, `${skillsLabel} (${skillsTrue} / ${skillsData})`, `${strategicLabel} (${strategicTrue} / ${strategicData})`].filter(label => label !== `${null} (${0} / ${null})`).toString().replace(/,/g, " - ");

  const reportHarderDegree = [`سهل جدا (${veryEasyCorrect} / ${allVeryEasy})`, `سهل (${easyCorrect} / ${allEasy})`, `متوسط (${normalCorrect} / ${allNormal})`, `صعب (${hardCorrect} / ${allHard})`, `صعب جدا (${veryHardCorrect} / ${allVeryHard})`].toString().replace(/,/g, " - ");

  const examTitle = localStorage.getItem("yaqees-examTitle")

  const userName = JSON.parse(localStorage.getItem("yaqees-user"))?.username


  const groupedData = {};

  data?.forEach(item => {
    if (!groupedData[item.SUBJECT_ID]) {
      groupedData[item.SUBJECT_ID] = {};
    }

    if (!groupedData[item.SUBJECT_ID][item.TOPIC_ID]) {
      groupedData[item.SUBJECT_ID][item.TOPIC_ID] = [];
    }

    groupedData[item.SUBJECT_ID][item.TOPIC_ID].push(item);
  });

  const groupedArray = Object.entries(groupedData).map(([subjectId, topics]) => ({
    SUBJECT_ID: subjectId,
    TOPICS: Object.entries(topics).map(([topicId, items]) => ({
      TOPIC_ID: topicId,
      ITEMS: items,
    })),
  }));


  const finalData = groupedArray.map(item => item.TOPICS).map(item => item.map(item => item.ITEMS));

  useEffect(() => {
    const headAccoDivs = document.querySelectorAll(".head-acco");

    headAccoDivs?.forEach(function (headAcco) {
      const children = headAcco.children;

      for (let i = 2; i < children.length; i++) {
        headAcco.removeChild(children[i]);
      }
    });
  }, []);

  ChartJS.register(RadialLinearScale, ArcElement, PointElement, LineElement, CategoryScale, LinearScale, BarElement, Filler, Tooltip, Legend);
  ChartJS.defaults.font.family = "'Noto Naskh Arabic', sans-serif";
  ChartJS.defaults.font.weight = "bold";

  const data5 = {
    labels: [` ${t("correct-answers")} ${mainExamData.question_correct}`, ` ${t("incorrect-answers")} ${mainExamData.question_wrong }`, ` ${t("skip-done")} ${mainExamData.question_skipped}`],
    datasets: [
        {
            data: [`${Math.round((mainExamData.question_correct / mainExamData.question_count) * 100)}`, `${Math.round((mainExamData.question_wrong / mainExamData.question_count) * 100)}`, `${Math.round((mainExamData.question_skipped / mainExamData.question_count) * 100)}`],
            backgroundColor: [
                '#4DB847',
                '#ED254E',
                '#D9D9D9',
            ],
            borderWidth: 3,
        },
    ],
};

const data4 = {
  labels: [`${rememberLabel} (${rememberData})`, `${understandLabel} (${understandData})`, `${applicationLabel} (${applicationData})`, `${analysisLabel} (${analysisData})`, `${installationLabel} (${installationData})`, `${calendarLabel} (${calendarData})`, `${creativityLabel} (${creativityData})`, `${innovationLabel} (${innovationData})`, `${callLabel} (${callData})`, `${extendedLabel} (${extendedData})`, `${skillsLabel} (${skillsData})`, `${strategicLabel} (${strategicData})`].filter(label => label !== `${null} (${null})`),
  datasets: [
      {
          data: [rememberTrue, understandTrue, applicationTrue, analysisTrue, installationTrue, calendarTrue, creativityTrue, innovationTrue, callTrue, extendedTrue, skillsTrue, strategicTrue].filter(label => label !== null),
          backgroundColor: '#005C9A',
      },
  ],
};

  const savePdf = () => {
    const htmlContent = document.getElementById('yourHtmlContent');

    const contentWidth = htmlContent.offsetWidth;
    const contentHeight = htmlContent.offsetHeight;

   
    const marginTop = 20;
    const marginBottom = 20;

    const pdfWidth = contentWidth;
    const pdfHeight = contentHeight + marginTop + marginBottom;

    html2canvas(htmlContent, { scale: 1 }).then(canvas => {
        
        const imgData = canvas.toDataURL('image/jpeg', 1);

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [pdfWidth, pdfHeight] 
        });
      
        pdf.addImage(imgData, 'JPEG', 0, marginTop, contentWidth, contentHeight);
      
        pdf.save(`${examTitle}.pdf`);
      });
}

const savePdf2 = () => {
  const htmlContent = document.getElementById('yourHtmlContent2');

  const contentWidth = htmlContent.offsetWidth;
  const contentHeight = htmlContent.offsetHeight;

 
  const marginTop = 20;
  const marginBottom = 20;

  const pdfWidth = contentWidth;
  const pdfHeight = contentHeight + marginTop + marginBottom;

  html2canvas(htmlContent, { scale: 1 }).then(canvas => {
      
      const imgData = canvas.toDataURL('image/jpeg', 1);

      const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [pdfWidth, pdfHeight] 
      });
    
      pdf.addImage(imgData, 'JPEG', 0, marginTop, contentWidth, contentHeight);
    
      pdf.save(`${examTitle}.pdf`);
  });
}


  return (
    <div className='print-reports-parent'>
          {loading ? <Loader/> :
          examType === "combined" ? 
          <>
            <div className='container d-flex align-items-center justify-content-end pdf-sec'>
      <button onClick={savePdf} className='pdf-btn'> <img src={imgPdf} alt=''/> </button>
      </div>
          <div id='yourHtmlContent'> 
       <div className="head-question py-4">
        <Container className="d-flex align-items-center justify-content-between">
          <div>
            <h5>{examTitle}</h5>
          </div>
          {/* <Link to="/">
            <FcHome className="home-icon" />
          </Link> */}
        </Container>
      </div>
      <Container className='my-4'>
      <Row className='combined-repo mt-2'>
                    <Col md={12} className='mb-3'>
                        <h5 className='mb-3'> <span> <FaUserPen /> </span> {userName} </h5>
                        <h6> <span> <MdDateRange /> </span> {new Date(dataCombined.date).toLocaleDateString("ar-EG", {
                            weekday: 'long',
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true,
                            timeZone: 'Africa/Cairo'
                        })}</h6>
                        <div className='grid2 my-4'>
                        <h6> عدد الاسئلة الكلي : <span> {mainExamData.question_count} سؤال </span> </h6>
                        <h6> عدد الاجابات الصحيحة : <span> {mainExamData.question_correct} سؤال </span> </h6>
                        <h6> عدد الاجابات الخاطئة : <span> {mainExamData.question_wrong} سؤال </span> </h6>
                        <h6> عدد الاسئلة التي تم تخطيها : <span> {mainExamData.question_skipped} سؤال </span> </h6>
                        <h6> الوقت المستغرق للاختبار : <span> {dataCombined?.time?.split(":")[1]} {t("minute")}  {dataCombined?.time?.split(":")[2]} {t("second")} </span> </h6>
                        <h6> متوسط الوقت المستغرق لكل سؤال : <span> {timeAvrage} {t("second")} </span> </h6>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className='global-result levels-sec mb-4'>
                            <div className='bord-btm'>
                                <h4> مستوي الطالب : {dataCombined.degree > 0 && dataCombined.degree <= 10 ? `المبتدئ الأدني` : dataCombined.degree > 11 && dataCombined.degree <= 20 ? `المبتدئ الأوسط` : dataCombined.degree > 21 && dataCombined.degree <= 30 ? `المبتدئ الأعلي` : dataCombined.degree > 31 && dataCombined.degree <= 40 ? `المتوسط الأدني` : dataCombined.degree > 41 && dataCombined.degree <= 50 ? `المتوسط الأوسط` : dataCombined.degree > 51 && dataCombined.degree <= 60 ? `المتوسط الأعلي` : dataCombined.degree > 61 && dataCombined.degree <= 70 ? `المتقدم الأدني` : dataCombined.degree > 71 && dataCombined.degree <= 80 ? `المتقدم الأوسط` : dataCombined.degree > 81 && dataCombined.degree <= 90 ? `المتقدم الأعلي` : dataCombined.degree > 91 && dataCombined.degree <= 100 ? `المتميز (المتقن)` : "" } </h4>
                            </div>

                            <div className='row m-2 align-items-stretch my-3'>
                                <div className='col-lg-2 bord-lft'>
                                    <div className='all-degree' style={{ background: dataCombined.degree >= 0 && dataCombined.degree < 50 ? `#E80C53` : dataCombined.degree >= 50 ? `#4DB847` : "" }}>
                                        <div className='sec1'>
                                            <p className='text-center py-2'> المجموع النهائي </p>
                                        </div>
                                        <p className='text-center py-2 deg-num'> % {dataCombined.degree} </p>
                                    </div>
                                </div>
                                <div className='col-lg-10'>
                                    <div className='row align-items-stretch justify-content-center g-5'>
                                        {dataCombined?.placement_exam?.map(item => (
                                            <div className='col-lg-2' key={item.id}>
                                                <div className='curs-comb'>
                                                    <CircularProgressbar
                                                        minValue
                                                        value={item.degree}
                                                        text={`${item.degree}%`}
                                                        strokeWidth={7}
                                                        styles={buildStyles({
                                                            pathColor: item.degree > 0 && item.degree < 50 ? `#E80C53` : item.degree >= 50 ? `#4DB847` : "",
                                                            textColor: "#1B1A1D",
                                                            trailColor: "#d6d6d67d",
                                                        })}
                                                        className="circle-perc"
                                                    />
                                                    <p className='m-0 mt-2 circle-text-active'> {item?.info?.main_title} </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
{leoData.map((item , i)=>(
  <div className='learn-outcome global-result mb-4'>
  <div className='bord-btm'>
      <h4> {item.main_title} </h4>
  </div>
  {item?.lo_data?.length > 0 ? item?.lo_data?.map(child => (
      <div className='global-result leo-sec d-flex align-items-center m-3'>
          <p className='m-2 w-100'> {child.lo_name} </p>
          <div class="progress  w-100" role="progressbar" aria-label="Example with label" aria-valuenow={child.degree} aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar" style={{ width: `${child.degree}%` , background: child.degree > 0 && child.degree < 50 ? `#E80C53` : child.degree >= 50 ? `#4DB847` : "" }}> % {child.degree} </div>
          </div>
      </div>
  )) : <div> <p className='notfound-leo'> لاتوجد مهارات لهذا المستوي </p> </div>}

</div>
))}

                    </Col>

                    <Col md={4}>
                        <div className='analysis-charts time-acc repo-chart mb-3'>
                            <div>
                                <h5> {t("time-investment")} </h5>
                                <p> {t("description-time")} </p>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={clockImg} alt="" />
                            </div>
                            <div className='time-test-info'>
                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <h6> {t("time-taken")} </h6>
                                    <h5>{dataCombined?.time?.split(":")[1]} {t("minute")}  {dataCombined?.time?.split(":")[2]} {t("second")}  </h5>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mt-3'>
                                    <h6> {t("average-time")} </h6>
                                    <h5>{timeAvrage} {t("second")}</h5>
                                </div>
                            </div>
                        </div>

                        <div className='analysis-charts time-acc repo-chart mb-3'>
                            <div>
                                <h5> {t("performance-analysis")} </h5>
                                <p> {t("description-preformance")} </p>
                            </div>
                            <hr />
                        
                            <Pie data={data5} options={{
                                    plugins: {
                                        legend: {
                                            labels: {
                                                font: {
                                                    family: "'Noto Naskh Arabic', sans-serif",
                                                },
                                                color: "black",
                                            },
                                            position: "bottom"
                                        }
                                    },
                                }}/>
                        </div>
                    </Col>
                    </Row>
      </Container>
      </div> </> :
      <>
      <div className='container d-flex align-items-center justify-content-end pdf-sec'>
      <button onClick={savePdf2} className='pdf-btn'> <img src={imgPdf} alt=''/> </button>
      </div>
      <div id='yourHtmlContent2'>
      <div className="head-question py-4">
        <Container className="d-flex align-items-center justify-content-between">
          <div>
            <h5>{examTitle}</h5>
          </div>
          {/* <Link to="/">
            <FcHome className="home-icon" />
          </Link> */}
        </Container>
      </div>
      <Container className='my-4'>

      <Row className='combined-repo mt-2'>
                    <Col md={12} className='mb-3'>
                        <h5 className='mb-3'> <span> <FaUserPen /> </span> {userName} </h5>
                        <div className='grid2 my-4'>
                        <h6> عدد الاسئلة الكلي : <span> {allQues} سؤال </span> </h6>
            <h6> عدد الإجابات الصحيحة : <span> {correct} سؤال </span> </h6>
            <h6>  عدد الإجابات الخاطئة : <span> {wrong} سؤال </span> </h6>
            <h6> عدد الأسئلة التي تم تخطيها : <span> {skip} سؤال </span> </h6>
            <h6>  الوقت المستغرق للاختبار : <span> {time[2]} ثانية - {time[1]} دقيقة </span> </h6>
            <h6>  متوسط الوقت المستغرق لكل سؤال : <span> {timeAvrage} ثانية </span> </h6>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className='global-result levels-sec mb-4'>
                            <div className='bord-btm'>
                                <h4> مستوي الطالب : {Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 0 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 10 ? `المبتدئ الأدني` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 11 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 20 ? `المبتدئ الأوسط` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 21 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 30 ? `المبتدئ الأعلي` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 31 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 40 ? `المتوسط الأدني` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 41 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 50 ? `المتوسط الأوسط` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 51 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 60 ? `المتوسط الأعلي` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 61 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 70 ? `المتقدم الأدني` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 71 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 80 ? `المتقدم الأوسط` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 81 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 90 ? `المتقدم الأعلي` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) > 91 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) <= 100 ? `المتميز (المتقن)` : "" } </h4>
                            </div>

                            <div className='row m-2 align-items-stretch my-3'>
                                <div className='col-lg-2 bord-lft'>
                                    <div className='all-degree' style={{ background: Math.round((QuesMarksCorrect / sumQuesMarks) * 100) >= 0 && Math.round((QuesMarksCorrect / sumQuesMarks) * 100) < 50 ? `#E80C53` : Math.round((QuesMarksCorrect / sumQuesMarks) * 100) >= 50 ? `#4DB847` : "" }}>
                                        <div className='sec1'>
                                            <p className='text-center py-2'> المجموع النهائي </p>
                                        </div>
                                        <p className='text-center py-2 deg-num'> % {Math.round((QuesMarksCorrect / sumQuesMarks) * 100)} </p>
                                    </div>
                                </div>
                                <div className='col-lg-10'>
                                    <div className='row align-items-stretch justify-content-center g-5'>
                                        {dataCombined?.placement_exam?.map(item => (
                                            <div className='col-lg-2' key={item.id}>
                                                <div className='curs-comb'>
                                                    <CircularProgressbar
                                                        minValue
                                                        value={item.degree}
                                                        text={`${item.degree}%`}
                                                        strokeWidth={7}
                                                        styles={buildStyles({
                                                            pathColor: item.degree > 0 && item.degree < 50 ? `#E80C53` : item.degree >= 50 ? `#4DB847` : "",
                                                            textColor: "#1B1A1D",
                                                            trailColor: "#d6d6d67d",
                                                        })}
                                                        className="circle-perc"
                                                    />
                                                    <p className='m-0 mt-2 circle-text-active'> {item?.info?.main_title} </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='print-section3 report-answer'>
            {finalData.map((array, index) => (
              <div className="parent-collapse" key={index}>
                <Accordion defaultActiveKey="" >
                  <Accordion.Item eventKey="">
                    <Accordion.Header className='disabled-accordion-header'> <div className="d-flex align-items-center head-acco">
                      <GiGraduateCap className="graduate-cap" />
                      {array[0].map((item, index) => (
                        <div key={index}>
                          <h4>{item.GRADE_NAME} - {item.SUBJECT_NAME}</h4>
                        </div>
                      ))}

                    </div></Accordion.Header>
                    <Accordion.Body>
                      {array.map((item, index) => (
                        <div className="child-collapse" key={index}>
                          <Accordion defaultActiveKey="0" >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header className='disabled-accordion-header'> <div className="d-flex align-items-start">
                                <FiBookOpen className="book-icon" />
                                <div>
                                  <h5>{item[0]?.PARENT[0]?.PARENT_NAME} - {item[0]?.PARENT[1]?.PARENT_NAME} </h5>
                                  <p className="my-3">{item[0].TOPIC_NAME_EN}</p>
                                </div>

                              </div></Accordion.Header>
                              <Accordion.Body>
                                <div id="example-collapse-text">

                                  <h5> نتائج التعلم </h5>
                                  {item.map((item, index) => (
                                    <div className="d-flex align-items-center justify-content-between lco-parent" key={index}>
                                      <h6 className="my-4">{item.name}</h6>
                                      <div className="d-flex align-items-center justify-content-center lco-sec">
                                        <h6 className="ques-num"><BsQuestionOctagon className="ques-icon" /> <span> اسئلة {item.questions_count}</span></h6>
                                        <div>
                                          <CircularProgressbar
                                            minValue
                                            value={Math.round(item.correct / item.questions_count) * 100}
                                            text={`${Math.round(item.correct / item.questions_count) * 100}%`}
                                            strokeWidth={8}
                                            styles={buildStyles({
                                              pathColor: `#4DB847`,
                                              textColor: "#1B1A1D",
                                              trailColor: "#d6d6d67d",
                                            })}
                                            className="circle-perc"
                                          />
                                        </div>
                                      </div>

                                    </div>
                                  ))}

                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ))}
          </div>

                    </Col>

                    <Col md={4}>
                        <div className='analysis-charts time-acc repo-chart mb-3'>
                            <div>
                                <h5> {t("time-investment")} </h5>
                                <p> {t("description-time")} </p>
                            </div>
                            <hr />
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={clockImg} alt="" />
                            </div>
                            <div className='time-test-info'>
                                <div className='d-flex align-items-center justify-content-between mt-5'>
                                    <h6> {t("time-taken")} </h6>
                                    <h5> {time[1]} {t("minute")}  {time[2]} {t("second")}  </h5>
                                </div>
                                <div className='d-flex align-items-center justify-content-between mt-3'>
                                    <h6> {t("average-time")} </h6>
                                    <h5>{timeAvrage} {t("second")}</h5>
                                </div>
                            </div>
                        </div>

                        <div className='analysis-charts ques-degre-chart level-chart'>
                            <div>
                                <h5> {t("cognitive-level")} </h5>
                                <p> {t("description-cognitive")} </p>
                            </div>
                            <hr />
                            <div>
                                <Bar data={data4} options={{
                                    maintainAspectRatio: false,
                                    devicePixelRatio: 2,
                                    fill: true,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                color: "black",
                                            },
                                        },
                                        y: {
                                            ticks: {
                                                color: "black",
                                            },
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                    </Col>
                    </Row>
          </Container>
        </div>
        </>}
    </div>
  )
}

export default PrintReports
