import React, { useEffect } from 'react'
import Parse from "html-react-parser";
import { Col, Row } from 'react-bootstrap';

function ChooseSkip({ showQuestion, checked, setChecked ,setSelectedAnswer ,setCorrectOptions}) {

  const chooseArray = JSON.parse(showQuestion?.QUS_OPTION)

  const handleChooseChange = (e) => {
    setSelectedAnswer(e.target.value);
    setCorrectOptions(Object.values(JSON.parse(showQuestion.QUS_CORRECT_RESPONSE)).toString())
  };


  let str = showQuestion.QUESTION_TEXT
  let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

  useEffect(() => {

    const elements2 = document.getElementsByClassName("preview");

    const elements3 = document.getElementsByClassName("mathLabel");

    for (let i = 0; i < elements2.length; i++) {
      const element = elements2[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements3.length; i++) {
      const element = elements3[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }

    }


  }, []);

  return (

    <div className='ques pb-2'>

       <h5 className='preview'>{showQuestion.QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${showQuestion.QUESTION_TEXT}`)}</h5>

      {showQuestion.QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${showQuestion.QUESTION_FILE_NAME}`} controls style={{width:"100%", margin: "20px 0"}} />
          )}

      <Row>
        {Object.values(chooseArray).map((item, i) => {
          if (item.includes("src")) {
            let startIndex = item.indexOf("src") + 5;
            let endIndex = item.indexOf("\"", startIndex);
            return <Col key={i} md={3} >
              <div className={checked === i ? "active-radio" : "form-check"} style={{ height: "100%", padding: "10px 0" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="answer"
                  id={`flexRadioDefault${i}`}
                  value={item}
                  checked=""
                  onClick={() => {
                    setChecked(i);
                  }}

                  onChange={handleChooseChange}

                />
                <label
                  className="form-check-label mathLabel"
                  htmlFor={`flexRadioDefault${i}`}
                  style={{ height: "100%" }}
                >
                  <img src={item.includes('src="/public') ? `https://test4you.me${item.substring(startIndex, endIndex)}`: `${item.substring(startIndex, endIndex)}`} alt="" style={{ maxWidth: "100%" }} />
                </label>
              </div>
            </Col>
          } else {
            return <Col key={i} md={6} >
              <div className={checked === i ? "active-radio" : "form-check"}  >
        <input
          className="form-check-input"
          type="radio"
          name="answer"
          id={`flexRadioDefault${i}`}
          value={item}
          checked=""
          onClick={() => {
            setChecked(i);
          }}

          onChange={handleChooseChange}

        />
        <label
          className="form-check-label mathLabel"
          htmlFor={`flexRadioDefault${i}`}
        >
       {Parse(`${item}`)}
        </label>
      </div>
    </Col>
  }
})}
    </Row>
</div >
  )
}

export default ChooseSkip
