import React, { useState, useEffect } from "react";
import { Col, Container, Row } from 'react-bootstrap'
import "./DragDropQues.css"
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import Parse from "html-react-parser";
import Dragula from 'react-dragula';
import { TbBulbFilled } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";


function DragDropQues({ data, index, setChangeBtn, checked, setChecked, showAnswer, selectedAnswer, setSelectedAnswer ,setCorrectOptions ,type ,renderMathType ,setShowQuestionSection ,showQuestionSection}) {

  const { t } = useTranslation();

  const chooseArray = JSON.parse(data[index]?.QUS_OPTION)


  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      const dragula = Dragula([componentBackingInstance, document.getElementById("drop-target")], {
        mirrorContainer: componentBackingInstance,
        revertOnSpill: true,
        copy: true,

      });
      dragula.on('drop', (el, target, source, sibling) => {

        if (showAnswer === false) {
          const result = `${el.textContent}`;
          const containerDropIn = document.getElementById("drop-target")
          if (containerDropIn.childElementCount > 0) {
            containerDropIn.innerHTML = '';
            document.getElementById("drop-target").appendChild(el)
          } else {
            document.getElementById("drop-target").appendChild(el)
          }
          setSelectedAnswer(result.trim())
          setChangeBtn(2);
          setCorrectOptions(Object.values(JSON.parse(data[index].QUS_CORRECT_RESPONSE)).toString().trim())
        } else {
          return null
        }
      })
    }
  };

  const [reminderCard, setReminderCard] = useState([]);

  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClose = () =>  setShow(false) ;
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `https://testcards.weghat.com/public/api/reminder-card/get/${data[index].CAT_ID}/${data[index].ST_LO_ID}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        setReminderCard(result.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [index]);

  let str = data[index].QUESTION_TEXT
  let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');




  let strAnswer = data[index].ANSWER_EXPLANATION
  let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

  useEffect(() => {

    setShowQuestionSection(true);

    const elements = document.getElementsByClassName("explain");

    const elements2 = document.getElementsByClassName("preview");

    const elements3 = document.getElementsByClassName("mathLabel");

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements2.length; i++) {
      const element = elements2[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements3.length; i++) {
      const element = elements3[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }

    }


  }, [index, renderMathType ,showQuestionSection]);


  return (
    <div className='choose-question d-flex  justify-content-center'>
      <Container >
      {showQuestionSection &&
        <div className='ques pt-5 pb-2'>
        <div className="d-flex align-items-start justify-content-between">
          <h5 className="preview">{data[index].QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${data[index].QUESTION_TEXT}`)}</h5>

          {reminderCard.message === "success" && type === "training" ?  <div className="bulb-icon"> <TbBulbFilled className="reminder-icon" onClick={handleShow} />  </div> : ""}
          </div>

          {data[index].QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${data[index].QUESTION_FILE_NAME}`} controls />
          )}

          <Row >
            {Object.values(chooseArray).map((item, i) => (
              <Col key={i} md={3} className='drag-drop' ref={dragulaDecorator}>
                <div className={checked === i ? "active-radio" : "form-check"}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={`flexRadioDefault${index}${i}`}
                    value={item}
                  />
                  <label
                    className="form-check-label mathLabel"
                    htmlFor={`flexRadioDefault${index}${i}`}
                  >
                  {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g,'')} </p> }
                  </label>
                </div>

              </Col>
            ))}
          </Row>
          <div id="drop-target">
          </div>


          {showAnswer === true && (
            <div>
              {selectedAnswer === Object.values(JSON.parse(data[index].QUS_CORRECT_RESPONSE)).toString().trim() ? (
                <div className="true-answer">
                  <h5>
                    <IoIosCheckmarkCircle className="true-icon" /> {t("correct-answer")}
                  </h5>
                  <div className="explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                </div>
              ) : (
                <div className="false-answer">
                  <h5>

                    <IoIosCloseCircle className="false-icon" />
                    {t("incorrect-answer")}
                  </h5>
                  <div className="explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                </div>
              )}
            </div>
          )}
        </div>}
      </Container>
      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: "9999999999999" ,  fontFamily: 'Noto Naskh Arabic'}}>
        <Modal.Header closeButton>
        <Modal.Title style={{color:"#005C9A"}}> {t("reminder-card")} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {loading ? <Loader/> :
            <div>
              {reminderCard.message === "success" && Parse(`${reminderCard?.data[0]?.content}`)}
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DragDropQues
