import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import "./BlogSection.css"
import img1 from "../../Assets/images/Rectangle-11.png"
import img2 from "../../Assets/images/Rectangle-22.png"
import img3 from "../../Assets/images/Rectangle-33.png"
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import axios from 'axios'
import Parse from "html-react-parser";
import moment from "moment";
import 'moment/locale/ar';
import Loader from '../Loader/Loader'


function BlogSection({blogsData ,loading}) {

    const langDir = localStorage.getItem("yaqees-language")


    const { t } = useTranslation();

    return (
        <div className='blog-section my-5'>
              {loading ? <Loader/> :
            <Container>
                <h6> {t("yaqees-blog")} </h6>
                <div className='cards'>
                    <Row>
                        {blogsData.map(item => (
                             <Col key={item.id} md={3}>
                             <Link to={`/blog-info/${item.id}`}>
                            <Card >
                                <Card.Img variant="top" src={item.image} />
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>
                                        {Parse(`${item.content}`)}
                                    </Card.Text>
                                    <div className='card-time'>
                                        <p>{langDir === "ar" ? moment(item.created_date).locale('ar').fromNow() : moment(item.created_date).locale('en').fromNow()}</p>
                                    </div>
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                        ))}
                        <div className='my-5 all-test'>
                            <Link to="/blog" >
                            <button> {t("view-all")} </button>
                            </Link>
                        </div>

                    </Row>
                </div>


            </Container>
            }
        </div>
    )
}

export default BlogSection
