import React from 'react'
import "./WhyYaqesSection.css"
import { Container, Row, Col } from 'react-bootstrap';
import img1 from "../../Assets/images/Rectangle14.png"
import img2 from "../../Assets/images/Rectangle16.png"
import img3 from "../../Assets/images/Rectangle19.png"
import { useTranslation } from "react-i18next";

function WhyYaqesSection() {

  const { t } = useTranslation(); 

  return (
    <div className='why-yaqes-parent'>
      <Container>
        <Row className='g-2'>
          <Col md={8} >
            <Row className='align-items-center'>
              <Col md={5}>
                <h5> {t("why-student")} <span> {t("yaqees")} </span></h5>
                <p> {t("ready-info")} </p>
              </Col>
              <Col md={7}> <img src={img3} alt='' /></Col>
            </Row>
          </Col>
          <Col md={4}>
            <img src={img1} alt='' />
          </Col>
          <Col md={8} >
            <div className='comments'>
              <p> {t("student-name")} </p>
              <h6> {t("student-word")} </h6>
            </div>
          </Col>
          <Col md={4}>
            <img src={img2} alt='' />
          </Col>
        </Row>

      </Container>

    </div>
  )
}

export default WhyYaqesSection
