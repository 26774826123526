import React, { useRef, useState } from "react";
import "./TestInfoBody.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { addTestAnswers, addTrainingAnswers } from "../../Redux/Reducers/reviewsAnswerSlice";
import { useDispatch } from "react-redux";
import user_image from "../../Assets/images/user-default.jpg";
import { Button } from "antd";
import { useTranslation } from "react-i18next";



function TestInfoBody({ setShowModal, data, relatedData, comments, setRefershComment, refreshComment, id, promoData, addToCart, attemptData, submittingBody, setSubmittingBody, handleBuyFree }) {

  const [activeBtn, setActiveBtn] = useState(1);

  const [comment, setComment] = useState("");

  const [submittingComment, setSubmittingComment] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const userImg = localStorage.getItem("yaqees-userImage") ? localStorage.getItem("yaqees-userImage") : JSON.parse(localStorage.getItem("yaqees-user"))?.image;

  const discount = promoData?.map(item => item.discount)?.reduce((a, b) => a + b, 0);

  const startTest = () => {
    (async () => {
      if (token) {
        setShowModal(true)
        dispatch(addTestAnswers([]))
        dispatch(addTrainingAnswers([]))
        localStorage.removeItem("yaqees-disabled")
      } else {
        ErrorNotify("يجب تسجيل الدخول اولا");
      }
    })();
  };


  const addComment = () => {
    (async () => {
      try {
        setSubmittingComment(true);
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/comments/exam-store/${id}`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: {comment: comment },
        });
        setRefershComment(!refreshComment);
        setComment("");
        successNotify(result.data.message);
        setSubmittingComment(false);
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmittingComment(false);
      }
    })();
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleClose = () => {
    setComment("");
  };


  const deleteComment = (id)=>{
    (async () => {
      try {
        const result = await axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_BASE_URL}/comments/${id}`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        setRefershComment(!refreshComment);
        successNotify(result.data.message);
      } catch (err) {
        ErrorNotify(err.response.data.message);
      }
    })();
  }

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };


  return (
    <div className="test-info-body">
      <div className="routes-sec">
        <Container className="d-flex align-items-center justify-content-between">
          <button className={activeBtn === 1 ? "active-btn" : ""} onClick={() => { setActiveBtn(1); scrollToSection(section1Ref) }}> {t("about-exam")} </button>
          {token && <button className={activeBtn === 4 ? "active-btn" : ""} onClick={() => { setActiveBtn(4); scrollToSection(section4Ref) }}> {t("previous-attempts")} </button>}
          <button className={activeBtn === 2 ? "active-btn" : ""} onClick={() => { setActiveBtn(2); scrollToSection(section2Ref) }}> {t("rating-opinion")} </button>
          <button className={activeBtn === 3 ? "active-btn" : ""} onClick={() => { setActiveBtn(3); scrollToSection(section3Ref) }}> {t("related-exam")} </button>
        </Container>
      </div>
      <Container>
        <Row className="my-5">
          <Col md={8} >
            <div ref={section1Ref} className="general-view">
              <h6> {t("overview")} </h6>
              <div>
                {Parse(`${data.description}`)}
              </div>
            </div>
            <hr />
            {token && <>
              <div ref={section4Ref} className="test-reports">
                <h6> {t("previous-attempts")} </h6>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"> {t("attempts")} </th>
                        <th scope="col"> {t("type")} </th>
                        <th scope="col"> {t("date")} </th>
                        <th scope="col"> {t("time")} </th>
                        <th scope="col"> {t("ratio")} </th>
                        <th scope="col"> {t("status")} </th>
                        <th scope="col"> {t("reports")} </th>
                      </tr>
                    </thead>
                    <tbody>

                      {attemptData.length > 0 ? attemptData.map(item => (
                        <tr key={item.id}>
                          <td> {t("attempt")} {attemptData.indexOf(item) + 1}</td>
                          <td>{item.answer_type === "test" ? t("exam") : t("training")}</td>
                          <td>{new Date(item.created_at).toLocaleDateString("ar-EG", {
                            weekday: 'long',
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}</td>
                          <td>{item.time}</td>
                          <td> % {item.degree} </td>
                          <td>{item.result === "success" ? t("successful") : t("failed")}</td>
                          <td>  <Link to={`/attempt-report/${item.id}/${item.exam_type}`}>
                            <p className="try-sec"> {t("view-reports")} </p>
                          </Link></td>
                        </tr>
                      )) : <tr><td colSpan="7"><p className="no-attempts"> {t("no-attempts")} </p></td></tr>}

                    </tbody>
                  </table>
                </div>
              </div>
              <hr /></>}


            <div ref={section2Ref} className="Ratings my-4">
              <h6> {t("rating-opinion")} </h6>
              <div className="bg-light comment-sec p-2 mb-5">
                <div className="d-flex flex-row align-items-center">
                  <img
                    className="rounded-circle"
                    src={userImg ? userImg : user_image}
                    width="50" alt=""
                  />
                  <textarea
                    className="form-control ml-1 shadow-none textarea mx-4 py-4 mt-3"
                    value={comment}
                    onChange={handleComment}
                    placeholder={t("write-comment")}
                  ></textarea>
                </div>
                <div className="mt-4 mb-2 d-flex justify-content-end">
                  <button
                    onClick={handleClose}
                    className="btn-cancel btn-outline-primary btn-sm ml-1 shadow-none"
                    type="button"
                  >
                    {t("cancel")}
                  </button>
                  <Button
                      htmlType="submit"
                      loading={submittingComment}
                      className="btn btn-sm shadow-none btn-add-comm mx-3 "
                      onClick={() => addComment()}
                    >
                      {!submittingComment && <p> {t("add-comment")} </p>}
                    </Button>
                </div>
              </div>

              <div className="opinions row">
                {comments?.map((item, i) => (
                  <div className="opinion-card col-md-12 mb-3" key={i}>
                    <Card>
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-between">
                            <img src={item?.user_id?.more_info?.image} alt="" />
                            <h5>{item?.user_id?.username}</h5>
                          </div>
                          {/* <h6>
                            <AiFillStar className="star-icon" /> 5 / 4.5
                          </h6> */}
                        </div>
                        <div className="comment-date mt-3 d-flex align-items-end justify-content-between">
                          <p>{item.comment}</p>
                          <p style={{ direction: "ltr", whiteSpace: "nowrap" }}>{moment(item.created_at).fromNow()}</p>
                        </div>
                        {item?.user_id?.id === JSON.parse(localStorage.getItem("yaqees-user"))?.id &&
                        <div>
                              <button  className="delete-comment-btn" onClick={()=>deleteComment(item.id)}>  {t("delete-comment")}  </button>
                        </div>}
                      </Card.Body>
                    </Card>
                  </div>
                ))}

              </div>

            </div>
            <hr />

            <div ref={section3Ref} className="related-tests my-4">
              <h6> {t("related-exam")} </h6>
              <div className="related-content row">
                {relatedData?.map(item => (
                  <div className="col-md-6" key={item.exam_id}>
                    <Card >
                      <Link to={`/exam-info/${item.exam_id}/${item.exam_type}`}>
                        <div className="clickable-card">
                          <span className="title-info">{item.exam_category}</span>
                          <Card.Img variant="top" src={item.exam_img} style={{ height: "180px" }} />
                          <Card.Body>
                            {/* <div className="head-title">
                              <p>1056 {t("student")} </p>
                              <p>
                                <AiFillStar className="star-icon" /> 5 / 4.5
                              </p>
                            </div> */}
                            <Card.Title>{item.exam_title}</Card.Title>

                          </Card.Body>
                        </div>
                      </Link>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="side-info">
              {/* <div className="d-flex align-items-center justify-content-between py-2">
                <h6>
                  <AiFillStar className="star-icon" /> 5 / 4.5
                </h6>
                <p></p>
              </div> */}
              <div className="d-flex align-items-center justify-content-between py-2">
                <h6> {t("number-reviews")} </h6>
                <p>4358</p>
              </div>

              <div className="d-flex align-items-center justify-content-between py-2">
                <h6> {t("level")} </h6>
                <p> {t("beginners")} </p>
              </div>

              <div className="d-flex align-items-center justify-content-between py-2">
                <h6> {t("difficulty-level")} </h6>
                <p> {t("medium")} </p>
              </div>

              <div className="d-flex align-items-center justify-content-between py-2">
                <h6> {t("exam-duration")} </h6>
                <p>45 {t("minute")} </p>
              </div>
              <div className="flex-column d-flex">
                {data.user_exam === true ? <button className="start-test-btn" onClick={startTest}> {t("start-exam")} </button> : <>
                  {data.price === 0 || promoData[0]?.is_availability === true ?
                  <>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                <p className="mb-0">  السعر :<br/>  </p>  <p className="text-price">  {t("free")} </p></div>
                    <Button
                      htmlType="submit"
                      loading={submittingBody}
                      className="start-free-btn"
                      onClick={() => handleBuyFree(setSubmittingBody, data.id)}
                    >
                      {!submittingBody && t("buy-free")}
                    </Button></> :
                    <>
                    
                <div className="d-flex align-items-center justify-content-between mt-3"><p className="tex-p">  السعر :<br/>  </p>  <p className="text-price"> {discount ? (data.price - discount) : data.price} $
                   {discount ?  <span> - {t("instead")} {data.price} $ </span> : ""} </p> </div>
                    <Button
                      htmlType="submit"
                      loading={submittingBody}
                      className="add-test-btn"
                      onClick={() => addToCart(setSubmittingBody)}
                    >
                      {!submittingBody && <p> {t("buy-now")} </p>}
                    </Button></>} </>
                }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TestInfoBody;
