import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ReportAnswer.css";
import ChooseReport from "../QuestionsReportTypes/ChooseReport/ChooseReport";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import TrueFalseReport from "../QuestionsReportTypes/TrueFalseReport/TrueFalseReport";
import MultiChooseReport from "../QuestionsReportTypes/MultiChooseReport/MultiChooseReport";
import PickFromListReport from "../QuestionsReportTypes/PickFromListReport/PickFromListReport";
import OrdringReport from "../QuestionsReportTypes/OrdringReport/OrdringReport";
import DragDropReport from "../QuestionsReportTypes/DragDropReport/DragDropReport";
import MatchingReport from "../QuestionsReportTypes/MatchingReport/MatchingReport";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";

function ReportAnswer({reportDataQues ,reportDataPublic ,examLevelTitles ,activeLevelBtns ,handleShowLevelReport ,loadingQues}) {

  const { t } = useTranslation();

  const sumQuesMarks = reportDataQues
  .map((item) => item.QUES_MARK)
  .reduce(function (x, y) {
    return +x + +y;
  }, 0);

const QuesMarksCorrect = reportDataQues
  .filter((item) => item.ResultSuccess === "correct")
  .map((item) => item.QUES_MARK)
  .reduce(function (x, y) {
    return +x + +y;
  }, 0);

const percentage = reportDataPublic.degree;

const allQues = reportDataQues.length;
const correct = reportDataQues.filter((item) => item.ResultSuccess === "correct").length;
const wrong = reportDataQues.filter((item) => item.ResultSuccess === "wrong").length;
const skip = reportDataQues.filter((item) => item.ResultSuccess === "").length;

const correctAnswersPercentage = Math.round((correct / allQues) * 100);
const wrongAnswersPercentage = Math.round((wrong / allQues) * 100);
const skipAnswersPercentage = Math.round((skip / allQues) * 100);

useEffect(() => {

  const elements = document.getElementsByClassName("explain");

  const elements2 = document.getElementsByClassName("preview");

  const elements3 = document.getElementsByClassName("mathLabel");

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (element) {
      if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
        window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
      }
    }
  }

  for (let i = 0; i < elements2.length; i++) {
    const element = elements2[i];

    if (element) {
      if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
        window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
      }
    }
  }

  for (let i = 0; i < elements3.length; i++) {
    const element = elements3[i];

    if (element) {
      if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
        window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
      }
    }

  }
}, []);


  return (
    <Container className="report-answer">
       <div className="d-flex align-items-center levels-allbtns mt-3">
            {examLevelTitles.map(item => (
              <div key={item.level_attempt} >
                <button className={activeLevelBtns === item.level_attempt ? "mx-2 level-btns active-level" : "mx-2 level-btns"} onClick={() => handleShowLevelReport(item.level_attempt)}>{item.title}</button>
              </div>
            ))}
          </div>
          <div className='head-title-repo my-3'>
            <div className="textLine mb-3">
        <h5 className="headTitle"> {t("report-answers")} </h5>
        <hr className="hrLine" />
      </div>

      <div className="card mt-3 mb-4">
        <div className="card-body">
        <p className='repo-desc'> عرض إجابات الطالب مع الإجابات النموذجية لكل سؤال، مع شرح خطوات الوصول للحل بشكل كامل. </p>
        </div>
      </div>
      </div>
      {loadingQues ? <Loader/> :  <Row>
        <Col md={8}>
          <div>
            {reportDataQues.map((item) => (
              <div className="questions mb-4" key={item.QuestionID}>
                {/* {<p>{reportDataQues.indexOf(item) + 1 }</p>} */}
                {item.QUS_TYPE === "5" && <ChooseReport data={item} />}
                {item.QUS_TYPE === "8" && <TrueFalseReport data={item} />}
                {item.QUS_TYPE === "6" && <MultiChooseReport data={item} />}
                {item.QUS_TYPE === "10" && <PickFromListReport data={item} />}
                {item.QUS_TYPE === "7" && <OrdringReport data={item} />}
                {item.QUS_TYPE === "11" && <DragDropReport data={item} />}
                {item.QUS_TYPE === "4" && <MatchingReport data={item} />}
              </div>
            ))}
          </div>
        </Col>
        <Col md={4}>
          <div
            className="analysis-charts repo-chart"
            style={{ margin: "0", height: "420px" }}
          >
            <div className="px-4">
              <h5 className='mb-2'> {t("degree")} </h5>
              <p> {t("description-degree")} </p>
            </div>
            <hr />
            <div className="px-4" style={{ width: 200, margin: "35px auto" }}>
              <div className="result-degree">
                <p> {t("degree")} <span> {QuesMarksCorrect} / {sumQuesMarks} </span></p>
              </div>
              <CircularProgressbar
                minValue
                value={percentage}
                text={`${percentage}%`}
                strokeWidth={8}
                styles={buildStyles({
                  pathColor: `#005C9A`,
                  textColor: "#1B1A1D",
                  trailColor: "#d6d6d67d",
                })}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between px-4">
              <div>
                <div className="progress">
                  <div className="progress-bar correct-bar" role="progressbar" style={{ width: `${correctAnswersPercentage}%` }} aria-valuenow={correctAnswersPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p> {t("correct")} <span>{correct}</span></p>
              </div>

              <div>
                <div className="progress">
                  <div className="progress-bar wrong-bar" role="progressbar" style={{ width: `${wrongAnswersPercentage}%` }} aria-valuenow={wrongAnswersPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p> {t("incorrect")} <span>{wrong}</span></p>
              </div>

              <div>
                <div className="progress">
                  <div className="progress-bar skip-bar" role="progressbar" style={{ width: `${skipAnswersPercentage}%` }} aria-valuenow={skipAnswersPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p> {t("skip")} <span>{skip}</span></p>
              </div>


            </div>
          </div>
        </Col>
      </Row>
      }
     
    </Container>
  );
}

export default ReportAnswer;
