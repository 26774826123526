import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./English/en.json";
import translationAR from "./Arabic/ar.json";

const fallbackLng = ["ar"];
const availableLanguages = ["ar", "en"];

const resources = {
  ar: {
    translation: translationAR,
  },
  en: {
    translation: translationEN,
  },
};


const savedLanguage = localStorage.getItem("yaqees-language");


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    lng: savedLanguage || "ar",

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });


i18n.on("languageChanged", (lng) => {
  localStorage.setItem("yaqees-language", lng);
});

export default i18n;
