import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import "./Specialization.css"
import { GiClawHammer, GiPencilRuler } from 'react-icons/gi';
import { MdOutlineMenuBook } from 'react-icons/md';
import { FaCalculator, FaLaptopCode } from 'react-icons/fa';
import { TbSchool } from "react-icons/tb";
import { useTranslation } from "react-i18next";


function Specialization({ setActive, setSpecialize }) {

  const { t } = useTranslation();

  const data = [{ id: 0, title: t("student"), icon: <TbSchool /> }, { id: 1, title: t("engineer"), icon: <GiPencilRuler /> }, { id: 2, title: t("teacher"), icon: <MdOutlineMenuBook /> }, { id: 3, title: t("lawyer"), icon: <GiClawHammer /> }, { id: 4, title: t("accountant"), icon: <FaCalculator /> }, { id: 5, title: t("software-developer"), icon: <FaLaptopCode /> }]

  const userData = JSON.parse(localStorage.getItem("yaqees-user"));

  const userTitle = localStorage.getItem("yaqees-userTitle")

  const [checked, setChecked] = useState(userTitle ? userTitle : userData.specialize)

  const show = false;

  const handleChooseChange = (e) => {
    setSpecialize(e.target.value);
  };

  return (
    <div className='specialization py-4'>
      <Row>
        {data.map((item) => (
          <Col key={item.id} md={4} >
            <div className={checked === item.title ? "active-radio" : "form-check"}>
              <input
                className="form-check-input"
                type="radio"
                name="answer"
                id={`flexRadioDefault${item.id}`}
                value={item.title}
                checked=""
                onClick={() => {
                  if (show === false) {
                    setChecked(item.title);
                  } else {
                    return null
                  }

                }}
                onChange={handleChooseChange}
              />
              <label
                className="form-check-label"
                htmlFor={`flexRadioDefault${item.id}`}
              >
                {item.title}
                <div className='special-icon'>{item.icon}</div>
              </label>

            </div>
          </Col>
        ))}
      </Row>
      <div className="info-btn d-flex justify-content-end mt-4">
        <button onClick={() => setActive(3)}> {t("next")} </button>
      </div>

    </div>
  )
}

export default Specialization
