import React , { useEffect } from 'react'
import Parse from "html-react-parser";
import { Col, Row } from 'react-bootstrap';
import Dragula from 'react-dragula';

function DragDropSkip({showQuestion  ,setSelectedAnswer ,setCorrectOptions}) {

    const chooseArray = JSON.parse(showQuestion?.QUS_OPTION)

      const dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
          const dragula = Dragula([componentBackingInstance ,document.getElementById("drop-target")], {
            mirrorContainer: componentBackingInstance,
            revertOnSpill: true,
            copy: true,
       
        });
          dragula.on('drop', (el, target, source, sibling) => {
           
            const result = `${el.textContent}`;
           const containerDropIn = document.getElementById("drop-target") 
            if(containerDropIn.childElementCount > 0){
              containerDropIn.innerHTML = '';
              document.getElementById("drop-target").appendChild(el)
            }else{
              document.getElementById("drop-target").appendChild(el)
            }
            setSelectedAnswer(result.trim())
            setCorrectOptions(Object.values(JSON.parse(showQuestion.QUS_CORRECT_RESPONSE)).toString().trim())
          })
        }
    
      
      };

      let str = showQuestion.QUESTION_TEXT
      let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

      useEffect(() => {

        const elements2 = document.getElementsByClassName("preview");
    
        const elements3 = document.getElementsByClassName("mathLabel");
    
        for (let i = 0; i < elements2.length; i++) {
          const element = elements2[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
        }
    
        for (let i = 0; i < elements3.length; i++) {
          const element = elements3[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
    
        }
    
    
      }, []);
    
  return (

<div className='ques pb-2'>

<h5 className='preview'>{showQuestion.QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${showQuestion.QUESTION_TEXT}`)}</h5>

{showQuestion.QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${showQuestion.QUESTION_FILE_NAME}`} controls style={{width:"100%", margin: "20px 0"}} />
          )}

<Row>
  {Object.values(chooseArray).map((item, i) => (
    <Col key={i} md={3} className='drag-drop' ref={dragulaDecorator}>
      <div className= "form-check">
        <input
          className="form-check-input"
          type="radio"
          id={`flexRadioDefault${i}`}
          value={item}
        />
        <label
          className="form-check-label mathLabel"
          htmlFor={`flexRadioDefault${i}`}
        >
         {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g,'')} </p> }
        </label>
      </div>
    </Col>
  ))}
   
</Row>
<div id="drop-target">
    </div>
</div>
  )
}

export default DragDropSkip
