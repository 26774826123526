import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Dropdown, ButtonGroup, DropdownButton } from "react-bootstrap";
import "./NavBarLogin.css";
import { Link, useLocation } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { FcBusinessman } from "react-icons/fc";
import logo from "../../Assets/images/logo.png";
import axios from "axios";
import { Button } from "antd";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import LanguageButton from "../LanguageButton/LangaugeButton"
import { useTranslation } from "react-i18next";


function NavBarLogin({ setIsLoggedIn, cartNum }) {

  const [navbar, setNavbar] = useState(false);

  const userImg = localStorage.getItem("yaqees-userImage") ? localStorage.getItem("yaqees-userImage") : JSON.parse(localStorage.getItem("yaqees-user"))?.image;

  const userName = JSON.parse(localStorage.getItem("yaqees-user"))?.username;

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const [promoCode, setPromoCode] = useState("");

  const [submittingPromo, setSubmittingPromo] = useState(1);

  const [submittingPromo2, setSubmittingPromo2] = useState(1);

  const [promoExam, setPromoExam] = useState([]);

  const { t } = useTranslation();


  const handlePromoChange = (e) => {
    setPromoCode(e.target.value)
  }

  const handleCheckPromoCode = () => {
    (async () => {
      try {
        setSubmittingPromo(2);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/get-availability-promo-code`,
          headers: {
            Accept: "application/json",
            Username: "administrator",
            Password: "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          params: { promo: promoCode, availability: true }
        });
        setSubmittingPromo(3);
        setPromoExam(result.data.data);
      } catch (err) {
        setSubmittingPromo(1);
        ErrorNotify(err.response.data.message);
      }
    })();
  };

  const handleAddPromoCode = (id, type) => {
    (async () => {
      try {
        setSubmittingPromo2(2);
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/promo-code`,
          headers: {
            Accept: "application/json",
            Username: "administrator",
            Password: "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: { promo: promoCode, exam_id: type === 2 ? id : "", package_id: type === 3 ? id : "" },
        });
        successNotify(result.data.message);
        setPromoCode("");
        setSubmittingPromo2(3);
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmittingPromo2(1);
      }
    })();
  };

  const changeNavColor = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeNavColor();
    window.addEventListener("scroll", changeNavColor);
  });

  const handleLogOut = () => {
    let languageCache = localStorage.getItem('yaqees-language');

    let languageI18nx = localStorage.getItem("i18nextLng");

    localStorage.clear();
    languageCache && localStorage.setItem('yaqees-language', languageCache);
    languageI18nx && localStorage.setItem('i18nextLng', languageI18nx);
    setIsLoggedIn(localStorage.getItem("yaqees-user"));

  }

  const location = useLocation();

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };


  return (
    <Navbar expand="lg" className={navbar ? "navbar-dark" : "navbar"}>
      <Container>
        <Link className="mob-logo" to="/">
          <img className="logo" src={logo} alt="" />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Link className="not-mob-logo" to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <Nav className="d-flex justify-content-center">
            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/" ? "active-link" : "links"}
                to="/"
              >
                {t("home")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/exams" ? "active-link" : "links"}
                to="/exams"
              >
                {t("all-exams")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/packages" ? "active-link" : "links"}
                to="/packages"
              >
                {t("combined-tests")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/blog" ? "active-link" : "links"}
                to="/blog"
              >
                {t("blog")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/about" ? "active-link" : "links"}
                to="/about"
              >
                {t("about")}
              </Link>
            </div>

            <div className="parent_links mx-2">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className={location.pathname === "/contact-us" ? "active-link" : "links"}
                to="/contact-us"
              >
                {t("contact-us")}
              </Link>
            </div>
          </Nav>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>

              <LanguageButton />

              <div className="notifi-cart">
                <div className="badge badge-warning" id="lblCartCount">
                  {cartNum ? cartNum : 0}
                </div>
                <Link to="/Cart">
                  <div className="bag-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M22 11.5066C21.4533 11.5066 21 11.0533 21 10.5066V8.66662C21 7.26662 20.4 5.90662 19.36 4.95995C18.3067 3.99995 16.9467 3.55995 15.5067 3.69328C13.1067 3.91995 11 6.37328 11 8.93328V10.2266C11 10.7733 10.5467 11.2266 10 11.2266C9.45333 11.2266 9 10.7733 9 10.2266V8.91995C9 5.33328 11.8933 2.02662 15.32 1.69328C17.32 1.50662 19.24 2.13328 20.7067 3.47995C22.16 4.79995 23 6.69328 23 8.66662V10.5066C23 11.0533 22.5467 11.5066 22 11.5066Z"
                        fill="#292D32"
                      />
                      <path
                        d="M20 30.3333H12C5.84 30.3333 4.69334 27.4666 4.4 24.68L3.4 16.6933C3.25334 15.2533 3.2 13.1866 4.6 11.64C5.8 10.3066 7.78667 9.66663 10.6667 9.66663H21.3333C24.2267 9.66663 26.2133 10.32 27.4 11.64C28.7867 13.1866 28.7467 15.2533 28.6 16.6666L27.6 24.68C27.3067 27.4666 26.16 30.3333 20 30.3333ZM10.6667 11.6666C8.41334 11.6666 6.86667 12.1066 6.08 12.9866C5.42667 13.7066 5.21334 14.8133 5.38667 16.4666L6.38667 24.4533C6.61334 26.5866 7.2 28.3466 12 28.3466H20C24.8 28.3466 25.3867 26.6 25.6133 24.48L26.6133 16.4666C26.7867 14.84 26.5733 13.7333 25.92 13C25.1333 12.1066 23.5867 11.6666 21.3333 11.6666H10.6667Z"
                        fill="#292D32"
                      />
                      <path
                        d="M20.56 17.5334C19.8133 17.5334 19.2133 16.9334 19.2133 16.2C19.2133 15.4667 19.8133 14.8667 20.5467 14.8667C21.28 14.8667 21.88 15.4667 21.88 16.2C21.88 16.9334 21.2933 17.5334 20.56 17.5334Z"
                        fill="#292D32"
                      />
                      <path
                        d="M11.2267 17.5334C10.48 17.5334 9.88 16.9334 9.88 16.2C9.88 15.4667 10.48 14.8667 11.2133 14.8667C11.9467 14.8667 12.5467 15.4667 12.5467 16.2C12.5467 16.9334 11.96 17.5334 11.2267 17.5334Z"
                        fill="#292D32"
                      />
                    </svg>
                  </div>
                </Link>
              </div>

              <div>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-user" >
                    <img className="topbarImg" src={userImg} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu id="drop" className="dropdown-menu">
                    <div className="text-center">
                      <h6
                        style={{
                          color: "#005C9A",
                          padding: "5px 10px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textDecoration: "none",
                          marginBottom: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",

                        }}
                      >
                        {userName}
                        <FcBusinessman className="icon-user" />
                      </h6>
                    </div>
                    <div className="mb-2 dropdown-item promo-dropdown">
                      {['end'].map(
                        (direction) => (
                          <DropdownButton
                            as={ButtonGroup}
                            key={direction}
                            id={`dropdown-button-drop-${direction}`}
                            drop={direction}
                            variant="secondary"
                            title={t(("availability-code"))}
                          >
                            <div className="promo-sec">
                              <div className="form-input">
                                <span className="submit-promo">
                                  {submittingPromo === 3 ? <FaCheckCircle className="done-promo" /> :
                                    <Button
                                      htmlType="submit"
                                      loading={submittingPromo === 2}
                                      className="use-promo-btn"
                                      onClick={handleCheckPromoCode}
                                    >
                                      {submittingPromo === 1 && <p> {t(("activate"))} </p>}
                                    </Button>}
                                </span>
                                <input
                                  type="text"
                                  placeholder={t(("availability-code"))}
                                  value={promoCode}
                                  name="promoCode"
                                  onChange={handlePromoChange}
                                />
                              </div>
                              {submittingPromo === 3 && <div className="d-flex align-items-center justify-content-between mt-3">
                                {promoExam.type === 2 ? <Link to={`/exam-info/${promoExam.promo_exam.exam_id}/${promoExam.promo_exam?.exam?.type}`}>

                                  <div className="d-flex align-items-center justify-content-center">
                                    <img src={promoExam.promo_exam?.exam?.more_information?.imagePath} alt="" />
                                    <p className="mx-4">{promoExam.promo_exam?.exam?.main_title}</p>
                                  </div>
                                </Link> : <Link to={`/package-info/${promoExam.promo_package.package_id}`}>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <img src={promoExam.promo_package?.package?.image} alt="" />
                                    <p className="mx-4">{promoExam.promo_package?.package?.name}</p>
                                  </div>
                                </Link>
                                }
                                {submittingPromo2 === 3 ? <FaCheckCircle className="done-promo" /> :
                                  <Button
                                    htmlType="submit"
                                    loading={submittingPromo2 === 2}
                                    className="use-promo-btn"
                                    onClick={() => handleAddPromoCode(promoExam.type === 2 ? promoExam.promo_exam.exam_id : promoExam.promo_package.package_id, promoExam.type)}
                                  >
                                    {submittingPromo2 === 1 && <p> {t(("activate"))} </p>}
                                  </Button>}
                              </div>}
                            </div>
                          </DropdownButton>
                        ),
                      )}
                    </div>
                    <Dropdown.Item className="dropdown-items">
                      <button className="logout-btn" onClick={handleLogOut} >
                        <BiLogOut className="mx-1" />
                        {t(("logout"))}
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>

          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarLogin;
