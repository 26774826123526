import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import Parse from "html-react-parser";
import { RiDragMove2Line } from "react-icons/ri";
import { BsFillSkipEndCircleFill } from 'react-icons/bs';
import { useTranslation } from "react-i18next";


function OrdringReport({data}) {

  const { t } = useTranslation();

  const userAnswerArray = data.userAnswer && JSON.parse(data.userAnswer);

    const str = data.QUS_CORRECT_RESPONSE

    const filterStr = str.replace(/{/g, '[').replace(/}/g, ']').replace(/"(\d{1,2}|100)":/g, '');
    const endARRoptions = JSON.parse(filterStr);

    let str1 = data.QUESTION_TEXT;
    let newStr = str1.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');
  
  
    let strAnswer = data.ANSWER_EXPLANATION;
    let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g,'src="https://test4you.me/');

    return (
        <div className="choose-question-report d-flex  justify-content-center">
            <Container>
                <div className="ques pt-5 pb-2">
                <div className="d-flex align-items-start justify-content-between head-ques">
                <h5 className="preview"> {data.QUESTION_TEXT.includes('src="/public')? Parse(`${newStr}`): Parse(`${data.QUESTION_TEXT}`)} </h5>
  
                {data.ResultSuccess === "correct" && (
              <p className="correct-tag">
                <IoIosCheckmarkCircle className="true-icon" /> {t("correct-answer")}
              </p>
            )}
            {data.ResultSuccess === "wrong" && (
              <p className="wrong-tag">
                <IoIosCloseCircle className="false-icon" /> {t("incorrect-answer")}
              </p>
            )}
            {data.ResultSuccess === "" && (
              <p className="skip-tag">
                <BsFillSkipEndCircleFill className='skip-icon' /> {t("question-skipped")}
              </p>
            )}
            </div>

                    {data.QUESTION_FILE_NAME && (
                        <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${data.QUESTION_FILE_NAME}`} controls />
                    )}
<Row style={{margin:"0"}}>

    {userAnswerArray ?    <Col md={6} className="drag" style={{margin:"0"}}>
                        {Object.values(userAnswerArray).map((item, i) => (
                            <div key={i} md={6}>
                                <div className={item === `${endARRoptions[i]}`? "true-check" : "false-check"}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id={`flexRadioDefault${i}`}
                                        value={item}

                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={`flexRadioDefault${i}`}
                                    >
                                        <RiDragMove2Line className="drag-icon" /> 
                                        {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g,'')} </p> }
                                    </label>
                                </div>
                            </div>
                        ))}
                    </Col> :   <Col md={6} className="drag" style={{margin:"0"}}>
                        {Object.values(endARRoptions).map((item, i) => (
                            <div key={i} md={6}>
                                <div className="true-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id={`flexRadioDefault${i}`}
                                        value={item}

                                    />
                                    <label
                                        className="form-check-label mathLabel"
                                        htmlFor={`flexRadioDefault${i}`}
                                    >
                                        <RiDragMove2Line className="drag-icon" /> 
                                        {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g,'')} </p> }
                                    </label>
                                </div>
                            </div>
                        ))}
                    </Col>
 }
                  
                 

                    </Row>
<div>
    
{data.AnsStatus === "done" && data.ResultSuccess === "correct" && (
              <>
                <div className="true-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className='explain'>
                  {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}
            {data.AnsStatus === "done" && data.ResultSuccess === "wrong" && (
              <>
                <div className="false-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className='explain'>
                  {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}

            
{data.AnsStatus === "skip" && data.ResultSuccess === "" && (
              <>
                <div className="skip-answer">
                  <h5>
                  {t("explanation")}
                  </h5>
                  <div className="explain">
                  {data.ANSWER_EXPLANATION.includes('src="/public')? Parse(`${newStrAnswer}`): Parse(`${data.ANSWER_EXPLANATION}`)}
                  </div>
                </div>
              </>
            )}
</div>
                    
                </div>
            </Container>
        </div>
    );
}

export default OrdringReport;

