import React from 'react'
import { useStopwatch } from 'react-timer-hook';
import "./Timer.css"

function Timer() {
    const { seconds, minutes, hours } = useStopwatch({ autoStart: true });

    localStorage.setItem("timer", JSON.stringify({ seconds, minutes, hours }))
    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    return (
        <div className='timer'>
            <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
        </div>

    )
}

export default Timer
