import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FcRules } from "react-icons/fc";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import Parse from "html-react-parser";
import { FcCalendar } from "react-icons/fc";
import Loader from '../Components/Loader/Loader';


function BlogInfo() {

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const { t } = useTranslation();

    const langDir = localStorage.getItem("yaqees-language")

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios({
                    method: "GET",
                    url: `${process.env.REACT_APP_BASE_URL}/blogs/${id}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Username : "administrator",
                        Password : "yaqess123456",
                    },
                });
                setData(result.data.data);
                setLoading(false);
            } catch (err) {

            }
        })();
    }, []);

    return (
        <div className='bloginfo-sec'>
                {loading ? <Loader/> : <>
                <div className="test-info-header">
                <Container>
                    <Row className="align-items-start justify-content-between">
                        <Col md={8}>
                            <h5>{data.title}</h5>
                            <div className='d-flex align-items-center'>
                                <FcRules className='blog-icon'/>
                            <p className="create-text"> {t("exam-created")} </p>
                            <div className="d-flex align-items-center my-3 mx-2">
                                <h6>{data.created_by}</h6>
                            </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <FcCalendar className='blog-icon'/>
                            <p className="create-text"> {t("post-blog")} </p>
                            <div className="d-flex align-items-center my-3 mx-2 post-time-blog">
                                {langDir === "ar" ?  new Date(data.created_date).toLocaleDateString(
                                "ar-EG",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              ) :    new Date(data.created_date).toLocaleDateString(
                                "en-Us",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              ) }
                         
                            </div>
                            </div>
                       
                        </Col>
                        <Col md={4}>
                            <img className="sec-img" src={data.image} alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='my-4 blog-desc'>
                <Container>
                    <div>{Parse(`${data.content}`)}</div>
                </Container>
            </div>
               
                </>}
          
        </div>
    )
}

export default BlogInfo
