import React from "react";
import FileInputComponent from "react-file-input-previews-base64";
import "./PersonalInfo.css";
import { FaCamera } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { FaUser, FaPhone } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { PhoneInput  , defaultCountries , parseCountry} from 'react-international-phone';
import 'react-international-phone/style.css';

function PersonalInfo({ handleChange, setSelectedFile, selectedFile, Personal, setActive ,setPersonal }) {

  const { t } = useTranslation();

  return (
    <div className="personal-info mt-2">
      <div className="choose-img d-flex align-items-center">
        <FileInputComponent
          labelText="Select file"
          labelStyle={{ display: "none" }}
          callbackFunction={(file_arr) => {
            setSelectedFile(file_arr[0].base64);
          }}
          accept="image/*"
          imagePreview={false}
          buttonComponent={
            <button>
              {selectedFile ? (
                <img className="sidebarImg-change" src={selectedFile} alt="" />
              ) : (
                <FaCamera className="camera-icon" />
              )}
            </button>
          }
        />

        <div className="change-text mx-2">
          <h6> {t("personal-image")} </h6>
          <FileInputComponent
            labelText="Select file"
            labelStyle={{ display: "none" }}
            callbackFunction={(file_arr) => {
              setSelectedFile(file_arr[0].base64);
            }}
            accept="image/*"
            imagePreview={false}
            buttonComponent={<p> {t("change")} </p>}
          />
        </div>
      </div>

      <Row className="mt-1">
        <Col md={6}>
          <div className="mb-2">
            <h6> {t("first-name")} </h6>
            <div className="form-input">
              <span>
                <FaUser />
              </span>
              <input
                type="text"
                placeholder={t("first-name")}
                value={Personal.firstName}
                name="firstName"
                onChange={handleChange}
              />
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div className="mb-2">
            <h6> {t("last-name")} </h6>
            <div className="form-input">
              <span>
                <FaUser />
              </span>
              <input
                type="text"
                placeholder={t("last-name")}
                value={Personal.lastName}
                name="lastName"
                onChange={handleChange}
              />
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div className="mb-2">
            <h6 className="phone-text"> {t("phone-number")} </h6>
              <PhoneInput
            defaultCountry="eg"
            value={Personal.phone}
            onChange={(phone) => setPersonal({ ...Personal, phone : phone })}
            placeholder={t("phone-number")}
            />
          </div>
     
        </Col>
      </Row>
      <div className="info-btn d-flex justify-content-end mt-3">
        <button onClick={() => setActive(2)}> {t("next")} </button>
      </div>
    </div>
  );
}

export default PersonalInfo;
