import React, { useState } from 'react'
import "./Cart.css"
import { Container, Card, Row, Col } from 'react-bootstrap'
import img1 from "../../Assets/images/cart-screen.png";
import { AiOutlineDelete } from "react-icons/ai";
import axios from 'axios';
import { toast } from "react-toastify";
import { Button } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Cart({ cartData, setRefresh, refresh }) {

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const [submitting, setSubmitting] = useState(false);

  const [submittingDelete, setSubmittingDelete] = useState(false);

  const [submittingDeleteAll, setSubmittingDeleteAll] = useState(false);

  const [promoCode, setPromoCode] = useState("");

  const [submittingPromo, setSubmittingPromo] = useState(1);

  const [discountData, setDiscountData] = useState("");

  const discount = localStorage.getItem("yaqees-discount");

  const { t } = useTranslation();

  const handlePromoChange = (e) => {
    setPromoCode(e.target.value)
  }


  const handleAddPromoCode = () => {
    (async () => {
      try {
        setSubmittingPromo(2);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/get-discount-promo-exams`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          params: { exam_id: cartData.map(item=> item.is_package === false ? item.package[0].id : "").filter((id) => id !== "").join(',') , promo: promoCode , discount : true , package_id : cartData.map(item=> item.is_package === false ? "" : item.package.id).filter((id) => id !== "").join(',')},
        });
        localStorage.setItem("yaqees-discount", result.data.data.parentage);
        setDiscountData(result.data.data);
        setSubmittingPromo(3);
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmittingPromo(1);
      }
    })();
  };


  const resetCart = () => {
    (async () => {
      setSubmittingDeleteAll(true);
      try {
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/cart/resetAll`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        successNotify(result.data.message);
        setRefresh(!refresh);
        setSubmittingDeleteAll(false);
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmittingDeleteAll(false);
      }
    })();
  };

  const deleteTestCart = (examId) => {
    (async () => {
      try {
        setSubmittingDelete((prevsubmitting) => ({
          ...prevsubmitting,
          [examId]: true,
        }));
        const result = await axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_BASE_URL}/cart/remove/${examId}`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        successNotify(result.data.message);
        setRefresh(!refresh)
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmittingDelete((prevsubmitting) => ({
          ...prevsubmitting,
          [examId]: false,
        }));
      }
    })();
  };

  const deletePackagetCart = (packageId) => {
    (async () => {
      try {
        setSubmittingDelete((prevsubmitting) => ({
          ...prevsubmitting,
          [packageId]: true,
        }));
        const result = await axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_BASE_URL}/cart/remove/package/${packageId}`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        successNotify(result.data.message);
        setRefresh(!refresh)
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmittingDelete((prevsubmitting) => ({
          ...prevsubmitting,
          [packageId]: false,
        }));
      }
    })();
  };

  const handleBuy = (ids) => {
    (async () => {
      try {
        setSubmitting(true);
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/payment`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: {
            promo: promoCode,
            data: ids,
          }
        });
        setPromoCode("");
        localStorage.removeItem("yaqees-discount");
        localStorage.setItem("yaqees-purchase", result.data.data.order_reference)
        window.location.replace(result.data.data.order_paypage_url);
      } catch (err) {
        setSubmitting(false);
      }
    })();
  };


  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <div className='parent-cart'>
      <Container>
        <h5> {t("cart")} </h5>
        {cartData.length > 0 ? <Row>
          <Col md={8}>
          {cartData.map((item ,i)=> (
            <div key={i}>
       { item.is_package === false ?
          <div>
            {item.package.map(item => (
              <Card className="parent-card flex-row " key={item.exam_id}>
                <Card.Img variant="top" src={item.image} style={{ width: "300px", height: "auto" }} />
                <Card.Body className='d-flex flex-column justify-content-between px-4'>
                  <div className='d-flex align-items-start justify-content-between my-2'>
                    <p className="cart-title">{item.main_title}</p>

                  </div>
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div className="last-title">
                      {item.price === 0 ? (
                        <p>
                          {t("free")}<span></span>
                        </p>
                      ) : (
                        <p>{item.price}$</p>
                      )}
                    </div>
                    <Button
                      htmlType="submit"
                      loading={submittingDelete[item.exam_id]}
                      className='delete-btn'
                      onClick={() => deleteTestCart(item.exam_id)}
                    >
                      {!submittingDelete[item.exam_id] && <p><AiOutlineDelete /> {t("delete")} </p>}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div> :
          <div key={item.package.id}>
            <Card className="parent-card flex-row " >
              <Card.Img variant="top" src={item.package.image} style={{ width: "300px", height: "auto" }} />
              <Card.Body className='d-flex flex-column justify-content-between px-4'>
                <div className='d-flex align-items-start justify-content-between my-2'>
                  <p className="cart-title">{item.package.name}</p>

                </div>
                <div className="d-flex align-items-center justify-content-between my-2">
                  <div className="last-title">
                    {item.package.price === 0 ? (
                      <p>
                        {t("free")}<span></span>
                      </p>
                    ) : (
                      <p>{item.package.price}$</p>
                    )}
                  </div>
                  <Button
                    htmlType="submit"
                    loading={submittingDelete[item.package.id]}
                    className='delete-btn'
                    onClick={() => deletePackagetCart(item.package.id)}
                  >
                    {!submittingDelete[item.package.id] && <p><AiOutlineDelete /> {t("delete")} </p>}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>}
          </div>
      ))}
          </Col>

          <Col md={4}>
            <div className='total-salary'>
              <div className="d-flex align-items-center justify-content-between py-2" style={{ borderBottom: "1px solid #D9D9D9", marginBottom: "10px" }}>
                <h6> {t("exams-number")} </h6>
                <p>{cartData.length}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between py-1">
                <h6> {t("amount")} </h6>
                <p>{cartData.map(item =>  item.is_package === false ? item.package.map(item2 =>item2.price) : item.package.price ).flat().reduce((a, b) => a + b, 0)} {t("dollar")} </p>
              </div>

              <div className="d-flex align-items-center justify-content-between py-1">
                <h6> {t("discounts")}  </h6>
                <p>{(cartData.map(item =>  item.is_package === false ? item.package.map(item2 =>item2.price) : item.package.price ).flat().reduce((a, b) => a + b, 0) * Math.round(discount)) / 100} {t("dollar")} </p>
              </div>

              <div className="d-flex align-items-center justify-content-between py-1">
                <h6> {t("promocode")}  </h6>
                <div className="promo-sec">
                  <div className="form-input">
                    <span className='submit-promo'>
                      {submittingPromo === 3 ? <FaCheckCircle className="done-promo me-4" /> :
                        <Button
                          htmlType="submit"
                          loading={submittingPromo === 2}
                          className="use-promo-btn"
                          onClick={handleAddPromoCode}
                        >
                          {submittingPromo === 1 && <p> {t("activate")}  </p>}
                        </Button>}
                    </span>
                    <input
                      type="text"
                      placeholder={t("promocode")}
                      value={promoCode}
                      name="promoCode"
                      onChange={handlePromoChange}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between py-1">
                <h6> {t("total")} </h6>
                <p>{cartData.map(item =>  item.is_package === false ? item.package.map(item2 =>item2.price) : item.package.price ).flat().reduce((a, b) => a + b, 0) - (cartData.map(item =>  item.is_package === false ? item.package.map(item2 =>item2.price) : item.package.price ).flat().reduce((a, b) => a + b, 0) * Math.round(discount)) / 100} {t("dollar")} </p>
              </div>
              <div className='flex-column d-flex'>
                <Button
                  htmlType="submit"
                  loading={submitting}
                  className='buy-btn'
                  onClick={() => handleBuy(cartData.map(packageObj => {
                    const package_id = Array.isArray(packageObj.package) ? null : packageObj.package.id;
                    const exams_ids = Array.isArray(packageObj.package) ? packageObj.exams_ids : packageObj.package.exams_ids;
                    const exams = Array.isArray(packageObj.package) ?
                      packageObj.package.map(exam => exam.exam_id) :
                      packageObj.package.exams.map(exam => exam.exam_id);
                  
                    return {
                      package_id,
                      exams,
                    };
                  }))}
                >
                  {!submitting && <p>{t("pay")}  {cartData.map(item =>  item.is_package === false ? item.package.map(item2 =>item2.price) : item.package.price ).flat().reduce((a, b) => a + b, 0)  - (cartData.map(item =>  item.is_package === false ? item.package.map(item2 =>item2.price) : item.package.price ).flat().reduce((a, b) => a + b, 0) * Math.round(discount)) / 100} {t("dollar")} </p>}
                </Button>

                <Button
                  htmlType="submit"
                  loading={submittingDeleteAll}
                  className='delete-allcart-btn'
                  onClick={resetCart}
                >
                  {!submittingDeleteAll && t("delete-cart")}
                </Button>
              </div>
            </div>
          </Col>
        </Row> : <div className='no-tests'></div>}


      </Container>

    
    </div>
  )
}

export default Cart