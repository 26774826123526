import React from "react";
import "./AllTests.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import Pagination from "../Pagination/Pagination";
import { TwoThumbInputRange } from "react-two-thumb-input-range";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";


function AllTests({
    data,
    loading,
    loadingAll,
    getPage,
    totalCount,
    perPage,
    filterData,
    filterDataLanguage,
    filterDataTags,
    handleFilterChange,
    handleFilterLanguageChange,
    handleFilterTagsChange,
    handleFilterTypesChange,
    handleFilterCountryChange,
    handleFilterPrice,
    handleSearch,
    price,
    setPrice,
    selectedTypeValue,
    selectedTagValue,
    selectedLanguageValue,
    selectedCountryValue,
    categories,
    countries,
    addToCart,
    cartData,
    submitting
}) {

    const { t } = useTranslation();


    const testTypes = [{ id: 0, title: "اختبارات تشخيصية", value: "simple" }, { id: 1, title: "اختبارات ألية", value: "diagnostic" }, { id: 2, title: "اختبارات مجمعة", value: "combined" }]

    const handlePriceMin = (e) => {
        setPrice([e.target.value, price[1]]);
    };

    const handlePriceMax = (e) => {
        setPrice([price[0], e.target.value]);
    };

    const onValueSChange = (values) => {
        setPrice(values);
    };

const cartIds = cartData?.reduce((acc, item) => {
    const packageItem = item.package;
    if (Array.isArray(packageItem)) {
        if (!item.is_package) {
            packageItem.forEach(pkg => acc.push(pkg.exam_id));
        }
    } else {
        if (!packageItem.is_package) {
            acc.push(...packageItem.exams_ids);
        }
    }
    return acc;
}, []);


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e.target.value);
        }
    };

    const handleClickSearch = () => {
        const inputValue = document.getElementById('searchInput').value;
        handleSearch(inputValue);
    };

    const handleSearchChange = (e) => {
if(e.target.value === ""){
    handleSearch(e.target.value);
}else{
    return null
}
    }
    
    return (
        <div className="all-tests-parent">
            {loadingAll ? <Loader/> : <Container>
                <Row>
                    <Col xs={12} md={3}>
                        <div className="sidebar">
                            <h6> {t("exam-type")} </h6>
                            <div className="d-flex flex-column form-input my-3">
                                <span>
                                    <FiSearch className="ms-4 search-icon" onClick={handleClickSearch} />
                                </span>
                                <input id="searchInput" type="text" name="" placeholder={t("exams-search")} onKeyDown={handleKeyDown} onChange={(e)=>handleSearchChange(e)}/>
                            </div>
                            <div className="all-filters">
                                {filterData?.map((item) => (
                                    <div key={item.id} className="parent-collapse">
                                        <Accordion defaultActiveKey="0" >
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center">
                                                        {item.name}
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                    <>
                                                        {item.children.map((category) => (
                                                            category.children ? <div key={category.id} className="parent-collapse">
                                                                <Accordion defaultActiveKey="0" >
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>{category.name}</Accordion.Header>
                                                                        <Accordion.Body style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            {category.children.map(child =>
                                                                                child.children ? <div key={child.id} className="parent-collapse">
                                                                                    <Accordion defaultActiveKey="0">
                                                                                        <Accordion.Item eventKey="0">
                                                                                            <Accordion.Header>{category.name}</Accordion.Header>
                                                                                            <Accordion.Body>
                                                                                                {child.children.map(child2 =>
                                                                                                    <div key={child2.id} className="form-check" onChange={handleFilterChange}>
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="checkbox"
                                                                                                            value={child2.id}
                                                                                                            id={`flexCheckDefault${child2.name}${child2.id}`}
                                                                                                            checked={categories.includes(`${child2.id}`)}
                                                                                                            readOnly
                                                                                                        />
                                                                                                        <label className="form-check-label" htmlFor={`flexCheckDefault${child2.name}${child2.id}`}>
                                                                                                            {child2.name}
                                                                                                        </label>
                                                                                                    </div>)}

                                                                                            </Accordion.Body>
                                                                                        </Accordion.Item>
                                                                                    </Accordion>
                                                                                </div> : <div
                                                                                    className="form-check"
                                                                                    key={child.id}
                                                                                    onChange={handleFilterChange}
                                                                                >
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        value={child.id}
                                                                                        id={`flexCheckDefault${child.name}${child.id}`}
                                                                                        checked={categories.includes(`${child.id}`)}
                                                                                        readOnly
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor={`flexCheckDefault${child.name}${child.id}`}>
                                                                                        {child.name}
                                                                                    </label>
                                                                                </div>)}

                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div> : <div
                                                                className="form-check"
                                                                key={category.id}
                                                                onChange={handleFilterChange}
                                                            >
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value={category.id}
                                                                    id={`flexCheckDefault${category.name}${category.id}`}
                                                                    checked={categories.includes(`${category.id}`)}
                                                                    readOnly
                                                                />
                                                                <label className="form-check-label" htmlFor={`flexCheckDefault${category.name}${category.id}`}>
                                                                    {category.name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                ))}

                                {/* <div className="parent-collapse">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>نوع الاختبار</Accordion.Header>
                                            <Accordion.Body>
                                                {testTypes.map(item => (

                                                    <div className="form-check" key={item.id} onClick={() => handleFilterTypesChange(item.value)}>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id={`flexRadioDefault${item.title}${item.id}`} value={item.value} checked={selectedTypeValue === item.value} readOnly />
                                                        <p className="form-check-label" htmlFor={`flexRadioDefault${item.title}${item.id}`}>
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                ))}

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div> */}

<div className="parent-collapse">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>الدولة</Accordion.Header>
                                            <Accordion.Body>
                                                {countries.map(item => (
                                                    <div className="form-check" key={item.id} onClick={() => handleFilterCountryChange(item.id)}>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault22" id={`flexRadioDefault${item.name}${item.id}`} value={item.id} checked={selectedCountryValue === item.id} readOnly />
                                                        <p className="form-check-label" htmlFor={`flexRadioDefault${item.name}${item.id}`}>
                                                            {item.name}
                                                        </p>
                                                    </div>
                                                ))}

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                                <div className="parent-collapse">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>اللغة</Accordion.Header>
                                            <Accordion.Body>
                                                {filterDataLanguage.map(item => (
                                                    <div className="form-check" key={item.id} onClick={() => handleFilterLanguageChange(item.id)}>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault22" id={`flexRadioDefault${item.language}${item.id}`} value={item.id} checked={selectedLanguageValue === item.id} readOnly />
                                                        <p className="form-check-label" htmlFor={`flexRadioDefault${item.language}${item.id}`}>
                                                            {item.language}
                                                        </p>
                                                    </div>
                                                ))}

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                                {/* <div className="parent-collapse">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>الكلمات الدالة</Accordion.Header>
                                            <Accordion.Body>
                                                {filterDataTags.map(item => (
                                                    <div className="form-check" key={item.id} onClick={() => handleFilterTagsChange(item.id)}>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault33" id={`flexRadioDefault${item.name}${item.id}`} value={item.id} checked={selectedTagValue === item.id} readOnly />
                                                        <p className="form-check-label" htmlFor={`flexRadioDefault${item.name}${item.id}`}>
                                                            {item.name}
                                                        </p>
                                                    </div>
                                                ))}

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div> */}

                                <div className="parent-collapse">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>السعر</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="price-filter">
                                                    <div
                                                        className="range"
                                                        style={{ direction: "ltr", marginTop: "40px" }}
                                                    >
                                                        <TwoThumbInputRange
                                                            onChange={onValueSChange}
                                                            values={price}
                                                            min={0}
                                                            max={1000}
                                                            className="mt-5"

                                                            trackColor="#005C9A"
                                                            thumbColor="#005C9A"
                                                        />
                                                    </div>
                                                    <div
                                                        className="d-flex justfiy-content-between align-items-center mt-4 sallary"
                                                        style={{ direction: "ltr" }}
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder="من"
                                                            name="from"
                                                            value={price[0]}
                                                            onChange={handlePriceMin}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="إلى"
                                                            name="to"
                                                            value={price[1]}
                                                            onChange={handlePriceMax}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                                        <button onClick={handleFilterPrice}>بحث</button>
                                                    </div>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={9}>
                        <div className="all-tests">
                            <h5> {t("all-exams")} </h5>
                            {loading ? (
                               <Loader/>
                            ) : (
                                <>
                                    <Row>
                                        {data.length !== 0 ? (
                                            <>
                                                {data.map((item) => (
                                                    <Col xs={12} md={4} key={item.exam_id}>
                                                        <Card>
                                                            <Link
                                                                to={`/exam-info/${item.exam_id}/${item.exam_type}`}
                                                            >
                                                                <div className="clickable-card">
                                                                    <span className="title-info">
                                                                        {item.exam_category}
                                                                    </span>
                                                                    <Card.Img variant="top" src={item.exam_img} />
                                                                    <Card.Body>
                                                                        {/* <div className="head-title">
                                                                            <p>1056 {t("student")}</p>
                                                                            <p>
                                                                                <AiFillStar className="star-icon" /> 5 /
                                                                                4.5
                                                                            </p>
                                                                        </div> */}
                                                                        <Card.Title>{item?.exam_title?.length > 70 ? item.exam_title.substring(0, 70) + " .... " : item.exam_title}</Card.Title>

                                                                    </Card.Body>
                                                                </div>
                                                            </Link>
                                                            {item.user_exam === true ? <div className="last-title"> <p>
                                                                {t("purchased")}
                                                            </p></div> : <div className="last-title">
                                                                {item.exam_price === 0 || item?.promo_code[0]?.is_availability === true ? (

                                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                                        <p> {t("free")} <span></span> </p>
                                                                        {item.exam_price > 0 && <p className="discount">{item.exam_price}$</p>}
                                                                    </div>
                                                                ) : (
                                                                    <p>{item.exam_price}$</p>
                                                                )}
                                                                {item.exam_price === 0 || item?.promo_code[0]?.is_availability === true ? "" : <div className="add-to-bag" onClick={() => addToCart(item.exam_id)}>
                                                                    <Button
                                                                        htmlType="submit"
                                                                        loading={submitting[item.exam_id]}
                                                                        className="add-bag-btn"
                                                                    >
                                                                        {!submitting[item.exam_id] && <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="18"
                                                                            height="21"
                                                                            viewBox="0 0 18 21"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M16.96 7.96002C16.29 7.22002 15.28 6.79002 13.88 6.64002V5.88002C13.88 4.51002 13.3 3.19002 12.28 2.27002C11.25 1.33002 9.90999 0.890022 8.51999 1.02002C6.12999 1.25002 4.11999 3.56002 4.11999 6.06002V6.64002C2.71999 6.79002 1.70999 7.22002 1.03999 7.96002C0.0699899 9.04002 0.09999 10.48 0.20999 11.48L0.90999 17.05C1.11999 19 1.90999 21 6.20999 21H11.79C16.09 21 16.88 19 17.09 17.06L17.79 11.47C17.9 10.48 17.92 9.04002 16.96 7.96002ZM8.65999 2.41002C9.65999 2.32002 10.61 2.63002 11.35 3.30002C12.08 3.96002 12.49 4.90002 12.49 5.88002V6.58002H5.50999V6.06002C5.50999 4.28002 6.97999 2.57002 8.65999 2.41002ZM5.41999 12.15H5.40999C4.85999 12.15 4.40999 11.7 4.40999 11.15C4.40999 10.6 4.85999 10.15 5.40999 10.15C5.96999 10.15 6.41999 10.6 6.41999 11.15C6.41999 11.7 5.96999 12.15 5.41999 12.15ZM12.42 12.15H12.41C11.86 12.15 11.41 11.7 11.41 11.15C11.41 10.6 11.86 10.15 12.41 10.15C12.97 10.15 13.42 10.6 13.42 11.15C13.42 11.7 12.97 12.15 12.42 12.15Z"
                                                                                fill={cartIds?.includes(item.exam_id) ? "black" : "#D9D9D9"}
                                                                            />
                                                                        </svg>}
                                                                    </Button>

                                                                </div>}
                                                            </div>}

                                                        </Card>
                                                    </Col>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="no-tests"> {t("no-exams")} </p>
                                        )}
                                    </Row>
                                </>
                            )}

                        </div>
                        {totalCount > perPage && (
                            <Pagination
                                getPage={getPage}
                                totalCount={totalCount}
                                perPage={perPage}
                            />
                        )}
                    </Col>
                </Row>
            </Container>}

        </div>
    );
}

export default AllTests;
