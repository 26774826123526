import React , { useEffect } from 'react'
import Parse from "html-react-parser";
import Select from 'react-select';

function PickFromListSkip({showQuestion ,setSelectedAnswer ,setCorrectOptions}) {
  
    const chooseArray = JSON.parse(showQuestion?.QUS_OPTION)

    const optionsArray = Object.values(chooseArray)

   const myArray = optionsArray.map((item=> ({label: item, value: item})));

    const handleChooseChange = (e) => {
        setSelectedAnswer(e.value);
        setCorrectOptions(Object.values(JSON.parse(showQuestion.QUS_CORRECT_RESPONSE)).toString())
    };

    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled ? undefined : isSelected ? "rgba(0, 92, 154, 0.1)" : isFocused ? undefined :null,
            color: isSelected ? "black": "#333333",
            cursor: isFocused ?" pointer":null,
            border: isSelected ? "1px solid #005C9A": null,
            borderRadius: isSelected ? "0" :null,
          };
        }
      };

      let str = showQuestion.QUESTION_TEXT
      let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

      useEffect(() => {

        const elements2 = document.getElementsByClassName("preview");
    
        const elements3 = document.getElementsByClassName("mathLabel");
    
        for (let i = 0; i < elements2.length; i++) {
          const element = elements2[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
        }
    
        for (let i = 0; i < elements3.length; i++) {
          const element = elements3[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
    
        }
    
    
      }, []);
    
    return (
    <div className='ques pb-2'>
       <h5 className='preview'>{showQuestion.QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${showQuestion.QUESTION_TEXT}`)}</h5>

    {showQuestion.QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${showQuestion.QUESTION_FILE_NAME}`} controls style={{width:"100%", margin: "20px 0"}} />
          )}

    <div className="form-input mt-4">
    <Select
className="basic-single mathLabel"
classNamePrefix="select"
defaultValue={myArray[0]}
options={myArray}
onChange={handleChooseChange}
styles={colourStyles}
/>
    </div>
</div>
  )
}

export default PickFromListSkip
