import React from 'react'
import { Container } from 'react-bootstrap';
import companyLogo from "../../Assets/images/company-logo.png"
import "./BasicFooter.css"
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";


function BasicFooter() {
    return (
        <div className='footer' style={{ padding: "25px 0" }}>
            <Container>
                <div className='d-flex align-items-center justify-content-between all-rights'>
                    <div className='d-flex align-items-center'>
                        <p>جميع الحقوق محفوظة 2023</p>
                        <img className='company-logo' src={companyLogo} alt='' />
                    </div>

                    <div className='d-flex align-items-center'>
                        <AiFillYoutube className='media-icons' />
                        <AiFillInstagram className='media-icons' />
                        <FaTwitter className='media-icons' />
                        <FaFacebookF className='media-icons' />

                    </div>
                </div>
            </Container>
        </div>
    )
}

export default BasicFooter
