import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PackageInfoHeader from "../Components/PackageInfoHeader/PackageInfoHeader";
import PackageInfoBody from "../Components/PackageInfoBody/PackageInfoBody";
import Loader from "../Components/Loader/Loader";

function PackageInfo({refresh , setRefresh}) {

    const [buy, setBuy] = useState(true);
  
    const [data, setData] = useState([]);
  
    const [promoData, setPromoData] = useState([]);
  
    const [comments, setComments] = useState([]);
  
    const [loading, setLoading] = useState(false);
  
    const [refreshComment, setRefershComment] = useState(false);
  
    const [promoRefresh, setPromoRefresh] = useState(false);

    const [submittingHeader, setSubmittingHeader] = useState(false);
  
    const [submittingBody, setSubmittingBody] = useState(false);
    
    const { id } = useParams();

    const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

    const userId = JSON.parse(localStorage.getItem("yaqees-user"))?.id;

  
    useEffect(() => {
      (async () => {
        try {
          setLoading(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/packages/${id}?user_id=${userId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username : "administrator",
              Password : "yaqess123456",
            },
          });
          setData(result.data.data);
          setPromoData(result.data.data.promo_code);
          setComments(result.data.data.comments);
          setLoading(false);
        } catch (err) {
        }
      })();
  
    }, []);

    useEffect(() => {
      (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/packages/${id}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username : "administrator",
              Password : "yaqess123456",
            },
          });
          setComments(result.data.data.comments)
        } catch (err) {
  
        }
      })();
  
    }, [refreshComment]);


    const addToCart = (loader) => {
        (async () => {
          try {
            loader(true);
            const result = await axios({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/cart-store/${id}`,
              headers: {
                Accept: "application/json",
                Username : "administrator",
                Password : "yaqess123456",
                Authorization: "Bearer " + token,
                token: token,
              },
            });
            setRefresh(!refresh)
            successNotify(result.data.message);
            loader(false);
          } catch (err) {
            ErrorNotify(err.response.data.message);
            loader(false);
          }
        })();
      };


      const handleBuyFree = (loader , examsId, packageId) => {
        (async () => {
          try {
            loader(true);
            const result1 = await axios({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/add-exam-to-user`,
              headers: {
                Accept: "application/json",
                Username : "administrator",
                Password : "yaqess123456",
                Authorization: "Bearer " + token,
                token: token,
              },
              data:{exam_ids : examsId.toString() , package_id: packageId}
            });
                const result = await axios({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/packages/${id}?user_id=${userId}`,
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Username : "administrator",
                    Password : "yaqess123456",
                  },
                });
                setData(result.data.data);
                setPromoData(result.data.data.promo_code);
                setComments(result.data.data.comments);
            successNotify(result1.data.message);
            loader(false);
          } catch (err) {
            ErrorNotify(err.response.data.message);
            loader(false);
          }
        })();
      };
    

      const successNotify = (msg) => {
        toast.success(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };
    
      const ErrorNotify = (msg) => {
        toast.error(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: 'Almarai' }}>
              {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
            </h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };

  return (
    <div className='package-info-parent'>
 {loading ? <Loader/> : <><PackageInfoHeader data={data} addToCart={addToCart} setSubmittingHeader={setSubmittingHeader} submittingHeader={submittingHeader} promoData={promoData} handleBuyFree={handleBuyFree} />
 <PackageInfoBody data={data} id={id} setRefershComment={setRefershComment} refreshComment={refreshComment} comments={comments} addToCart={addToCart} submittingBody={submittingBody} setSubmittingBody={setSubmittingBody} promoData={promoData} handleBuyFree={handleBuyFree}/>
    </>}
    </div>
  
  )
}

export default PackageInfo
