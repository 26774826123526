import React from "react";
import "./ProfileHead.css";
import { MdSettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProfileHead() {

  const { t } = useTranslation();

  const name = JSON.parse(localStorage.getItem("yaqees-user")).username;
  const userImg = localStorage.getItem("yaqees-userImage") ? localStorage.getItem("yaqees-userImage") : JSON.parse(localStorage.getItem("yaqees-user"))?.image;
  const userTitle = localStorage.getItem("yaqees-userTitle") ? localStorage.getItem("yaqees-userTitle") : JSON.parse(localStorage.getItem("yaqees-user"))?.specialize;

  return (
    <div className="profile-head">
      <div className="section1"></div>

      <div className="section2">
        <img src={userImg} alt="" />

        <div
          className="d-flex align-items-center justify-content-between px-4 user-name"
        >
          <div>
            <h6>{name}</h6>
            <p>{userTitle}</p>
          </div>
          <Link to="/account-setting">
            <button>
              <MdSettings className="ms-1 setting-icon" /> {t("Edit-account")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProfileHead;
