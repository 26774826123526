import React, { useEffect, useState } from 'react'
import AllPackages from '../Components/AllPackages/AllPackages'
import axios from 'axios';
import { Container, Pagination } from 'react-bootstrap';
import { toast } from "react-toastify";

function Packages({setRefresh ,refresh ,cartData}) {

    const [data, setData] = useState([]);
  
    const [loading, setLoading] = useState(false);
  
    const [totalCount, setTotalCount] = useState("");
  
    const [perPage, setPerPage] = useState("");

    const [submitting, setSubmitting] = useState(false);

    const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

    const userId = JSON.parse(localStorage.getItem("yaqees-user"))?.id;


    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/packages?user_id=${userId}`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Username : "administrator",
                Password : "yaqess123456",
              },
            });
            setData(result.data.data.data);
            setTotalCount(result.data.total);
            setPerPage(result.data.per_page);
            setLoading(false);
          } catch (err) {
    
          }
        })();
      }, []);


      const getPage = async (page) => {
        setLoading(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/packages?page=${page}&user_id=${userId}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username : "administrator",
            Password : "yaqess123456",
          },
        });
        setData(result.data.data.data);
        setTotalCount(result.data.total);
        setPerPage(result.data.per_page);
        setLoading(false);
    
      };

      const addToCart = (packageId) => {
        (async () => {
          try {
            setSubmitting((prevsubmitting) => ({
              ...prevsubmitting,
              [packageId]: true,
            }));
            const result = await axios({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/cart-store/${packageId}`,
              headers: {
                Accept: "application/json",
                Username : "administrator",
                Password : "yaqess123456",
                Authorization: "Bearer " + token,
                token: token,
              },
            });
            setRefresh(!refresh)
            successNotify(result.data.message);
            setSubmitting((prevsubmitting) => ({
              ...prevsubmitting,
              [packageId]: false,
            }));
          } catch (err) {
            ErrorNotify(err.response.data.message);
            setSubmitting((prevsubmitting) => ({
              ...prevsubmitting,
              [packageId]: false,
            }));
          }
        })();
      };

      const successNotify = (msg) => {
        toast.success(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };
    
      const ErrorNotify = (msg) => {
        toast.error(
          <div className="d-flex justify-content-around align-items-center">
            <h6 style={{ fontFamily: 'Almarai' }}>
              {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
            </h6>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      };

  return (
    <div className='parent-package'>
        <Container>
        <AllPackages data={data} cartData={cartData} loading={loading} addToCart={addToCart} submitting={submitting}/>
         {totalCount > perPage && (
        <Pagination
            getPage={getPage}
            totalCount={totalCount}
            perPage={perPage}
        />
    )}
    </Container>
    </div>
  )
}

export default Packages
