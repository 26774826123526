import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import footerLogo from "../../Assets/images/logo.png"
import companyLogo from "../../Assets/images/company-logo.png"
import "./Footer.css"
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


function Footer() {
    
    const { t } = useTranslation();

    return (
        <div className='footer'>
            <Container>
                <Row className='footer-content'>
                    <Col xs={12} md={2}>
                        <img src={footerLogo} alt='' />
                    </Col>
                    <Col xs={6} md={2}>
                        <div>
                            <h6> {t("language-exams")}  </h6>
                            <p>  {t("placement-exams")} </p>
                            <p>  {t("diagnostic-exams")} </p>
                            <p>  {t("achievement-exams")} </p>
                        </div>
                    </Col>
                    <Col xs={6} md={2}>
                        <div>
                            <h6> {t("educational-exams")} </h6>
                            <p> {t("diagnostic-exams")} </p>
                            <p> {t("capabilities-exams")} </p>
                            <p> {t("final-exams")} </p>
                        </div>
                    </Col>

                    <Col xs={6} md={2}>
                        <div>
                            <h6> {t("important-links")} </h6>
                            <Link to="/about">
                            <p> {t("about")} </p>
                            </Link>
                            <Link to="/contact-us">
                            <p> {t("contact-us")} </p>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <div className='d-flex align-items-center justify-content-between all-rights mt-4'>
                    <div className='d-flex align-items-center'>
                        <p> {t("allrights-copy")} </p>
                        <img className='company-logo' src={companyLogo} alt='' />
                    </div>

                    <div className='d-flex align-items-center'>
                       
                        {/* <AiFillYoutube className='media-icons' /> */}
                        <Link to="https://www.instagram.com/yaqees">
                        <AiFillInstagram className='media-icons' />
                        </Link>
                        <Link to="https://twitter.com/Yaqees1">
                        <FaTwitter className='media-icons' />
                        </Link>
                        <Link to="https://www.facebook.com/Yaqees">
                        <FaFacebookF className='media-icons' />
                        </Link>

                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer
