import React, { useState } from 'react'
import { Container, Modal } from 'react-bootstrap';
import "./ModalSkipAnswerTest.css"
import { useDispatch, useSelector } from 'react-redux';
import { addTestAnswers } from '../../Redux/Reducers/reviewsAnswerSlice';
import ChooseSkip from '../QuestionsSkipTypes/ChooseSkip/ChooseSkip';
import TrueFalseSkip from '../QuestionsSkipTypes/TrueFalseSkip/TrueFalseSkip';
import PickFromListSkip from '../QuestionsSkipTypes/PickFromListSkip/PickFromListSkip';
import MultiChooseSkip from '../QuestionsSkipTypes/MultiChooseSkip/MultiChooseSkip';
import OrdaringSkip from '../QuestionsSkipTypes/OrdaringSkip/OrdaringSkip';
import DragDropSkip from '../QuestionsSkipTypes/DragDropSkip/DragDropSkip';
import MatchingSkip from '../QuestionsSkipTypes/MatchingSkip/MatchingSkip';
import { useTranslation } from "react-i18next";

function ModalSkipAnswerTest({ showModal, setShowModal, showQuestion, questionNumber }) {

  const [selectedAnswer, setSelectedAnswer] = useState('')
  const [correctOptions, setCorrectOptions] = useState("");

  const [checked, setChecked] = useState("");

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const reduxData = useSelector((state) => state.review.testData);



  const handleclose = () => {
    setShowModal(false)
    setSelectedAnswer("");
    setChecked("")
  };

  const handleConfirm = () => {
    const newState = reduxData.map(obj => {
      if (obj.QUS_ID === showQuestion.QUS_ID) {
        return { ...obj, status: "done", userAnswer: selectedAnswer, result: selectedAnswer === correctOptions || JSON.stringify(selectedAnswer) === correctOptions || correctOptions === "true" ? "correct" : "wrong" };
      }
      return obj;
    });
    dispatch(addTestAnswers(newState))
    setShowModal(false)
    setSelectedAnswer("");
    setChecked("")
  }


  return (
    <Modal
      show={showModal}
      onHide={handleclose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='skip-test-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t("question")} {questionNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className='choose-question-modal d-flex align-items-center justify-content-center'>
            <Container >

              {showQuestion?.QUS_TYPE === 5 && <ChooseSkip showQuestion={showQuestion} setSelectedAnswer={setSelectedAnswer} checked={checked} setChecked={setChecked} setCorrectOptions={setCorrectOptions} />}
              {showQuestion?.QUS_TYPE === 8 && <TrueFalseSkip showQuestion={showQuestion} setSelectedAnswer={setSelectedAnswer} checked={checked} setChecked={setChecked} setCorrectOptions={setCorrectOptions} />}
              {showQuestion?.QUS_TYPE === 6 && < MultiChooseSkip showQuestion={showQuestion} checked={checked} setChecked={setChecked} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} />}
              {showQuestion?.QUS_TYPE === 10 && <PickFromListSkip showQuestion={showQuestion} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} />}
              {showQuestion?.QUS_TYPE === 7 && <OrdaringSkip showQuestion={showQuestion} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} />}
              {showQuestion?.QUS_TYPE === 11 && <DragDropSkip showQuestion={showQuestion} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} />}
              {showQuestion?.QUS_TYPE === 4 && <MatchingSkip showQuestion={showQuestion} setSelectedAnswer={setSelectedAnswer} setCorrectOptions={setCorrectOptions} />}
            </Container>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {selectedAnswer ? <button className='confirm-btn' onClick={handleConfirm}> {t("confirm")} </button> : <button className='confirm-btn-dis' disabled> {t("confirm")} </button>}

      </Modal.Footer>
    </Modal>
  )
}

export default ModalSkipAnswerTest
