import React, { useEffect } from 'react'
import "./AllPackages.css"
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiFillStar } from "react-icons/ai";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Loader from '../Loader/Loader';

function AllPackages({ data, submitting, loading, cartData, addToCart }) {

  const { t } = useTranslation();


  const cartIds = cartData.reduce((acc, item) => {
    const packageItem = item.package;
    if (Array.isArray(packageItem)) {
        packageItem.forEach(pkg => {
            acc.examIds.push(pkg.exam_id);
            acc.packageIds.push(pkg.id);
        });
    } else {
        const exams = packageItem.exams ? packageItem.exams.map(exam => exam.exam_id) : packageItem.exams_ids;
        acc.examIds.push(...exams);
        acc.packageIds.push(packageItem.id);
    }
    return acc;
}, { examIds: [], packageIds: [] });


  return (
    <Col xs={12} md={12}>
      <div className="all-packages">
        <h5> {t("combined-tests")} </h5>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              {data.length !== 0 ? (
                <>
                  {data.map((item) => (
                    <Col xs={12} md={6} key={item.id}>
                      <Card>

                        <div className="clickable-card">
                          <Link to={`/package-info/${item.id}`}>
                            <Card.Img variant="top" src={item.image} />
                          </Link>
                          <Card.Body>
                            <Link to={`/package-info/${item.id}`}>
                              <>
                                <div className="head-title">
                                  <p>{item.name}</p>
                                  {/* <p>
                                  <AiFillStar className="star-icon" /> 5 /
                                  4.5
                                </p> */}
                                </div>
                                <Card.Title>{item?.description?.length > 80 ? item.description.substring(0, 80) + " .... " : item.description}</Card.Title>
                              </>
                            </Link>
                            <div>
                              {item.user_package === true ? <div className="last-title"> <p>
                                {t("purchased")}
                              </p></div> : <div className="last-title">
                                {item.price === 0 || item?.promo_code[0]?.is_availability === true ? (

                                  <div className="d-flex align-items-center justify-content-between w-100">
                                    <p> {t("free")} <span></span> </p>
                                    {item.price > 0 && <p className="discount">{item.price}$</p>}
                                  </div>
                                ) : (
                                  <p>{item.price}$</p>
                                )}
                                {item.price === 0 || item?.promo_code[0]?.is_availability === true ? "" : <div className="add-to-bag" onClick={() => addToCart(item.id)}>
                                  <Button
                                    htmlType="submit"
                                    loading={submitting[item.id]}
                                    className="add-bag-btn"
                                  >
                                    {!submitting[item.id] && <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="21"
                                      viewBox="0 0 18 21"
                                      fill="none"
                                    >
                                      <path
                                        d="M16.96 7.96002C16.29 7.22002 15.28 6.79002 13.88 6.64002V5.88002C13.88 4.51002 13.3 3.19002 12.28 2.27002C11.25 1.33002 9.90999 0.890022 8.51999 1.02002C6.12999 1.25002 4.11999 3.56002 4.11999 6.06002V6.64002C2.71999 6.79002 1.70999 7.22002 1.03999 7.96002C0.0699899 9.04002 0.09999 10.48 0.20999 11.48L0.90999 17.05C1.11999 19 1.90999 21 6.20999 21H11.79C16.09 21 16.88 19 17.09 17.06L17.79 11.47C17.9 10.48 17.92 9.04002 16.96 7.96002ZM8.65999 2.41002C9.65999 2.32002 10.61 2.63002 11.35 3.30002C12.08 3.96002 12.49 4.90002 12.49 5.88002V6.58002H5.50999V6.06002C5.50999 4.28002 6.97999 2.57002 8.65999 2.41002ZM5.41999 12.15H5.40999C4.85999 12.15 4.40999 11.7 4.40999 11.15C4.40999 10.6 4.85999 10.15 5.40999 10.15C5.96999 10.15 6.41999 10.6 6.41999 11.15C6.41999 11.7 5.96999 12.15 5.41999 12.15ZM12.42 12.15H12.41C11.86 12.15 11.41 11.7 11.41 11.15C11.41 10.6 11.86 10.15 12.41 10.15C12.97 10.15 13.42 10.6 13.42 11.15C13.42 11.7 12.97 12.15 12.42 12.15Z"
                                        fill={cartIds?.packageIds?.includes(item.id) ? "black" : "#D9D9D9"}
                                      />
                                    </svg>}
                                  </Button>

                                </div>}
                              </div>}
                            </div>
                          </Card.Body>

                        </div>



                      </Card>
                    </Col>
                  ))}
                </>
              ) : (
                <p className="no-tests"> {t("no-exams")} </p>
              )}
            </Row>
          </>
        )}

      </div>

    </Col>
  )
}

export default AllPackages
