import React, { useState, useEffect } from "react";
import { Col, Container, Row } from 'react-bootstrap'
import "./MatchingQues.css"
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import Parse from "html-react-parser";
import { TbBulbFilled } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";

function MatchingQues({ data, index, setChangeBtn, checked, setChecked, showAnswer, selectedAnswer, setSelectedAnswer, setCorrectOptions ,pairs, setPairs ,type ,renderMathType ,setShowQuestionSection ,showQuestionSection}) {

  const { t } = useTranslation();

    const chooseArray = JSON.parse(data[index]?.QUS_OPTION)


    const str = data[index].QUS_CORRECT_RESPONSE
    const filterStr = str.replace(/{/g, '[').replace(/}/g, ']').replace(/"[0-9]":/g, '');
    const endARRoptions = JSON.parse(filterStr);

    const handleDragStart = (event, leftOption) => {
        event.dataTransfer.setData('text/plain', leftOption);
    };

    const handleDrop = (event, item) => {
        event.preventDefault();
        const leftOption = event.dataTransfer.getData('text/plain');
        const rightOption = item;
        const updatedPairs = [...pairs, { leftOption, rightOption }];
        const pairExists = pairs.map(p => p.leftOption);
        const pairExists2 = pairs.map(p => p.rightOption);
        if (pairExists.includes(leftOption) || pairExists2.includes(rightOption)) {
            // Push the pair into the pairs array only if it doesn't already exist
            return null
        } else {
            setPairs(updatedPairs);
            setSelectedAnswer(JSON.stringify(updatedPairs.map(item => [[item.leftOption], [item.rightOption]])));
            setChangeBtn(2);
            setCorrectOptions(!endARRoptions.map(element =>updatedPairs.map(item => [[item.leftOption], [item.rightOption]]).toString().includes(element.toString())).includes(false) ? "true" :"")
        }

    };



    const handleDragOver = event => {
        event.preventDefault();
    };

    const removeItem = (id) => {
        const filter = pairs.filter(item => item.leftOption !== id);

        setPairs(filter)
        setSelectedAnswer(JSON.stringify(filter));
    }
  

    const [reminderCard, setReminderCard] = useState([]);

    const [show, setShow] = useState(false);
  
    const [loading, setLoading] = useState(false);
  
    const handleClose = () =>  setShow(false) ;
    const handleShow = () => setShow(true);
  
    useEffect(() => {
      (async () => {
        try {
          setLoading(true);
          const result = await axios({
            method: "GET",
            url: `https://testcards.weghat.com/public/api/reminder-card/get/${data[index].CAT_ID}/${data[index].ST_LO_ID}`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          setReminderCard(result.data);
          setLoading(false);
        } catch (err) {
        }
      })();
    }, [index]);

    let str1 = data[index].QUESTION_TEXT
  let newStr = str1.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');




  let strAnswer = data[index].ANSWER_EXPLANATION
  let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

  useEffect(() => {

    setShowQuestionSection(true);

    const elements = document.getElementsByClassName("explain");

    const elements2 = document.getElementsByClassName("preview");

    const elements3 = document.getElementsByClassName("mathLabel");

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements2.length; i++) {
      const element = elements2[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements3.length; i++) {
      const element = elements3[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }

    }


  }, [index, renderMathType ,showQuestionSection]);


    return (
        <div className='choose-question d-flex  justify-content-center'>
            <Container >
            {showQuestionSection &&
                <div className='ques pt-5 pb-2' style={{ width: "100%" }}>
                <div className="d-flex align-items-start justify-content-between">
                <h5 className="preview">{data[index].QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${data[index].QUESTION_TEXT}`)}</h5>
          {reminderCard.message === "success" && type === "training" ?  <div className="bulb-icon"> <TbBulbFilled className="reminder-icon" onClick={handleShow} />  </div> : ""}
          </div>

                    {data[index].QUESTION_FILE_NAME && (
                        <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${data[index].QUESTION_FILE_NAME}`} controls />
                    )}



                    <div className="matching-question-container">
                        <Row style={{ width: "100%" }}>

                            <Col md={4}>
                                <div className="options-container">
                                    {chooseArray.listname && <h5 className='head-matching'>{chooseArray.listname[0]}</h5>}
                                    {Object.values(chooseArray.list.columnA).map((item, i) => (
                                        <div
                                            key={i}
                                            className={pairs.map(p => p.leftOption).includes(item) ? "select-option" : "option-drag"}
                                            draggable
                                            onDragStart={event => handleDragStart(event, item)}
                                        >
                                      {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g, ' ')} </p> }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={4}>
                                <div
                                    className="matching-container"
                                    onDragOver={event => handleDragOver(event)}
                                >
                                    {chooseArray.listname && <h5 className='head-matching'>{chooseArray.listname[1]}</h5>}
                                    {Object.values(chooseArray.list.columnB).map((item, i) => (
                                        <div key={i} className={pairs.map(p => p.rightOption).includes(item) ? "select-option" : "option"} onDrop={event => handleDrop(event, item)}>
                                            {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g,'')} </p> }
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={4}>
                                {pairs.length > 0 && (
                                    <div className="pairs-container">
                                        <h5 className='head-matching'>التوصيل</h5>
                                        {pairs.map((pair, i) => (
                                            <div key={i} className="pair match-option">
                                                <div> <span>{pair.leftOption.toString().includes("jpg") || pair.leftOption.toString().includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${pair.leftOption.toString().replace(/\#/g,'')}`} alt="" />  :  <p> {pair.leftOption.toString().replace(/\#/g,'')} </p> }  </span>
                                                    <span className="pair-arrow"> ⬅ </span>
                                                   <span>{pair.rightOption.toString().includes("jpg") || pair.rightOption.toString().includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${pair.rightOption.toString().replace(/\#/g,'')}`} alt="" />  :  <p> {pair.rightOption.toString().replace(/\#/g,'')} </p> }  </span> </div>
                                                {showAnswer === false && <IoIosCloseCircle onClick={() => removeItem(pair.leftOption)} className='cancel-match-icon' />}

                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>

                    {showAnswer === true && (
                        <div className='mb-5'>
                            {!endARRoptions.map(element =>selectedAnswer.includes(JSON.stringify(element))).includes(false) ? (
                                <div className="true-answer">
                                    <h5>
                                        <IoIosCheckmarkCircle className="true-icon" /> {t("correct-answer")}
                                    </h5>
                                    <div className="explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                                </div>
                            ) : (
                                <div className="false-answer">
                                    <h5>

                                        <IoIosCloseCircle className="false-icon" />
                                        {t("incorrect-answer")}
                                    </h5>
                                    <div className="explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>}
            </Container>
            <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: "9999999999999" ,  fontFamily: 'Noto Naskh Arabic'}}>
        <Modal.Header closeButton>
        <Modal.Title style={{color:"#005C9A"}}> {t("reminder-card")} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {loading ? <Loader/> :
            <div>
              {reminderCard.message === "success" && Parse(`${reminderCard?.data[0]?.content}`)}
            </div>
          }
        </Modal.Body>
      </Modal>
        </div>
    )
}

export default MatchingQues