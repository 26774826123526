import React, { useState } from 'react'
import Collapse from "react-bootstrap/Collapse";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import "./FAQs.css"
import { Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function FAQs() {
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);

  const [icon1, setIcon1] = useState(false);
  const [icon2, setIcon2] = useState(false);
  const [icon3, setIcon3] = useState(false);
  const [icon4, setIcon4] = useState(false);
  const [icon5, setIcon5] = useState(false);

  const { t } = useTranslation();

  const questions = [
    {
      id: 0,
      question: t("What-platform"),
      answer:
        <ul>
          <li> {t("faqs-text1")} </li>
          <li> {t("faqs-text2")} </li>
        </ul>,
      open: collapse1,
      setOpen: setCollapse1,
      icon: icon1,
      setIcon: setIcon1,
    },
    {
      id: 1,
      question: t("yaqees-beneift"),
      answer:
        <ul>
          <li> {t("faqs-text3")} </li>
          <li> {t("faqs-text4")} </li>
          <li> {t("faqs-text5")} </li>
          <li> {t("faqs-text6")} </li>
        </ul>,
      open: collapse2,
      setOpen: setCollapse2,
      icon: icon2,
      setIcon: setIcon2,
    },
    {
      id: 2,
      question: t("free-subscribe"),
      answer:
        <ul>
          <li> {t("faqs-text7")} </li>
        </ul>,
      open: collapse3,
      setOpen: setCollapse3,
      icon: icon3,
      setIcon: setIcon3,
    },
    {
      id: 3,
      question: t("yaqees-source"),
      answer:
        <ul>
          <li> {t("faqs-text8")} </li>
      </ul>,
      open: collapse4,
      setOpen: setCollapse4,
      icon: icon4,
      setIcon: setIcon4,
    },
    {
      id: 4,
      question: t("level-analysis"),
      answer:
        <ul>
          <li> {t("faqs-text9")} </li>
         </ul>,
      open: collapse5,
      setOpen: setCollapse5,
      icon: icon5,
      setIcon: setIcon5,
    },
  ];

  return (
    <div className="parent-container">
      <Container className="my-3">
        <h5> {t("FAQs")} </h5>
        {questions.map((item) => (
          <div key={item.id}>
            <div
              onClick={() => {
                item.setOpen(!item.open);
                item.setIcon(!item.icon);
              }}
              className="parent-collapse "

            >
              <div
                aria-controls="example-collapse-"
                aria-expanded={item.open}
                className="collapse-head d-flex justify-content-between align-items-center"
              >
                {item.question}
                <span>{item.icon ? < AiFillCaretUp /> : <AiFillCaretDown />}</span>
              </div>
              <Collapse in={item.open}>
                <div className="collapse-text" id="example-collapse-text">
                  {item.answer}
                </div>
              </Collapse>
            </div>
            <hr />
          </div>
        ))}
      </Container>
    </div>
  );
}

export default FAQs

