import React, { useState, useEffect } from 'react'
import ProfileHead from '../Components/ProfileHead/ProfileHead'
import { Container } from 'react-bootstrap'
import ProfileTests from '../Components/ProfileTests/ProfileTests'
import ProfileAnalysis from '../Components/ProfileAnalysis/ProfileAnalysis'
import axios from 'axios'
import { useTranslation } from "react-i18next";
import Loader from '../Components/Loader/Loader'
import Pagination from '../Components/Pagination/Pagination'


function Profile({ refresh }) {

  const [active, setActive] = useState(1);

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState("");

  const [perPage, setPerPage] = useState("");

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/get-user-exams`,
          headers: {
            Accept: "application/json",
            Username: "administrator",
            Password: "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        setData(result.data.data);
        setTotalCount(result.data.meta.total);
        setPerPage(result.data.meta.per_page);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [refresh]);

 

  const getPage = async (page) => {
    setLoading(true);
    const result = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/get-user-exams?page=${page}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Username: "administrator",
        Password: "yaqess123456",
        Authorization: "Bearer " + token,
        token: token,
      },
    });
    setData(result.data.data);
    setTotalCount(result.data.meta.total);
    setPerPage(result.data.meta.per_page);
    setLoading(false);
  };

  return (
    <div className='profile-parent'>
      <Container>
        <ProfileHead />
        {loading ?  <>
          <Loader />
          <div className='profile-route-btns'>
            <button className={active === 1 ? 'active-btn' : ""} onClick={() => setActive(1)}>{t("my-exams")}</button>
            {/* <button className={active === 2 ? 'active-btn' : ""} onClick={() => setActive(2)}>{t("performance-analysis")}</button> */}
          </div>
          {active === 2 && <ProfileAnalysis />}
          {active === 1 && <ProfileTests data={data} />}
        </>: <>
          <div className='profile-route-btns'>
            <button className={active === 1 ? 'active-btn' : ""} onClick={() => setActive(1)}>{t("my-exams")}</button>
            {/* <button className={active === 2 ? 'active-btn' : ""} onClick={() => setActive(2)}>{t("performance-analysis")}</button> */}
          </div>
          {active === 2 && <ProfileAnalysis />}
          {active === 1 && <ProfileTests data={data} />}
        </>}
    
      </Container>
      
      {totalCount > perPage && (
        <Pagination
          getPage={getPage}
          totalCount={totalCount}
          perPage={perPage}
        />
      )}
    </div>
  )
}

export default Profile
