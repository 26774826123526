import React, { useState } from 'react'
import "./ReviewAnswersTest.css"
import { Container, Row, Col } from 'react-bootstrap'
import { BsSkipEndFill } from 'react-icons/bs';
import { useSelector } from "react-redux";
import { Select } from 'antd';
import ModalSkipAnswerTest from '../ModalSkipAnswerTest/ModalSkipAnswerTest';
import Parse from "html-react-parser";
import { TbChecks } from 'react-icons/tb';
import { useTranslation } from "react-i18next";


function ReviewAnswersTest() {

    const reviewData = useSelector((state) => state.review.testData);

    const [filter, setFilter] = useState("")

    const [showModal, setShowModal] = useState(false);
    const [showQuestion, setShowQuestion] = useState([]);
    const [questionNumber, setQuestionNumber] = useState("");

    const { t } = useTranslation();

    const handleFilterChange = (value) => {
        setFilter(value)
    }

    const handleQuestionAnswer = (item) => {
        setShowModal(true);
        setShowQuestion(item)
        setQuestionNumber(reviewData.indexOf(item) + 1)
    }

    const Option = Select.Option;

    const disabledBtn = localStorage.getItem("yaqees-disabled");

    return (
        <div className='review-answers'>
            <Container>
                <div className='head-title d-flex align-items-center justify-content-between'>
                    <h5> {t("review-answers")} </h5>
                    <Select style={{ width: "12%" }} className='select-type-answer' value={filter} onChange={handleFilterChange}>
                        <Option value=""> {t("all-questions")} </Option>
                        <Option value="done"> {t("questions-solved")} </Option>
                        <Option value="skip"> {t("questions-skipped")} </Option>
                    </Select>
                </div>

                <Row>
                    <Col md={12}>
                        {reviewData.filter(answer => {
                            if (answer.status.includes(filter)) {
                                return answer
                            } else {
                                return null
                            }
                        }).map(item => (
                            <div className='question-review-test d-flex align-items-end justify-content-between' key={item.QUS_ID}>

                                {item.status === "done" && <div className='review-done'>
                                    <div>
                                        <div className='d-flex align-items-start' ><h6 className='ques-num'>{reviewData.indexOf(item) + 1}</h6> <h6>{item.QUESTION_TEXT.includes('src="/public') ? Parse(`${item.QUESTION_TEXT.replace(/src\s*=\s*"/, 'src="https://test4you.me/')}`) : Parse(`${item.QUESTION_TEXT}`)}</h6>  </div>

                                    </div>
                                    <p className='true-answer mt-3'><TbChecks className='true-icon' /> {t("question-solved")} </p>
                                </div>}

                                {item.status === "skip" && <div className='review-skip'>
                                    <div>
                                        <div className='d-flex align-items-start' ><h6 className='ques-num'>{reviewData.indexOf(item) + 1}</h6> <h6>{item.QUESTION_TEXT.includes('src="/public') ? Parse(`${item.QUESTION_TEXT.replace(/src\s*=\s*"/, 'src="https://test4you.me/')}`) : Parse(`${item.QUESTION_TEXT}`)}</h6>  </div>

                                    </div>
                                    <div className={disabledBtn ? 'd-flex align-items-center justify-content-end mt-3' : 'd-flex align-items-center justify-content-between mt-3'}>
                                     {!disabledBtn && <button className='question-answer' onClick={() => handleQuestionAnswer(item)}> {t("answer-question")} </button>}   
                                        <p className='skip-answer'><BsSkipEndFill className='skip-icon' /> {t("question-skipped")} </p>
                                    </div>

                                </div>}

                            </div>
                        ))}


                    </Col>
                </Row>
            </Container>
            <ModalSkipAnswerTest showModal={showModal} setShowModal={setShowModal} showQuestion={showQuestion} questionNumber={questionNumber} />
        </div>
    )
}

export default ReviewAnswersTest
