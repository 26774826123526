import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./ProfileTests.css"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProfileTests({ data }) {

  const { t } = useTranslation();

  return (
    <div className="profile-test">
      {data?.length > 0 ?
        <Row>
          {data?.map(item => (
            <Col xs={12} md={3} key={item.id} className="mb-4">
              <Link to={`/exam-info/${item.id}/${item.exam_type}`}>
                <Card>
                  <span className="title-info"> {item.category} </span>
                  <Card.Img variant="top" src={item.image} />
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="w-100">
                        <div className="head-title">
                          <h6> {item.Main_title} </h6>
                        </div>
                        {item.ExamAttempt ? <div className="last-title d-flex align-items-center justify-content-between">
                          <p> {t("time-taken")} <span>{item?.ExamAttempt?.time?.split(":")[1]} {t("minute")}  {item?.ExamAttempt?.time?.split(":")[2]} {t("second")} </span></p>
                          <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar minValue value={item.ExamAttempt?.degree} text={`${item.ExamAttempt?.degree}%`} strokeWidth={8} styles={buildStyles({ pathColor: `#005C9A`, textColor: '#1B1A1D', trailColor: '#d6d6d67d' })} />
                          </div>
                        </div> : <div className="last-title d-flex align-items-center justify-content-between mt-4"> <p> {t("no-attempt")} </p> </div>}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row> : <p className="no-tests"> {t("no-exams")} </p>}
   
    </div>
  );
}

export default ProfileTests;
