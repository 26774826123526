import React from 'react'

function Privacy() {
  return (
    <div className='privacy container'>
        <div className='privacy-en'>
        <h5> Privacy Policy </h5>
      <h6> Clause 1: </h6>
      <p> Yaqees maintains the confidentiality and security of the personal information its users provide to us, such as information they provide when registering for a user account, or when entering into transactions via the Site, which may include, for example, your name and other contact information; Any reference to "we" or "us" is a reference to Yaqees, and we will use commercial efforts to keep your personal information secure in accordance with this Privacy Policy.
Information collected by Yaqees through the Application is governed by this Privacy Policy, and does not apply to information we may obtain from you in other ways. </p>
    
    <h6> Clause 2: Information Collection and Use: </h6>
    <p> We collect a number of information in order to provide and improve the services available on the Site to you, including but not limited to: </p>
    <p> - Email. <br/>
- First and last name. <br/>
- Phone number </p>
<p> The grades you get in exams and exercises
Data from social networks If you use your social network accounts to register and create an account on the Site, information used from social networking sites such as Facebook includes: </p>

<p> - Your name.<br/>
- Username of the social network. <br/>
- The site. <br/>
- Sex. <br/>
- Date of birth. <br/>
- Email address. <br/>
- Profile picture. </p>
<h6> Clause 3: Usage data:  </h6>
<p> - We may collect information that your phone sends when you visit the Application.
This usage data may include information such as: the type of mobile device you use, your mobile phone's unique mobile identifier, the Internet address and protocol of your mobile phone, the type of mobile internet browser used by you, rare device identifiers and other diagnostic data </p>
<h6> Data usage: </h6>
<p> We use the collected data for a variety of purposes, including but not limited to: </p>
<p> - For the purposes of providing our services. <br/>
- For the purposes of notifying you of any changes to our services.<br/>
- To provide customer support service.<br/>
- To collect analytics or valuable information that enables us to develop our services and/or the website.<br/>
- To detect, address and reduce technical problems. </p>
<h6> Clause 4: Use, exchange and disclosure to third parties: </h6>
<p> We and our education partners who provide content through the Destinations App may, among other things, use your information collected from the App (including your personal information). In the event that we share or disclose your personal information to others, we require them to treat personal information confidentially, and to maintain adequate security to protect the information from loss, misuse, unauthorized use, disclosure, modification or destruction. </p>
<h6> Clause 5: Use, Exchange and Disclosure to Third Parties: </h6> 
<p> - We collect information, including personal information, when you register for a user account, or send an email to us. In addition to the above, we keep track of the information shown in the records of the pages you visit in the Application, the times they are visited, the order in which they are visited and any hyperlinks, and other user-competed tools that I've used.
<br/> - The Yaqees application records the Internet Protocol address and operating system used by each user, and we may be able - based on the Internet Protocol address - to determine the user's Internet service provider and the geographical location of his connection point.
<br/>- We track attendance, learning focus, content completion level, analyze performance statistics, and the way they learn.
To improve the curricula offered by Yaqees, accept the help of educational institutions, whether at the individual or collective level, as well as in evaluating the entry and use of the Yaqees application, and the impact of Yaqees on the education community on a global scale.
<br/>- Dissemination of non-personal information when accessing the Yaqees application, its use and its impact on student performance evaluation.
<br/>- Sending you updates about content and requested tasks provided by Nafes, or other tasks, sending you notifications about Yaqees products or services, Yaqees affiliates or selected business partners that may be of interest to you, or sending you emails about any matters relating to site maintenance or updates.
<br/>- Archiving or using this information in order to communicate with you in the future, or both.
<br/>- Maintain and improve the functionality and security of the Application, our software, systems, and network. </p>   
<h6> Clause 6: Sharing information with others in the following manner: </h6>
<p> - Service providers that perform certain services on our behalf or on behalf of educational institutions, including processing information you provide to us on the Application, operating the Application or portions of it, or in connection with other aspects of the Yaqees Services
<br/>- For the purposes of scientific research (as mentioned above), however, we will share your personal information only to the extent necessary to fulfill the purpose stated at the time such information was collected.
<br/>- To provide you with opportunities to connect with other users who may have similar interests or educational goals, for example, we may recommend specific study partners to you or introduce you to potential prospective students or mentors. In this case, we may use the information we collect about you to determine who may be interested in contacting you, but we will only provide other users with your username, nor will we disclose your real name or email address to them.
In addition to the above, we may share information that we have collected that does not constitute the possibility of personally identifying you with third parties, including - but not limited to - researchers and other partners. </p>
    <h6> Clause 7: Links to other websites: </h6>
    <p> Al- Yaqees may contain links to third party websites such as other content providers and some service providers, but these other websites are not under our control, so you understand and agree that we are not responsible for these sites' collection and use of your information, unless otherwise indicated in the Privacy Policy This is amazing. We recommend that you make sure that when you are directed to a third party website, you review the privacy policies of each website you visit and use. </p>
    <h6> Clause 8: Privacy Policy Changes </h6>
    <p> Please note that we review this Privacy Policy periodically and may make changes to it, and when we make any changes; The Privacy Policy link will contain a “Recent revision (with date)” sign, indicating that you have reviewed the new provisions which are effective immediately upon posting the new policy on this page with a new effective date. This mention will remain posted next to the Privacy Policy link for at least ten days after updates have been made. Your access to the application after making any changes is considered your prior acceptance of the amended privacy policy and any changes therein, and in the event that you receive a notice of the modifications mentioned above, be sure to visit this page periodically; To make sure you know the latest version of the Privacy Policy. </p>
    </div>
    <div className='privacy-ar'>
    <h5> سياسية الخصوصية </h5>

    <h6> بند 1: </h6>
    <p> تحتفظ  يقيس  بسرية وأمان معلومات مستخدميها الشخصية، التي يزودوننا بها، مثل المعلومات التي يقدمونها عند التسجيل للحصول على حساب مستخدم، أو عند الدخول في تعاملات عبر الموقع، والتي قد تتضمن -على سبيل المثال- الاسم ومعلومات الاتصال الأخرى؛ كما أن أي إشارة لـ «نحن» و«لنا» تُعتبَر إشارة إلى نافس، وسوف نبذل الجهود تجاريًّا للحفاظ على أمان معلوماتك الشخصية عملًا بسياسة الخصوصية هذه.
المعلومات التي تجمعها  يقيس  من خلال التطبيق تسري عليها سياسة الخصوصية هذه، ولا تنطبق على المعلومات التي قد نحصل عليها منك بطرق أخرى </p>
    <h6> بند 2: تجميع المعلومات واستعمالها </h6>
    <p> نحن نقوم بتجميع عدد من المعلومات وذلك لتقديم الخدمات المتاحة على الموقع لك وتحسينها وتشمل على سبيل المثال لا الحصر: </p>
   <p>     • البريد الإلكتروني.
    <br/>• الاسم الأول والاسم الأخير.
    <br/> • رقم الهاتف
    <br/>• الدرجات التي تحصل عليها في الاختبارات والتدريبات </p>
    <p> البيانات من الشبكات الاجتماعية إذا كنت تستخدم حسابات الشبكة الاجتماعية الخاصة بك للتسجيل وإنشاء حساب في الموقع، ومن المعلومات المستخدمة من مواقع الشبكات الاجتماعية مثل Facebook ما يلي: </p>
   <p>     • اسمك.
    <br/>• اسم المستخدم للشبكة الاجتماعية.
    <br/> • الموقع.
    <br/> • الجنس.
    <br/> • تاريخ الميلاد.
    <br/>• عنوان البريد الإلكتروني.
    <br/>• صورة الملف الشخصي </p>
    <h6> بند 3: بيانات الاستعمال </h6>
    <p>     • قد نعمل على تجميع المعلومات التي يقوم الهاتف الخاص بك بإرسالها عندما تقوم بزيارة التطبيق.
    <br/>• بيانات الاستعمال هذه قد تتضمن معلومات مثل: نوع جهاز الهاتف المحمول الذي تستعمله، ومعرف الهاتف الفريد الخاص بهاتفك المحمول، وعنوان الإنترنت والبروتوكول الخاص بهاتفك المحمول، ونوع متصفح الإنترنت عبر الهاتف المحمول المستعمل من قبلك ومعرفات الجهاز النادرة وغيرها من البيانات التشخيصية </p>
    <h6> استعمال البيانات: </h6>
    <p> نحن نقوم باستعمال البيانات المجمعة لغايات متنوعة، تشمل على سبيل المثال وليس الحصر:
    <br/>• لغايات تقديم خدماتنا.
    <br/>• لغايات إشعارك عن أي تغييرات بخدماتنا.
    <br/> • لتقديم خدمة دعم العملاء.
    <br/>• لتجميع تحليلات أو معلومات قيمة تمكننا من تطوير خدماتنا و/أو الموقع.
    <br/>• لكشف ومعالجة المشاكل التقنية والحد منها. </p>
    
    <h6> طلب حذف بياناتك كمستخدم: </h6>
    <p> في حالة الرغبة في حذف بياناتك المسجلة لدينا، يمكن للأشخاص المسجلين باستخدام الايميل الشخصي، أو التسجيل عبر ربط حسابهم (فيسبوك Facebook) بالتطبيق ارسال ايميل الى info@yaqees.app مرفقاً بالبريد الالكتروني المسجل من خلاله الحساب الخاص به.
أو في حالة كان المشترك مسجلا بواسطة حسابهم على فيسبوك (Facebook) يمكن من خلال إزالة إمكانية الوصول للتطبيق من إعدادات الفيسبوك.
 </p>
    
    <h6> بند 4: الاستخدام والتبادل والإفصاح للغير: </h6>
    <p> يمكن أن نقوم نحن وشركاؤنا في التعليم ممَّن يقدِّمون المحتوى عبر تطبيق وجهات باستخدام معلوماتك التي تُجمَع من التطبيق من بين أمور أخرى (بما فيها معلوماتك الشخصية). في حال مشاركتنا أو إفصاحنا عن معلوماتك الشخصية للغير، فإننا نُطالبه بالتعامل مع المعلومات الشخصية بسريِّة، وأن يحافظ على الأمان الكافي لحماية المعلومات من الضياع أو سوء الاستخدام أو الاستخدام غير المسموح أو الإفصاح أو التعديل أو الإتلاف.
 </p>
        
    <h6> بند 5: الاستخدام والتبادل والإفصاح للغير: </h6>
    <p>     • نحن نقوم بجمع المعلومات بما فيها المعلومات الشخصية عندما تقوم بالتسجيل للحصول على حساب مستخدم، أو ترسل لنا بريدًا إلكترونيًّا، بالإضافة إلى ما سبق فنحن نتتبَّع المعلومات الموضحة بسجلات الصفحات التي تزورها في التطبيق، ومواعيد زيارتها، والترتيب الذي تمت زيارتها فيه وأي روابط تشعبية، وغيرها من أدوات  يقيس  المستخدم التي قد استعملتها.
    <br/>• يقوم تطبيق  يقيس  بتسجيل عنوان بروتوكول الإنترنت ونظام التشغيل الذي يستخدمه كل مستخدم، وقد نتمكن -بناءً على عنوان بروتوكول الإنترنت- من تحديد مزود خدمة الإنترنت الخاص بالمستخدم والموقع الجغرافي لنقطة اتصاله.
    <br/>• نتتبع الحضور والتركيز في التعلم ومستوى الإكمال للمحتوى وتحليل إحصائيات الأداء والطريقة المستخدمة من قِبله للتعلُّم.
    <br/>• لتحسين المناهج التي تقدمها  يقيس  تقبل مساعدة المؤسسات التعليمية سواء على الصعيد الفردي أو الجماعي، وكذلك في تقييم دخول واستخدام تطبيق نافس، وتأثير  يقيس  على مجتمع التعليم على النطاق العالمي.
    <br/>• نشر المعلومات غير الشخصية، عند الدخول إلى تطبيق وجهات، واستخدامها وتأثيرها على تقييم أداء الطلاب.
    <br/>• إرسال تحديثات حول المحتوى والمهام المطلوبة التي يقدِّمها نافس، أو غيرها من المهام، ومراسلتكم حول إخطارات عن منتجات أو خدمات  يقيس  أو الشركات التابعة  يقيس  أو شركاء أعمال مختارين قد يهمك أمرهم، أو إرسال رسائل إلكترونية لك حول أي شئون تتعلق بصيانة الموقع أو تحديثاته.
    <br/>• أرشفة هذه المعلومات أو استخدامها من أجل التواصل معكم في المستقبل، أو هما معًا.
    <br/>• الحفاظ على وظائف وأمان التطبيق وبرنامجنا وأنظمتنا وشبكتنا وتحسينها.
 </p>
        
    <h6> بند 6: مشاركة المعلومات مع الغير على الشكل التالي: </h6>
    <p>     • مزودي خدمات يؤدون خدمات معينة نيابةً عنَّا أو عن المؤسسات التعليمية، بما في ذلك معالجة المعلومات التي تزودنا بها على التطبيق، أو تشغيل التطبيق أو أقسام منه، أو فيما يتعلق بجوانب أخرى من خدمات نافس
    <br/>• لأغراض الأبحاث العلمية (كما ذكرنا سابقًا)، غير أننا سنشارك المعلومات الشخصية الخاصة بك فقط بالمقدار الضروري لتلبية الغرض المذكور في وقت جمع هذه المعلومات.
    <br/>• لتزويدكم بفرص التواصل مع مستخدمين آخرين ممَّن قد يكون لهم نفس اهتماماتكم أو أهدافكم التعليمية، مثلًا، قد نوصيكم بشركاء دراسة محددين أو نعرفكم بطلاب متلقين أو مرشدين محتملين. وفي هذه الحالة قد نستخدم المعلومات التي نجمعها حولكم لتحديد مَن قد يكون مهتمًّا بالتواصل معكم، لكننا لن نزود المستخدمين الآخرين سوى باسم المستخدم الخاص بكم، ولن نفصح لهم عن اسمكم الحقيقي أو عنوان بريدكم الإلكتروني.
    <br/> • إضافة إلى ما سبق، فقد نشارك معلومات جمعناها ولا تشكل إمكانية تعرُّف شخصي عليكم مع الغير، بما في ذلك -على سبيل المثال لا الحصر- الباحثون وشركاء اخرين.
  </p>


<h6> بند 7: روابط إلى مواقع إلكترونية أخرى </h6>

<p> قد يحتوي ال يقيس  على روابط مواقع إلكترونية للغير كمزودي محتوى آخرين وبعض مزودي الخدمات، غير أن هذه المواقع الأخرى ليست تحت إدارتنا، وبذلك عليك أن تتفهم وتوافق على أننا لا نتحمل مسئولية جمع هذه المواقع واستخدامها لمعلوماتك، ما لم تتم الإشارة إليه بغير ذلك في سياسة الخصوصية هذه. نوصيكم بالحرص -عندما يتم توجيهكم إلى موقع الغير- بأن تقوموا بمراجعة سياسات الخصوصية لكل موقع تزورونه وتستخدمونه </p>

<h6> بند 8: تغييرات سياسة الخصوصية </h6>

<p> يرجى الأخذ بعين الاعتبار أننا نقوم بمراجعة سياسة الخصوصية هذه دوريًّا، وقد نُجري عليها بعض التغييرات، وعندما نُجري أي تغييرات؛ فإن رابط سياسة الخصوصية ستحتوي إشارة «مراجعة حديثة (مع التاريخ)»، مما يشير إلى مراجعتك للأحكام الجديدة التي تعتبر سارية فور نشر السياسة الجديدة على هذه الصفحة مع تاريخ نفاذ جديد. ستبقى هذه الإشارة موضوعة إلى جانب رابط سياسة الخصوصية لمدة عشرة أيام على الأقل بعد إجراء التحديثات. ويُعَدُّ دخولك الى التطبيق بعد إجراء أي تغييرات موافقةً مسبقة منك على سياسة الخصوصية المعدَّلة وأي تغييرات فيها، وفي حال استلامك إشعارًا بالتعديلات والمذكور أعلاه، احرص على زيارة هذه الصفحة بشكل دوري؛ للتأكد من معرفتك بأحدث نسخة من سياسة الخصوصية </p>
</div>
    </div>
  )
}

export default Privacy
