import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./VerificationCodeForgetPw.css";
import img1 from "../../Assets/images/login1.png";
import img2 from "../../Assets/images/login2.png";
import img3 from "../../Assets/images/login3.png";
import img4 from "../../Assets/images/login4.png";
import img5 from "../../Assets/images/login5.png";
import img6 from "../../Assets/images/login6.png";
import { useNavigate } from "react-router-dom";
import { RiLockUnlockFill } from "react-icons/ri";
import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";

function VerificationCodeForgetPw({ userForgetMail }) {

  const [user, setUser] = useState({ email: userForgetMail, forget_code: "", _method: "PUT" });

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const headers = {
    Accept: "application/json",
    Username: "administrator",
    Password: "yaqess123456",
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/forget-password/code`, user, { headers })
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setUser({ email: "", forget_code: "", _method: "PUT" });
        navigate("/new-passowrd")
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });

  };



  return (
    <div className="login">
      <Row>
        <Col
          md={3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img src={img1} alt="" />
          <img src={img2} alt="" />
          <img src={img3} alt="" />
        </Col>

        <Col md={6}>
          <Container className="login-sec">
            <h5>رمز التحقق</h5>
            <p>ادخل رمز التحقق المرسل علي بريدك الالكتروني</p>
            <form
              className="d-flex flex-column align-items-center"
              onSubmit={handleSubmit}
            >
              <div className="form-input">
                <span>
                  <RiLockUnlockFill />
                </span>

                <input
                  type="text"
                  placeholder="رمز التحقق"
                  value={user.forget_code}
                  name="forget_code"
                  onChange={handleChange}
                />
              </div>
              <Button
                htmlType="submit"
                loading={submitting}
                className="btn-login mx-auto mt-4"
              >
                {!submitting && "إعادة تعيين كلمة السر"}
              </Button>
            </form>
          </Container>
        </Col>

        <Col
          md={3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </Col>
      </Row>
    </div>
  );
}

export default VerificationCodeForgetPw
