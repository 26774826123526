import React from 'react'
import "./NotificationSetting.css"
import { useTranslation } from "react-i18next";


function NotificationSetting({ handleConfirm }) {

  const { t } = useTranslation();

  return (
    <div className='notification-setting py-4 '>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
        <label className="form-check-label" html="flexCheckChecked">
          {t("notify-1")}
        </label>
      </div>

      <div className="info-btn d-flex justify-content-end mt-5">
        <button onClick={handleConfirm}> {t("confirm")} </button>
      </div>

    </div>



  )
}

export default NotificationSetting
