import React, { useEffect, useState } from "react";
import AllTests from "../Components/AllTests/AllTests";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';

function Tests({ setRefresh, refresh, cartData }) {

  const location = useLocation();

  const [data, setData] = useState([]);

  const [filterData, setFilterData] = useState([]);

  const [filterDataLanguage, setFilterDataLanguage] = useState([]);

  const [filterDataTags, setFilterDataTags] = useState([]);

  const [loading, setLoading] = useState(false);

  const [loadingAll, setLoadingAll] = useState(false);

  const [totalCount, setTotalCount] = useState("");

  const [perPage, setPerPage] = useState("");

  const [categories, setCategories] = useState(location?.state?.cat ? location?.state?.cat : []);

  const [countries, setCountries] = useState([]);

  const [price, setPrice] = useState([0, 1000]);

  const [selectedTypeValue, setSelectedTypeValue] = useState(null);

  const [selectedTagValue, setSelectedTagValue] = useState(null);

  const [selectedLanguageValue, setSelectedLanguageValue] = useState(null);

  const [selectedCountryValue, setSelectedCountryValue] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const [searchText, setSearchText] = useState("")

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const userId = JSON.parse(localStorage.getItem("yaqees-user"))?.id;

  useEffect(() => {
    (async () => {
      try {
        setLoadingAll(true);
        const result = await axios({
          method: "GET",
          url: location?.state?.highSchool === "true" ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}&category_ids=${location?.state?.cat.toString()}` : `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username: "administrator",
            Password: "yaqess123456",
          },
        });
        const result2 = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/getAllCategories`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username: "administrator",
            Password: "yaqess123456",
          },
        });
        const result3 = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/getAllLanguages`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username: "administrator",
            Password: "yaqess123456",
          },
        });
        const result5 = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/getAllCountries`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username: "administrator",
            Password: "yaqess123456",
          },
        });
        // const result4 = await axios({
        //   method: "GET",
        //   url: `${process.env.REACT_APP_BASE_URL}/getAllTags`,
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     Username: "administrator",
        //     Password: "yaqess123456",
        //   },
        // });
        // setFilterDataTags(result4.data.data);
        setFilterDataLanguage(result3.data.data);
        setFilterData(result2.data.data);
        setCountries(result5.data.data)
        setData(result.data.data);
        setTotalCount(result.data.meta.total);
        setPerPage(result.data.meta.per_page);
        setLoadingAll(false);
      } catch (err) {
      }
    })();
  }, []);


  const getPage = async (page) => {
    setLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const result = await axios({
      method: "GET",
      url: categories.length > 0 ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}&category_ids=${categories.toString()}` : selectedLanguageValue ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}&language_id=${selectedLanguageValue}` : selectedTagValue ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}&tag_id=${selectedTagValue}` : selectedTypeValue ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}&type=${selectedTypeValue}` : searchText ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}&search_box=${searchText}` : price[0] != 0 || price[1] != 1000 ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}&price_min=${price[0]}&price_max=${price[1]}` : `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&page=${page}&user_id=${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Username: "administrator",
        Password: "yaqess123456",
      },
    });
    setData(result.data.data);
    setTotalCount(result.data.meta.total);
    setPerPage(result.data.meta.per_page);
    setLoading(false);
  };

  const handleFilterChange = async (e) => {
    setSelectedLanguageValue(null);
    setSelectedTagValue(null);
    setSelectedTypeValue(null);
    setSelectedCountryValue(null);
    setPrice([0, 1000]);
    if (e.target.checked === true) {
      setLoading(true);
      setCategories([...categories, e.target.value]);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}&category_ids=${[...categories, e.target.value,].toString()}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    } else {
      setLoading(true);
      const uncheckFilter = categories.filter((item) => item !== e.target.value);
      setCategories(uncheckFilter);
      const result = await axios({
        method: "GET",
        url: uncheckFilter.length > 0 ? `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}&category_ids=${[uncheckFilter].toString()}` : `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    }
  };

  const handleFilterLanguageChange = async (id) => {
    setSelectedTypeValue(null);
    setSelectedTagValue(null);
    setSelectedCountryValue(null);
    setCategories([]);
    setPrice([0, 1000]);
    if (selectedLanguageValue === id) {
      setLoading(true);
      setSelectedLanguageValue(null);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    } else {
      setLoading(true);
      setSelectedLanguageValue(id);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&language_id=${id}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    }
  };


  const handleFilterCountryChange = async (id) => {
    setSelectedTypeValue(null);
    setSelectedTagValue(null);
    setSelectedLanguageValue(null);
    setCategories([]);
    setPrice([0, 1000]);
    if (selectedCountryValue === id) {
      setLoading(true);
      setSelectedCountryValue(null);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    } else {
      setLoading(true);
      setSelectedCountryValue(id);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&country_id=${id}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    }
  };

  const handleFilterTagsChange = async (id) => {
    setSelectedTypeValue(null);
    setSelectedLanguageValue(null);
    setSelectedCountryValue(null);
    setCategories([]);
    setPrice([0, 1000]);
    if (selectedTagValue === id) {
      setLoading(true);
      setSelectedTagValue(null);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    } else {
      setLoading(true);
      setSelectedTagValue(id);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&tag_id=${id}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    }
  };


  // const handleFilterTypesChange = async (id) => {
  //   setSelectedLanguageValue(null);
  //   setSelectedTagValue(null);
  //   setCategories([]);
  //   setPrice([0, 1000]);
  //   if (selectedTypeValue === id) {
  //     setLoading(true);
  //     setSelectedTypeValue(null);
  //     const result = await axios({
  //       method: "GET",
  //       url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Username: "administrator",
  //         Password: "yaqess123456",
  //       },
  //     });
  //     setData(result.data.data);
  //     setTotalCount(result.data.meta.total);
  //     setPerPage(result.data.meta.per_page);
  //     setLoading(false);
  //   } else {
  //     setLoading(true);
  //     setSelectedTypeValue(id);
  //     const result = await axios({
  //       method: "GET",
  //       url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&type=${id}&user_id=${userId}`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Username: "administrator",
  //         Password: "yaqess123456",
  //       },
  //     });
  //     setData(result.data.data);
  //     setTotalCount(result.data.meta.total);
  //     setPerPage(result.data.meta.per_page);
  //     setLoading(false);
  //   }
  // };

  const handleFilterPrice = async () => {
    setLoading(true);
    setSelectedLanguageValue(null);
    setSelectedTagValue(null);
    setSelectedTypeValue(null);
    setSelectedCountryValue(null);
    setCategories([]);
    try {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&price_min=${price[0]}&price_max=${price[1]}&user_id=${userId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Username: "administrator",
          Password: "yaqess123456",
        },
      });
      setData(result.data.data);
      setTotalCount(result.data.meta.total);
      setPerPage(result.data.meta.per_page);
      setLoading(false);
    } catch (err) {
    }
  };


  const handleSearch = async (word) => {
    setSelectedLanguageValue(null);
    setSelectedTagValue(null);
    setSelectedTypeValue(null);
    setSelectedCountryValue(null);
    setCategories([]);
    setPrice([0, 1000]);
    setSearchText(word);
    if (word) {
      try {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&search_box=${word}&user_id=${userId}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username: "administrator",
            Password: "yaqess123456",
          },
        });
        setData(result.data.data);
        setTotalCount(result.data.meta.total);
        setPerPage(result.data.meta.per_page);
        setLoading(false);
      } catch (err) {
      }
    } else {
      try {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/getAllExams?page_size=${9}&user_id=${userId}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Username: "administrator",
            Password: "yaqess123456",
          },
        });
        setData(result.data.data);
        setTotalCount(result.data.meta.total);
        setPerPage(result.data.meta.per_page);
        setLoading(false);
      } catch (err) {
      }
    }
  };

  const addToCart = (examId) => {
    (async () => {
      try {
        setSubmitting((prevsubmitting) => ({
          ...prevsubmitting,
          [examId]: true,
        }));
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/cart-store`,
          headers: {
            Accept: "application/json",
            Username: "administrator",
            Password: "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: { exam_id: examId },
        });
        setRefresh(!refresh)
        successNotify(result.data.message);
        setSubmitting((prevsubmitting) => ({
          ...prevsubmitting,
          [examId]: false,
        }));
      } catch (err) {
        ErrorNotify(err.response.data.message);
        setSubmitting((prevsubmitting) => ({
          ...prevsubmitting,
          [examId]: false,
        }));
      }
    })();
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };


  return (
    <>
      <AllTests
        data={data}
        loading={loading}
        loadingAll={loadingAll}
        getPage={getPage}
        totalCount={totalCount}
        perPage={perPage}
        filterData={filterData}
        filterDataLanguage={filterDataLanguage}
        filterDataTags={filterDataTags}
        countries={countries}
        handleFilterChange={handleFilterChange}
        handleFilterLanguageChange={handleFilterLanguageChange}
        handleFilterTagsChange={handleFilterTagsChange}
        handleFilterPrice={handleFilterPrice}
        handleFilterCountryChange={handleFilterCountryChange}
        // handleFilterTypesChange={handleFilterTypesChange}
        handleSearch={handleSearch}
        price={price}
        setPrice={setPrice}
        selectedTypeValue={selectedTypeValue}
        selectedTagValue={selectedTagValue}
        selectedLanguageValue={selectedLanguageValue}
        selectedCountryValue={selectedCountryValue}
        categories={categories}
        addToCart={addToCart}
        cartData={cartData}
        submitting={submitting}
        style={{fontFamily: "Noto Naskh Arabic"}}
      />
    </>
  );
}

export default Tests;
