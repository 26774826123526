import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { FcHome } from "react-icons/fc";
import ReportAnswer from "../Components/ReportAnswer/ReportAnswer";
import ReportSummary from "../Components/ReportSummary/ReportSummary";
import ReportPerformanceAnalysis from "../Components/ReportPerformanceAnalysis/ReportPerformanceAnalysis";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FcPrint } from "react-icons/fc";
import Loader from "../Components/Loader/Loader";
import ReportSkills from "../Components/ReportSkills/ReportSkills";


function AttemptReport() {
  const [activeBtn, setActiveBtn] = useState(1);

  const [reportDataQues, setReportDataQues] = useState([]);

  const [reportDataPublic, setReportDataPublic] = useState([]);

  const [reportDataTime, setReportDataTime] = useState([]);

  const [loading, setLoading] = useState(false);

  const [loadingComb, setLoadingComb] = useState(false);

  const [loadingQues, setLoadingQues] = useState(false);

  const [examLevelTitles, setExamLevelTitles] = useState([]);

  const [activeLevelBtns, setActiveLevelBtns] = useState("");

  const [data, setData] = useState([]);

  const [dataSkills, setDataSkills] = useState([]);

  const [dataCombined, setDataCombined] = useState([]);

  const [mainExamData, setMainExamData] = useState([]);

  const [levelDescription, setLevelDescription] = useState([]);

  const [leoCombined, setLeoCombined] = useState([]);

  const [levelName, setLevelName] = useState([]);

  const [activeLeoBtn, setActiveLeoBtn] = useState([]);

  const examTitle = localStorage.getItem("yaqees-examTitle");

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const { attempId } = useParams();

  const { examType } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    examType === "combined" ?
      (async () => {
        try {
          setLoading(true)
          const getLevels = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getAttemptForCombinedExam/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
          });
          setExamLevelTitles(getLevels.data.data)
          setActiveLevelBtns(getLevels.data.data[0].level_attempt)
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/get-user-response?attempt_id=${getLevels.data.data[0].level_attempt}&exam_type=${examType}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },

          });
          const result2 = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getCombinedExamQuestionReport/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },

          });
          setReportDataQues(result.data.data.questionsWithAnswers);
          setReportDataPublic(result.data.data.attempt_data);
          setReportDataTime(result.data.data.attempt_data.time.split(":"));
          setMainExamData(result2.data.data)
          setLoading(false)
        } catch (err) {
        }
      })() : (async () => {
        try {
          setLoading(true)
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/get-user-response?attempt_id=${attempId}&exam_type=${examType}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },

          });
          setReportDataQues(result.data.data.questionsWithAnswers);
          setReportDataPublic(result.data.data.attempt_data);
          setReportDataTime(result.data.data.attempt_data.time.split(":"))
          setLoading(false)
        } catch (err) {
        }
      })()
  }, []);

  const handleShowLevelReport = async (attId) => {
    setActiveLevelBtns(attId)
    setLoadingQues(true)
    const result = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/get-user-response?attempt_id=${attId}&exam_type=${examType}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Username: "administrator",
        Password: "yaqess123456",
        Authorization: "Bearer " + token,
        token: token,
      },
    });
    setReportDataQues(result.data.data.questionsWithAnswers);
    setReportDataPublic(result.data.data.attempt_data);
    setReportDataTime(result.data.data.attempt_data.time.split(":"))
    setLoadingQues(false)
  }


  useEffect(() => {
    examType === "combined" ?
      (async () => {
        try {
          const getLevels = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getAttemptForCombinedExam/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
          });
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getLoReport/${getLevels.data.data[0].level_attempt}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
            },
          });
          setData(result.data.data);
        } catch (err) {
        }
      })() : (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getLoReport/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
            },
          });
          setData(result.data.data);
        } catch (err) {
        }
      })()
  }, []);

  useEffect(() => {
    examType === "combined" &&
      (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getCombinedAttemptDetail/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
            },
          });
          const result2 = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getLoQuestionsDegree/${result.data.data[0]?.placement_exam[0]?.attempt_code}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
            },
          });
          const result3 = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getLevelDegree/${result.data.data[0]?.placement_exam[0]?.exam_id}/${result.data.data[0]?.placement_exam[0]?.degree}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username: "administrator",
              Password: "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
          });
          setLeoCombined(result2.data.data);
          setActiveLeoBtn(result.data.data[0]?.placement_exam[0]?.attempt_code);
          setLevelName(result.data.data[0]?.placement_exam[0]?.info?.main_title)
          setDataCombined(result.data.data[0]);
          setLevelDescription(result3.data.data[0])
        } catch (err) {
        }
      })()
  }, []);


  const handleShowLeo = async (attId ,levNam ,examId ,degree) => {
    setActiveLeoBtn(attId)
    setLoadingComb(true)
    const result = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/getLoQuestionsDegree/${attId}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Username: "administrator",
        Password: "yaqess123456",
        Authorization: "Bearer " + token,
        token: token,
      },
    });

    const result2 = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/getLevelDegree/${examId}/${degree}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Username: "administrator",
        Password: "yaqess123456",
        Authorization: "Bearer " + token,
        token: token,
      },
    });
    setLeoCombined(result.data.data);
    setLevelName(levNam);
    setLevelDescription(result2.data.data[0])
    setLoadingComb(false);
  }


  useEffect(() => {
    examType === "combined" ?
      (async () => {
        try {
          const getLevels = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getAttemptForCombinedExam/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username : "administrator",
              Password : "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            },
          });
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getTopicReport/${getLevels.data.data[0].level_attempt}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username : "administrator",
              Password : "yaqess123456",
              Authorization: "Bearer " + token,
            },
          });
          setDataSkills(result.data.data);
        } catch (err) {
        }
      })() : (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/getTopicReport/${attempId}`,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Username : "administrator",
              Password : "yaqess123456",
              Authorization: "Bearer " + token,
            },
          });
          setDataSkills(result.data.data);
        } catch (err) {
        }
      })()
  }, []);


  return (
    <div className="test-report"  style={{fontFamily: "Noto Naskh Arabic"}}>
      <div className="head-question py-4">
        <Container className="d-flex align-items-center justify-content-between">
          <div>
            <h5>{examTitle}</h5>
          </div>
          <Link to="/">
            <FcHome className="home-icon" />
          </Link>
        </Container>
      </div>

      {loading ? <Loader/> :
        <div>
          <Container>
            <div className=" d-flex align-items-center justify-content-between">
              <div className="align-items-center justify-content-start my-4 head-btns">
                <button onClick={() => setActiveBtn(1)} className={activeBtn === 1 ? "active-reports-btn" : ""}>  {t("summary")} </button>
                <button onClick={() => setActiveBtn(2)} className={activeBtn === 2 ? "active-reports-btn" : ""}> {t("report-answers")} </button>
                { examType !== "combined" && <button onClick={() => setActiveBtn(3)} className={activeBtn === 3 ? "active-reports-btn" : ""}> {t("cumulative-analysis")} </button>} 
                { examType !== "combined" && <button onClick={() => setActiveBtn(4)} className={activeBtn === 4 ? "active-reports-btn" : ""}>  {t("skills")} </button>}
              </div>
              <Link to={`/print-report/${attempId}/${examType}`}>
              <button className="print-btn"> <FcPrint className="print-icon" /> {t("print")} </button>
              </Link>
            </div>
          </Container>

          {activeBtn === 1 && <ReportSummary  reportDataTime={reportDataTime} reportDataQues={reportDataQues} reportDataPublic={reportDataPublic} dataCombined={dataCombined} leoCombined={leoCombined} activeLeoBtn={activeLeoBtn} handleShowLeo={handleShowLeo} loadingComb={loadingComb} levelName={levelName} mainExamData={mainExamData} levelDescription={levelDescription}/>}
          {activeBtn === 2 && <ReportAnswer reportDataQues={reportDataQues} reportDataPublic={reportDataPublic} examLevelTitles={examLevelTitles} activeLevelBtns={activeLevelBtns} handleShowLevelReport={handleShowLevelReport} loadingQues={loadingQues}/>}
          {activeBtn === 3 && <ReportPerformanceAnalysis reportDataQues={reportDataQues} reportDataPublic={reportDataPublic} data={data} />}
          {activeBtn === 4 && <ReportSkills reportDataQues={reportDataQues} reportDataPublic={reportDataPublic} dataSkills={dataSkills} />}
        </div>}
    </div>
  );
}

export default AttemptReport;

