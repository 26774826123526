import React from 'react'
import { Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";


function About() {

  const { t } = useTranslation();

  return (
    <div className='parent-about'>
        <Container>
        <div className="section1">  <h5> {t("about")} </h5> </div>
   
      <div className='mb-4'>
        <h6> {t("vision")} </h6>
        <p> {t("vision-description")} </p>
      </div>

      <div className='mb-4'>
        <h6> {t("platform")} </h6>
        <p> {t("platform-description")} </p>
      </div>

      <div className='mb-4'>
        <h6> {t("offer")} </h6>
        <p> {t("offer-description1")}

<br/> {t("offer-description2")}

<br/> {t("offer-description3")}

<br/> {t("offer-description4")} </p>
      </div>
      </Container>
    </div>
  )
}

export default About
