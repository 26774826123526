import React, { useEffect, useState } from "react";
import TestInfoHeader from '../Components/TestInfoHeader/TestInfoHeader'
import TestInfoBody from '../Components/TestInfoBody/TestInfoBody'
import StartTestModal from './../Components/StartTestModal/StartTestModal';
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Components/Loader/Loader";


function TestInfo({setRefresh ,refresh}) {

  const [buy, setBuy] = useState(true);
  
  const [showModal, setShowModal] = useState(false);

  const [data, setData] = useState([]);

  const [promoData, setPromoData] = useState([]);

  const [comments, setComments] = useState([]);

  const [relatedData, setRelatedData] = useState([]);

  const [attemptData, setAttemptData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [refreshComment, setRefershComment] = useState(false);

  const [promoRefresh, setPromoRefresh] = useState(false);

  const [submittingHeader, setSubmittingHeader] = useState(false);

  const [submittingBody, setSubmittingBody] = useState(false);

  const location = useLocation();

  const userId = JSON.parse(localStorage.getItem("yaqees-user"))?.id;

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const { id } = useParams();
  const { type } = useParams();

  localStorage.setItem("yaqees-examId", id)
  localStorage.setItem("yaqees-examType", type)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/exam-information/${id}/${type}?user_id=${userId}`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Username : "administrator",
            Password : "yaqess123456",
          },
        });
        setData(result.data.data.main_data);
        setPromoData(result.data.data.promo_code);
        setComments(result.data.data.comments);
        setLoading(false);
        localStorage.setItem("yaqees-examTitle", result.data.data.main_data.title);
        type === "combined" ? localStorage.setItem("yaqees-examLevels", JSON.stringify(result.data.data.main_data.exam_data)) : localStorage.removeItem("yaqees-examLevels");
        type === "combined" && localStorage.setItem("yaqees-success_required", result.data.data.success_required) ;
      } catch (err) {
      }
    })();

  }, [location ,promoRefresh]);


  useEffect(() => {
    (async () => {
      try {
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/exam-information/${id}/${type}`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Username : "administrator",
            Password : "yaqess123456",
          },
        });
        setComments(result.data.data.comments)
      } catch (err) {

      }
    })();

  }, [location, refreshComment]);


  useEffect(() => {
    (async () => {
      try {
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/related-exams/${id}`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Username : "administrator",
            Password : "yaqess123456",
          },
        });
        setRelatedData(result.data.data);

      } catch (err) {

      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/getExamUserAttempt/${id}`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        setAttemptData(result.data.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, []);

  const addToCart = (loader) => {
    (async () => {
      try {
        loader(true);
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/cart-store`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: { exam_id: id},
        });
        setRefresh(!refresh)
        successNotify(result.data.message);
        loader(false);
      } catch (err) {
        ErrorNotify(err.response.data.message);
        loader(false);
      }
    })();
  };


  const handleBuyFree = (loader , examId) => {
    (async () => {
      try {
        loader(true);
        const result1 = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/add-exam-to-user`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data:{exam_ids : examId ,package_id: ""}
        });
            const result = await axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/exam-information/${id}/${type}?user_id=${userId}`,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Username : "administrator",
                Password : "yaqess123456",
              },
            });
            setData(result.data.data.main_data);
            setPromoData(result.data.data.promo_code);
            setComments(result.data.data.rates);
            localStorage.setItem("yaqees-examTitle", result.data.data.main_data.title)
            type === "combined" ? localStorage.setItem("yaqees-examLevels", JSON.stringify(result.data.data.main_data.exam_data)) : localStorage.removeItem("yaqees-examLevels");
        successNotify(result1.data.message);
        loader(false);
      } catch (err) {
        ErrorNotify(err.response.data.message);
        loader(false);
      }
    })();
  };


  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
    <div className='test-info-parent' >
    {loading ? <Loader/> : <><TestInfoHeader buy={buy} setBuy={setBuy} setShowModal={setShowModal} data={data} id={id} promoData={promoData} setPromoRefresh={setPromoRefresh} promoRefresh={promoRefresh} addToCart={addToCart} submittingHeader={submittingHeader} setSubmittingHeader={setSubmittingHeader} handleBuyFree={handleBuyFree}/>
    <TestInfoBody buy={buy} setBuy={setBuy} setShowModal={setShowModal} data={data} relatedData={relatedData} comments={comments} setRefershComment={setRefershComment} refreshComment={refreshComment} id={id} promoData={promoData} addToCart={addToCart} attemptData={attemptData} submittingBody={submittingBody} setSubmittingBody={setSubmittingBody} handleBuyFree={handleBuyFree}/>
      <StartTestModal showModal={showModal} setShowModal={setShowModal} /></>}
  </div>
  )
}

export default TestInfo
