import React from 'react'
import img from "../../Assets/images/education1.png"
import { AiFillCheckCircle } from "react-icons/ai";
import "./StartTestModal.css"
import { Link } from 'react-router-dom';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function StartTestModal({ showModal, setShowModal }) {

  const testId = localStorage.getItem("yaqees-examId");

  const examType = localStorage.getItem("yaqees-examType");

  const { t } = useTranslation();

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='start-test-modal before-str'
    >
      <Modal.Body>
        <Container>
          <Row className='parent-start'>
            <Col md={7}>
              <h5> {t("before-start")} </h5>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content1")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content2")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content3")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content4")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content5")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content6")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content7")} </p>
              <p><AiFillCheckCircle className='true-check' /> {t("before-content8")} </p>
              <div className='d-flex align-items-center justify-content-around mt-4'>
                <Link to={`/training-questions/${testId}`}>
                  <button className='start-training-btn'> {t("training-first")} </button>
                </Link>
                <Link to={`/exam-questions/${testId}`}>
                  <button className='start-test-btn'> {t("start-exam")} </button>
                </Link>
              </div>
            </Col>
            <Col md={5}>
              <img src={img} alt='' />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default StartTestModal
