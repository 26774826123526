import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ReportSkills.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Accordion from 'react-bootstrap/Accordion';
import { GiGraduateCap } from 'react-icons/gi';
import { FiBookOpen } from 'react-icons/fi';
import { useTranslation } from "react-i18next";

function ReportSkills({ reportDataQues, reportDataPublic, dataSkills }) {

  const { t } = useTranslation();

  const groupedData = {};

  dataSkills.forEach(item => {
    if (!groupedData[item.SUBJECT_ID]) {
      groupedData[item.SUBJECT_ID] = {};
    }

    if (!groupedData[item.SUBJECT_ID][item.UNIT_ID]) {
      groupedData[item.SUBJECT_ID][item.UNIT_ID] = [];
    }

    groupedData[item.SUBJECT_ID][item.UNIT_ID].push(item);
  });

  const groupedArray = Object.entries(groupedData).map(([subjectId, topics]) => ({
    SUBJECT_ID: subjectId,
    TOPICS: Object.entries(topics).map(([topicId, items]) => ({
      TOPIC_ID: topicId,
      ITEMS: items,
    })),
  }));


  const finalData = groupedArray.map(item => item.TOPICS).map(item => item.map(item => item.ITEMS));


  function filterRepeatedTopics(data) {
    const seenTopicIDs = new Set(); 
    const filteredData = data.map(topic => {
      if (seenTopicIDs.has(topic.TOPIC_ID)) {
        delete topic.SUBJECT_NAME_EN;
        delete topic.UNIT_NAME_EN;
        return topic;
      } else {
        seenTopicIDs.add(topic.TOPIC_ID);
        return topic;
      }
    });
    return filteredData;
  }

  const filteredFinalData = finalData.map(array => array.map(topicArray => filterRepeatedTopics(topicArray)));

  const sumQuesMarks = reportDataQues
    .map((item) => item.QUES_MARK)
    .reduce(function (x, y) {
      return +x + +y;
    }, 0);

  const QuesMarksCorrect = reportDataQues
    .filter((item) => item.ResultSuccess === "correct")
    .map((item) => item.QUES_MARK)
    .reduce(function (x, y) {
      return +x + +y;
    }, 0);

  const percentage = reportDataPublic.degree;

  const allQues = reportDataQues.length;
  const correct = reportDataQues.filter((item) => item.ResultSuccess === "correct").length;
  const wrong = reportDataQues.filter((item) => item.ResultSuccess === "wrong").length;
  const skip = reportDataQues.filter((item) => item.ResultSuccess === "").length;

  const correctAnswersPercentage = Math.round((correct / allQues) * 100);
  const wrongAnswersPercentage = Math.round((wrong / allQues) * 100);
  const skipAnswersPercentage = Math.round((skip / allQues) * 100);

  useEffect(() => {
    const removeChildren = () => {
      var parentDivs = document.querySelectorAll('.head-acco');

      parentDivs.forEach(parentDiv => {
        var children = parentDiv.children;
        var startIndex = 2;

        for (var i = children.length - 1; i >= startIndex; i--) {
          parentDiv.removeChild(children[i]);
        }
      });
    };

    removeChildren();
  }, []);

  return (
    <Container className="report-answer">
          <div className='head-title-repo my-3'>
            <div className="textLine mb-3">
        <h5 className="headTitle"> {t("skills")} </h5>
        <hr className="hrLine" />
      </div>

      <div className="card mt-3 mb-4">
        <div className="card-body">
        <p className='repo-desc'> عرض مستوى اكتساب الطالب للمهارات المستهدفة، والتحقق من فهم واستيعاب الموضوعات، بحيث يمكن تحديد الفجوات التعليمية على مستوى كل موضوع أو مهارة، وتقديم فيديوهات شرح وعناصر تعليمية مناسبة لتساعد الطالب على اجتياز الاختبار في المحاولات التالية.  </p>
        </div>
      </div>
      </div>
      <Row>
        <Col md={8}>
          {filteredFinalData.map((array, index) => (
            <div className="parent-collapse" key={index}>
              <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey="0">
                  <Accordion.Header > <div className="d-flex align-items-center head-acco w-100">
                    <GiGraduateCap className="graduate-cap" />
                    {array[0].map((item, index) => (
                      <div key={index}>
                        <h4>{item.SUBJECT_NAME_EN}</h4>
                      </div>
                    ))}

                  </div></Accordion.Header>
                  <Accordion.Body>
                    {array.map((item, index) => (
                      <div className="child-collapse" key={index}>
                        <Accordion defaultActiveKey="0" >
                          <Accordion.Item eventKey="1">
                            <Accordion.Header > <div className="d-flex align-items-center my-2 w-100">
                              <FiBookOpen className="book-icon skill-book" />
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <h5>{item[0].UNIT_NAME_EN} </h5>
                                <div className="mx-5">
                                  <CircularProgressbar
                                    minValue
                                    value={Math.round(item.reduce((acc, item) => {
                                      return Math.round(acc + (item.correct / item.questions_count) * 100);
                                    }, 0) / ((item.map(i => i).flat().length) * 100) * 100)}

                                    text={`${Math.round(item.reduce((acc, item) => {
                                      return Math.round(acc + (item.correct / item.questions_count) * 100);
                                    }, 0) / ((item.map(i => i).flat().length) * 100) * 100)
                                      }%`}
                                    strokeWidth={8}
                                    styles={buildStyles({
                                      pathColor: Math.round(item.reduce((acc, item) => {
                                        return Math.round(acc + (item.correct / item.questions_count) * 100);
                                      }, 0) / ((item.map(i => i).flat().length) * 100) * 100) > 0 && Math.round(item.reduce((acc, item) => {
                                        return Math.round(acc + (item.correct / item.questions_count) * 100);
                                      }, 0) / ((item.map(i => i).flat().length) * 100) * 100) < 50 ? `#E80C53` : Math.round(item.reduce((acc, item) => {
                                        return Math.round(acc + (item.correct / item.questions_count) * 100);
                                      }, 0) / ((item.map(i => i).flat().length) * 100) * 100) >= 50 ? `#4DB847` : "",
                                      textColor: "#1B1A1D",
                                      trailColor: "#d6d6d67d",
                                    })}
                                    className="circle-perc"
                                  />
                                </div>

                              </div>
                            </div></Accordion.Header>
                            <Accordion.Body>
                              <div id="example-collapse-text">
                                {item.map((item, index) => (
                                  <div className="lco-parent" key={index}>
                                    <div className="d-flex align-items-center justify-content-between lco-sec my-2">
                                      <h6 className="my-3">{item.lo_title}</h6>
                                      <div>
                                        <CircularProgressbar
                                          minValue
                                          value={Math.round((item.correct / item.questions_count) * 100)}
                                          text={`${Math.round((item.correct / item.questions_count) * 100)}%`}
                                          strokeWidth={8}
                                          styles={buildStyles({
                                            pathColor: Math.round((item.correct / item.questions_count) * 100) > 0 && Math.round((item.correct / item.questions_count) * 100) < 50 ? `#E80C53` : Math.round((item.correct / item.questions_count) * 100) >= 50 ? `#4DB847` : "",
                                            textColor: "#1B1A1D",
                                            trailColor: "#d6d6d67d",
                                          })}
                                          className="circle-perc"
                                        />
                                      </div>
                                    </div>

                                  </div>
                                ))}

                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ))}


        </Col>
        <Col md={4}>
          <div
            className="analysis-charts repo-chart"
            style={{ margin: "0", height: "420px" }}
          >
            <div className="px-4">
              <h5 className='mb-2'> {t("degree")} </h5>
              <p> {t("description-degree")} </p>
            </div>
            <hr />
            <div className="px-4" style={{ width: 200, margin: "35px auto" }}>
              <div className="result-degree">
                <p> {t("degree")} <span> {QuesMarksCorrect} / {sumQuesMarks} </span></p>
              </div>
              <CircularProgressbar
                minValue
                value={percentage}
                text={`${percentage}%`}
                strokeWidth={8}
                styles={buildStyles({
                  pathColor: `#005C9A`,
                  textColor: "#1B1A1D",
                  trailColor: "#d6d6d67d",
                })}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between px-4">
              <div>
                <div className="progress">
                  <div className="progress-bar correct-bar" role="progressbar" style={{ width: `${correctAnswersPercentage}%` }} aria-valuenow={correctAnswersPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p> {t("correct")} <span>{correct}</span></p>
              </div>

              <div>
                <div className="progress">
                  <div className="progress-bar wrong-bar" role="progressbar" style={{ width: `${wrongAnswersPercentage}%` }} aria-valuenow={wrongAnswersPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p> {t("incorrect")} <span>{wrong}</span></p>
              </div>

              <div>
                <div className="progress">
                  <div className="progress-bar skip-bar" role="progressbar" style={{ width: `${skipAnswersPercentage}%` }} aria-valuenow={skipAnswersPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <p> {t("skip")} <span>{skip}</span></p>
              </div>


            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ReportSkills
