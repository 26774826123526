import React, { useEffect, useState } from "react";
import BriefSection from "../Components/BriefSection/BriefSection";
import ImproveSection from "../Components/ImproveSection/ImproveSection";
import TestSection from "../Components/TestSection/TestSection";
import BlogSection from "../Components/BlogSection/BlogSection";
import WhyYaqesSection from "../Components/WhyYaqesSection/WhyYaqesSection";
import FAQs from "../Components/FAQs/FAQs";
import SubscripSection from '../Components/SubscripSection/SubscripSection';
import axios from "axios";
import BriefExams from "../Components/BriefExams/BriefExams";


function Home() {

  const [activeClass, setActiveClass] = useState("");

  const [data, setData] = useState([]);

  const [testsData, setTestsData] = useState([]);

  const [blogsData, setBlogsData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // const result = await axios({
        //   method: "GET",
        //   url: `${process.env.REACT_APP_BASE_URL}/getAllTags`,
        //   headers: {
        //     Accept: "application/json",
        //     Username : "administrator",
        //     Password : "yaqess123456",
        //     "Content-Type": "application/json",
        //   },
        // });
        // const result2 = await axios({
        //   method: "GET",
        //   url: `${process.env.REACT_APP_BASE_URL}/getExamsByTagId/${result.data.data[0].id}`,
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     Username : "administrator",
        //     Password : "yaqess123456",
        //   },
        // });
        const result3 = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/blogs`,
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Username : "administrator",
              Password : "yaqess123456",
          },
      });
      setBlogsData(result3.data.data.data);
      setLoading(false);
        // setData(result.data.data);
        // setTestsData(result2.data.data);
        // setActiveClass(result.data.data[0].id)
      } catch (err) {

      }
    })();
  }, []);


  // const handleSelectTests = async (id) => {
  //   setLoading(true);
  //   setActiveClass(id);
  //   const result = await axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_BASE_URL}/getExamsByTagId/${id}`,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Username : "administrator",
  //       Password : "yaqess123456",
  //     },
  //   });
  //   setTestsData(result.data.data);
  //   setLoading(false);

  // }



  return (
    <>
      <BriefSection />
      <BriefExams/>
      <ImproveSection />
      {/* <TestSection handleSelectTests={handleSelectTests} data={data} testsData={testsData} loading={loading} activeClass={activeClass} /> */}
      <BlogSection blogsData={blogsData} loading={loading}/>
      <WhyYaqesSection />
      <FAQs />
      <SubscripSection />
    </>
  );
}

export default Home;
