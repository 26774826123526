import React from 'react'

function TermsService() {
  return (
    <div className='terms-service container'>
      <h4> شروط الخدمة </h4>
      <h5> مرحبًا بكم في يقيس </h5>

      <p> يرجى قراءة شروط الخدمة، وسياسة الخصوصية، وميثاق الشرف الخاص يقيس قبل التسجيل في يقيس أو استخدام أي جزء من محتويات التطبيق، (ونعني بكلمة «التطبيق» جميع المحتويات والصفحات التي تقع ضمن نطاق تطبيق يقيس بما في ذلك الوصول إلى أي من المواد الخاصة بالمحتوى، وغرف الدردشة، أو الخدمات الإلكترونية الأخرى.
تعتبر كل من هذه الشروط، وسياسة الخصوصية، وميثاق الشرف، بمثابة اتفاقيات (قد يتم الإشارة إليها فيما بعد مجتمعةً باسم «الاتفاقيات») بينك وبين تطبيق يقيس، كما توافق عند استخدامك للتطبيق على الالتزام قانونيًّا بالاتفاقيات، سواء كنت مستخدمًا مسجلًا على التطبيق أم لا. </p>

      <h6> بند 1: </h6>
      <p>  تحتفظ «يقيس» بحق تعديل هذه الشروط في أي وقت دون إشعارك سلفًا، وتُعتبر التغييرات التي تتم على هذه الشروط نافذة فور نشرها على هذه الصفحة، أو ضمن أي قسم آخر من التطبيق.
    <br/>• يُعدُّ دخولك إلى التطبيق بعد إجراء أي تغييرات موافقةً منك على الشروط المعدَّلة، وكافة التغييرات الواردة فيها.
    <br/>• تأكَّد من ترددك على هذه الصفحة بشكل دوري؛ لتبقى مُطَّلِعًا على الإصدار الأحدث لهذه الشروط.
    <br/>• تقدِّم يقيس المحتوى التعليمي عبر تطبيق يقيس، من تأليف مجموعة من خبراء المحتوى، وفقًا للمعايير الدولية، والتقييم الفردي للطالب. </p>

      <h6> بند 2: منح الترخيص إلى المستخدمين: </h6>
      <p> تمنح -بموجب ما تقوم بتقديمه، أو ما توزِّعه من منشورات المستخدم الخاصة - جميعَ مستخدمي التطبيق رخصةً غير حصرية للوصول إلى المادة العلمية المستخدمة الخاصة واستخدامها. </p>

      <h6> بند 3: قواعد السلوك عبر الإنترنت </h6>
      <p> توافق على أنك مسئول بالكامل عن استخدامك للتطبيق، وللمواد التي يتم نشرها من خلال اسم المستخدم الخاص بك، والتي يجب أن تشمل كامل المحتوى الذي يتم تقديمه ونشره أو توزيعه على التطبيق، من قِبَلِك أو من قِبَل مستخدمين آخرين للتطبيق، بما في ذلك -على سبيل المثال- النصوص المكتوبة والمسموعة، وجميع المشاركات ضمن المحتوى، والملاحظات، والأسئلة، والتعليقات، ومقاطع الفيديو، وتحميل الملفات (مشاركات المستخدم).
كما توافق على استخدامك التطبيق، ملتزمًا بالاتفاقيات وجميع القوانين المحلية والإقليمية والدولية، والقواعد والأنظمة المتَّبعة، بما في ذلك قوانين حقوق التأليف والنشر، وأي قوانين تتعلق بنقل البيانات الفنية المصدَّرة من بلد إقامتك. </p>

      <h6> توضِّح القائمة الآتية المواد التي يُمنع منعًا باتًّا تداولها على الموقع الإلكتروني: </h6>
      <p>     • المحتوى الذي يُشهِّر أو يضايق أو يهدِّد الآخرين.
    <br/>• المحتوى الذي يناقش أنشطة غير مشروعة.
    <br/>• المحتوى الذي ينتهك الملكية الفكرية لطرف ثالث، بما في ذلك -على سبيل المثال- النصوص المسموعة أو المكتوبة، وحقوق التأليف والنشر، أو العلامات التجارية.
    <br/>• المحتوى الدَّنِس، أو الإباحي، أو المخلُّ بالآداب، أو غير اللائق، أو غير المصرَّح به.
    <br/>• الإعلانات أو أي شكل من أشكال الدعاية التجارية.
    <br/>• المحتويات التي لها علاقة بالأنشطة السياسية الحزبية.
    <br/>• الفيروسات، أو الملفات التالفة، والبرمجيات الخبيثة، وبرامج التجسس، أو أي برامج أخرى مماثلة قد تُلحق الضرر بعمل الحاسوب أو الممتلكات الأخرى.
    <br/>• المحتوى الذي يتضمَّن معلومات غير دقيقة عن قصد، أو التي يتم نشرها بقصد تضليل الآخرين.
    <br/>• علاوة على ذلك، توافق على عدم محاولة جمع معلومات، أو تنزيل أي محتوًى من محتويات التطبيق (يقيس) بكميات كبيرة، بما يشمل قائمة أو دليل مستخدمي النظام، والكتب الدراسية المتاحة عبر الإنترنت، أو منشورات المستخدمين، أو معلوماتهم الشخصية. كما توافق على عدم تحريف، أو محاولة تحريف هُويتك أثناء استخدام التطبيق (يقيس).
    <br/>• إن أي انتهاك لبنود قائمة المحظورات المذكورة أعلاه يُخَوِّل «تطبيق يقيس» لإنهاء استخدامك ووصولك أو مشاركتك في التطبيق، أو هما معًا، دون إشعار مُسبَق.
    <br/>• تتعهَّد من خلال هذه الوثيقة -وكشرط لتحظى بالخدمات التي يقدِّمها تطبيق يقيس - أنك لن تستخدم التطبيق بأي طريقة تهدف إلى الإساءة، أو تعطيل، أو إعاقة، أو إرهاق خادم «يقيس»، أو شبكة (شبكات) «يقيس» المتصلة بأي خادم من خوادمها، أو التدخُّل بأي طرف ثالث والتمتُّع بالتطبيق. كما لا يجوز لك محاولة الوصول غير المصرَّح به إلى الموقع عن طريق حسابات أخرى، أو أنظمة كمبيوتر، أو شبكات متصلة بأي خادم من خلال القرصنة أو استخراج كلمة المرور أو أي وسيلة أخرى.
    <br/>• لا يجوز الحصول أو محاولة الحصول على أي مواد أو معلومات مخزَّنة على الموقع، أو أيٍّ من خوادمه، أو أجهزة الحاسوب المرتبطة به، من خلال أي وسيلة لم يتم إتاحتها عن قصد من خلال التطبيق.
 </p>

      <h6> بند 4: الصلاحيات: </h6>
      <p> 
للاستفادة من المشاركة الكاملة في الأنشطة والخدمات التي يقدِّمها «يقيس»؛ يجب عليك تزويدنا باسمك، وعنوان بريدك الإلكتروني، وكلمة المرور الخاصة؛ وذلك لإنشاء حساب مستخدم جديد، (والذي يُشار إليه بـ «حساب المستخدم»). ولن تقوم بإفشاء دخولك التطبيق، أو مشاركته، أو مشاركة معلومات حساب المستخدم الخاص مع طرف ثالث لأي سبب من الأسباب.
    <br/>• أثناء إنشاء حساب المستخدم الخاص، قد تتم مطالبتك بإدخال معلومات إضافية اختيارية (على سبيل المثال: عنوانك)، وبهذا تُصرِّح أن جميع المعلومات التي قدَّمتها هي معلومات حقيقية ودقيقة وحديثة. كما توافق على صيانة وتحديث المعلومات الخاصة؛ لإبقائها دقيقة وحديثة.
    <br/>• نحن نهتم بسرية وأمن المعلومات الشخصية الخاصة.
    <br/>• يرجى الاطلاع على سياسة الخصوصية الخاصة بنا؛ لمعرفة المزيد حول ماهيَّة المعلومات التي قد يجمعها «تطبيق يقيس» عنك، وكيف يستخدم «تطبيق يقيس» تلك المعلومات.
    <br/>• يُعدُّ دخولك إلى التطبيق موافقة على جمع واستخدام المعلومات الخاصة من قِبَل «تطبيق يقيس». </p>

<h6> بند 5: مسئولية منشورات المستخدم </h6>
<p> على الرغم من قائمة المواد التي يُمنع منعًا باتًّا تداولها على الموقع، كما هو مبيَّن أعلاه، والتي تتعلق بتقديم أو توزيع منشورات المستخدم الخاصة ، يمنح الحقوق اللازمة والتراخيص والموافقات والأذونات لإعادة نشر منشورات المستخدم على التطبيق، والسماح لـ«يقيس» ومستخدميها بإعادة الإنتاج والتعديل والنشر، وما إلى ذلك من استخدام منشورات المستخدم الخاصة وتوزيعها بطريقة تتفق مع التراخيص التي تُمنح في ظل هذه الشروط، وأنك لن تعمد إلى خَرق حقوق طرف ثالث أو انتهاكها، من خلال تقديمك لمنشورات المستخدم الخاصة ، أو استخدامك للتراخيص الممنوحة أدناه.
تتحمل، وليس «يقيس»، المسئولية الكاملة عن منشورات المستخدم الخاصة، وعواقب مشاركتها مع الآخرين، أو نشرها على التطبيق. </p>


<h6> بند 6: قانون حماية استخدامك للمحتوى </h6>

<p>     • تمَّت حماية محتويات التطبيق كافة من خلال قانون حماية حقوق التأليف والنشر
   <br/> • إن جميع النصوص والامتحانات ومقاطع الفيديو والصور وغيرها من المواد التعليمية المتوفِّرة مع المحتوى، المقدَّمة في هذا الموقع، هي للاستخدام الشخصي الخاص والمتعلِّق بذلك المحتوى فقط، ما لم يَرِد خلاف ذلك صراحةً ضمن الموقع.
   <br/> • يخضع استخدام بعض الوثائق المرجعية، والكتب الدراسية الرقمية، والمقالات، وغيرها من المعلومات على الموقع لإذنٍ من قِبَل طرف ثالث، إذ يخضع استخدام هذه المعلومات لقواعد وشروط معينة سيتم نشرها جنبًا إلى جنب مع هذه المعلومات.
   <br/>• تتعهَّد بموجب هذه الوثيقة، وباستخدامك لهذا التطبيق، بالالتزام بجميع هذه القواعد والشروط، كما أنك توافق على الحفاظ على جميع حقوق التأليف والنشر والإشعارات الأخرى التي تخص أي محتوى تحصل عليه من خلال التطبيق.
   <br/>• جميع حقوق هذا التطبيق ومحتواه محفوظة من قِبل «تطبيق يقيس». </p>


<h6> بند 7: العلامات التجارية </h6>
<p> استخدام «يقيس» وغيرها من أسماء المؤسسات المشاركة والعلامات التجارية: </p>
    
    <p>     • إن اسم وشعار «يقيس» هما علامتان تجاريتان تابعه الى تطبيق يقيس، لا يجوز استخدام أي من العلامات التجارية الخاصة بالتطبيق، أو ما يشبه أيًّا من المذكور، لأغراض ترويجية، أو بأي شكل قد يوضِّح أو يوحي عمدًا أو سهوًا، أو يظهر، أو يعطي انطباعًا بوجود علاقة أو تأييد من قِبَل هذا التطبيق، وذلك تبعًا لتقدير المؤسَّسة المعنية وحدها، وأي أمر خلاف هذا يجب أن يكون بموافقة خَطِّيَّة مسبقة من صاحب العلامة التجارية ذات الصلة.
    <br/>• لا ينبغي أن يُفسَّر أي محتوًى من المحتويات الواردة ضمن هذا التطبيق على أنه يمنح -ضمنًا أو قانونًا أو خلاف ذلك- أي ترخيص أو حق لاستخدام أي علامة تجارية معروضة على التطبيق دون الحصول على إذن خطِّيٍّ من صاحب العلامة التجارية ذات الصلة.
    <br/>• لا تعود ملكية أي من العلامات التجارية إلى «يقيس»، أو غيرها من المؤسَّسات المشاركة التي تظهر على التطبيق، أو في الخدمات المتاحة على التطبيق، أو من خلاله، فهي -إن وُجدت- ملكية حصرية لأصحابها. </p>
    
    <h6> بند 8: حدود المسئوليات </h6>
    <p>     • إنَّ التطبيق وأيَّ معلومات أو محتويات أو خدمات -متوفِّرة على التطبيق أو من خلاله- مقدَّمة «كما هي»، و«كما هي متوفرة» دون ضمان من أي نوع (صريح أو ضمني أو غير ذلك)، بما في ذلك، وعلى سبيل المثال لا الحصر، أي ضمانات ضمنية للرواج والملاءمة لغرض معيَّن، وعدم مخالفتها، فيما عدا الضمانات الشبيهة لهذه التي قد لا يجوز التنازل عنها بموجب القوانين المعمول بها.
    <br/>• لا يضمن «تطبيق يقيس» والمشاركون فيها (كما هم معرَّفون أدناه) عمل التطبيق دون انقطاع، أو بشكل خالٍ من الأخطاء، أو أن التطبيق خالٍ من الفيروسات أو المكوِّنات الضارة الأخرى، أو أن المحتوى المضاف أو المقدَّم من شأنه تلبية احتياجاتك أو توقعاتك.
    • ليقع استخدام هذا التطبيق والمحتوى والخدمات التي يتم الحصول عليها من التطبيق أو عبره على مسئوليتك الخاصة، كما أن دخولك إلى التطبيق أو تنزيلك لأي من المعلومات أو المواد أو البيانات المتاحة من خلال التطبيق، أو أي من المواقع المرجعية يقع تحت ضمانك ومجازفتك، وستكون وحدك مسئولًا عن أي ضرر يلحق بالممتلكات الخاصة، (بما في ذلك نظام الهاتف الخاص بك)، أو أي فقدان للبيانات ينتج عن تنزيل أو استخدام هذه المواد أو البيانات، ما لم يتم التصريح بخلاف ذلك ضمن سياسة الخصوصية لـ «يقيس». </p>
   
   <h6> بند 9: التنازل المتعلِّق بمنشورات المستخدم </h6>
   <p>     • لا تلتزم «يقيس» ولا أي من مشاركي «يقيس» بمراقبة أي من منشورات المستخدمين، أو أي تواصل يتم مع أعضاء آخرين ضمن التطبيق.
    <br/>• تحتفظ «يقيس» بحق مراجعة منشورات المستخدمين، والتصرف وفقًا لتقديرها المطلق؛ وذلك في أي وقت، ولأي سبب من الأسباب.
    <br/>• تقوم «يقيس» بتعديل منشورات المستخدم غير المطابقة لشروطها أو إزالتها -كليًّا أو جزئيًّا- في أي وقت تختاره، ودون سابق إنذار.
    <br/>• تقوم «يقيس» بالتحقيق في مزاعم وجود منشور لمستخدِم لا يتوافق مع شروط «يقيس» لتقديم الخدمة، وذلك عند استلام إشعار من مستخدِم أو مالك المحتوى، </p>
   

   <h6> بند 10: روابط لمواقع أخرى </h6>
   <p>     • قد يتضمن التطبيق وصلات إلى مواقع أخرى يتم صيانتها وإدارتها من قِبَل أشخاص آخرين.
   <br/> • ليست «يقيس» - أو أي من مشاركي «يقيس» - مسئولين عن مسح المحتويات، أو الموافقة عليها، أو مراجعتها، أو المصادقة عليها، أو استخدام أي من المنتجات، أو الخدمات التي يمكن أن تقدمها هذه المواقع الأخرى.
   <br/> • توافق ولأقصى حد تسمح به القوانين المتبعة على عدم مسئولية «يقيس» أو أي من مشاركي «يقيس» عن أي خسارة أو ضرر، سواء كان حقيقيًّا، أو مرتبطًا منطقيًّا، أو ناشئًا عن شروط تقديم هذه الخدمة، أو متعلقًا بها، أو باستخدامك (أو استخدام أي طرف ثالث)، أو عدم القدرة على استخدام التطبيق، أو مواضع المحتوى على التطبيق، أو اعتمادك الخاص بك على المعلومات التي يتم الحصول عليها من التطبيق أو عبره، سواء اعتمدتْ مطالبتك على العقد أو الضرر أو القانون أو غير ذلك. </p>
   
   <h6> ما البيانات الشخصية التي نجمعها ولماذا نقوم بجمعها؟</h6>
   <p>     • نقوم بجمع البيانات التي يدخلها المستخدمون طواعية للاشتراك بتطبيق يقيس
    <br/>• بالنسبة للمستخدمين الذين قاموا بالتسجيل على التطبيق، نقوم أيضًا بتخزين المعلومات الشخصية التي يقدمونها في ملف تعريف المستخدم الخاص بهم. يمكن لجميع المستخدمين الاطلاع على معلوماتهم الشخصية أو تعديلها في أي وقت (باستثناء أنه لا يمكنهم تغيير اسم المستخدم الخاص بهم) </p>
   
   <h6> خطوات حذف الحساب </h6>

   <p> يمكن للأشخاص المسجلين باستخدام الايميل الشخصي، أو التسجيل عبر ربط حسابهم (فيسبوك Facebook) بالتطبيق ارسال ايميل الى info@yaqees.app   مرفقاً بالبريد الالكتروني المسجل من خلاله الحساب الخاص به.
أو في حالة كان المشترك مسجلا بواسطة حسابهم على فيسبوك (Facebook) يمكن من خلال إزالة إمكانية الوصول للتطبيق من إعدادات الفيسبوك سوف يتم أوتوماتيكيا مسح كافة البيانات الخاصة بك عم طريق الخطوات التالية: </p>
    
    <p> 
    • الذهاب إلى الإعدادات والخصوصية، ثم انقر على الإعدادات.
    <br/>• في القائمة اليمنى انقر على التطبيقات ومواقع الويب.
    <br/>• انتقِل إلى التطبيق أو موقع الويب الذي تريد إزالته، ثم انقر بجوار اسم التطبيق أو موقع الويب على إزالة.
    <br/>• يمكنك اختيار أحد هذه الخيارات:
    <br/>• حذف المنشورات أو مقاطع الفيديو أو المناسبات التي قام [تطبيق أو موقع ويب] بنشرها على يومياتك.
    <br/>• السماح لفيسبوك بإخطار [تطبيق أو موقع ويب] بأن اتصال تسجيل الدخول الخاص بك تمت إزالته. قد يعرضون عليك طريقة أخرى لتسجيل الدخول.
    <br/>• انقر على إزالة مرة أخرى للتأكيد.
 
ستنتهي الحقوق الممنوحة لك بموجب هذه الاتفاقية تبعًا لإنهاء حقك في استخدام التطبيق، ولكن العمل سيستمر بالأحكام الأخرى لشروط هذه الخدمة.
 </p>

<h6> بند 12: التعويض </h6>

<p> أنت توافق على الدفاع وعدم الإضرار بـ«يقيس»، أو بمشاركي «يقيس»، والشركات التابعة لكل منها، وفروعها، وأعضاء مجلس الإدارة المسئولين، والوكلاء، والموظفين، بما في ذلك أي مسئولية أو مصاريف تنشأ من أي مطالبات. </p>

<p>     • كامل الاتفاقية
تُشكِّل جميع هذه الشروط، وميثاق الشرف، وسياسة الخصوصية مجمل الاتفاق بينك وبين «يقيس» فيما يتعلق باستخدامك للتطبيق، وتحل محل أي اتفاقيات سابقة بينك وبين «يقيس» بخصوص استخدامك للتطبيق.
بموافقتك على شروط الخدمة هذه بعد استخدامك، لا يحق لك الاعتراض على متطلبات وصلاحيات يقيس.
يُعدُّ هذا الميثاق ساري المفعول بدءًا من عام 2022م
 </p>
    </div>
  )
}

export default TermsService
