import React, { useState, useEffect } from "react";
import {  Container } from 'react-bootstrap'
import "./PickFromListQues.css"
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import Parse from "html-react-parser";
import Select from 'react-select';
import { TbBulbFilled } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";

function PickFromListQues({ data, index, setChangeBtn, checked, setChecked, showAnswer, selectedAnswer, setSelectedAnswer, setCorrectOptions ,type ,renderMathType ,setShowQuestionSection ,showQuestionSection}) {

  const { t } = useTranslation();

  const chooseArray = JSON.parse(data[index]?.QUS_OPTION)

  const optionsArray = Object.values(chooseArray)

  const myArray = optionsArray.map((item => ({ label: item, value: item })));

  const handleChooseChange = (e) => {
    setSelectedAnswer(e.value);
    setChangeBtn(2);
    setCorrectOptions(Object.values(JSON.parse(data[index].QUS_CORRECT_RESPONSE)).toString())
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? "rgba(0, 92, 154, 0.1)" : isFocused ? undefined : null,
        color: isSelected ? "black" : "#333333",
        cursor: isFocused ? " pointer" : null,
        border: isSelected ? "1px solid #005C9A" : null,
        borderRadius: isSelected ? "0" : null,
      };
    }
  };

  const [reminderCard, setReminderCard] = useState([]);

    const [show, setShow] = useState(false);
  
    const [loading, setLoading] = useState(false);
  
    const handleClose = () =>  setShow(false) ;
    const handleShow = () => setShow(true);
  
    useEffect(() => {
      (async () => {
        try {
          setLoading(true);
          const result = await axios({
            method: "GET",
            url: `https://testcards.weghat.com/public/api/reminder-card/get/${data[index].CAT_ID}/${data[index].ST_LO_ID}`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          setReminderCard(result.data);
          setLoading(false);
        } catch (err) {
        }
      })();
    }, [index]);

    let str = data[index].QUESTION_TEXT
    let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');
  
  
  
  
    let strAnswer = data[index].ANSWER_EXPLANATION
    let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');
  
    useEffect(() => {

      setShowQuestionSection(true);

      const elements = document.getElementsByClassName("explain");
  
      const elements2 = document.getElementsByClassName("preview");
  
      const elements3 = document.getElementsByClassName("mathLabel");
  
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
  
        if (element) {
          if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
            window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
          }
        }
      }
  
      for (let i = 0; i < elements2.length; i++) {
        const element = elements2[i];
  
        if (element) {
          if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
            window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
          }
        }
      }
  
      for (let i = 0; i < elements3.length; i++) {
        const element = elements3[i];
  
        if (element) {
          if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
            window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
          }
        }
  
      }
  
  
    }, [index, renderMathType ,showQuestionSection]);
  

  return (
    <div className='choose-question d-flex  justify-content-center'>
      <Container >
      {showQuestionSection &&
        <div className='ques pt-5 pb-2'>
        <div className="d-flex align-items-start justify-content-between">
        <h5 className="preview">{data[index].QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${data[index].QUESTION_TEXT}`)}</h5>
          {reminderCard.message === "success" && type === "training" ?  <div className="bulb-icon"> <TbBulbFilled className="reminder-icon" onClick={handleShow} />  </div> : ""}
          </div>

          {data[index].QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${data[index].QUESTION_FILE_NAME}`} controls />
          )}

          <div className="form-input mt-4">
            <Select
              className="basic-single mathLabel"
              classNamePrefix="select"
              defaultValue={myArray[0]}
              options={myArray}
              onChange={showAnswer === false ? handleChooseChange : null}
              styles={colourStyles}
              isDisabled={showAnswer === false ? false : true}
            />
          </div>
          {showAnswer === true && (
            <div style={{ marginTop: "190px" }}>
              {selectedAnswer === Object.values(JSON.parse(data[index].QUS_CORRECT_RESPONSE)).toString() ? (
                <div className="true-answer">
                  <h5>
                    <IoIosCheckmarkCircle className="true-icon" /> {t("correct-answer")}
                  </h5>
                  <div className="explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                </div>
              ) : (
                <div className="false-answer">
                  <h5>

                    <IoIosCloseCircle className="false-icon" />
                    {t("incorrect-answer")}
                  </h5>
                  <div className="explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                </div>
              )}
            </div>
          )}
        </div>
}
      </Container>
      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: "9999999999999" ,  fontFamily: 'Noto Naskh Arabic'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{color:"#005C9A"}}> {t("reminder-card")} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {loading ? <Loader/> :
            <div>
              {reminderCard.message === "success" && Parse(`${reminderCard?.data[0]?.content}`)}
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PickFromListQues
