import React from 'react'
import "./Loader.css"

function Loader() {
  return (
    <div className="loader__wrapper">
    <ul className="loader p-0 m-0">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>
  )
}

export default Loader