import React , { useEffect }  from 'react'
import Parse from "html-react-parser";
import { Col } from 'react-bootstrap';
import Dragula from 'react-dragula';
import { RiDragMove2Line } from "react-icons/ri";

function OrdaringSkip({showQuestion  ,setSelectedAnswer ,setCorrectOptions}) {

    const chooseArray = JSON.parse(showQuestion?.QUS_OPTION)

    // const [randomChooseArray, setRandomChooseArray] = useState(Object.values(chooseArray).sort(() => Math.random() - 0.5))
    
    const str = showQuestion.QUS_CORRECT_RESPONSE

    const filterStr = str.replace(/{/g, '[').replace(/}/g, ']').replace(/"(\d{1,2}|100)":/g, '');
    const endARRoptions = JSON.parse(filterStr);

    const dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
          let options = { };
          const dragula = Dragula([componentBackingInstance], options);
          dragula.on('drop', (el, target, source, sibling) => {
           
            const items = document.querySelectorAll('.drag .form-check label');
            const result =  Array.from(items).map(el => `${el.textContent}`)
            setSelectedAnswer(JSON.stringify(result.map(str => str.replace(/\s/g, ''))))
            setCorrectOptions(JSON.stringify(endARRoptions))
         
            
          })
        }
      };

      let str1 = showQuestion.QUESTION_TEXT
      let newStr = str1.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

      useEffect(() => {

        const elements2 = document.getElementsByClassName("preview");
    
        const elements3 = document.getElementsByClassName("mathLabel");
    
        for (let i = 0; i < elements2.length; i++) {
          const element = elements2[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
        }
    
        for (let i = 0; i < elements3.length; i++) {
          const element = elements3[i];
    
          if (element) {
            if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
              window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
            }
          }
    
        }
    
    
      }, []);
    
  return (

<div className='ques pb-2'>

<h5 className='preview'>{showQuestion.QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${showQuestion.QUESTION_TEXT}`)}</h5>

{showQuestion.QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${showQuestion.QUESTION_FILE_NAME}`} controls style={{width:"100%", margin: "20px 0"}} />
          )}

<Col className="drag" ref={dragulaDecorator}>
  {Object.values(chooseArray).map((item, i) => (
    <Col key={i} md={6} >
      <div className= "form-check">
        <input
          className="form-check-input"
          type="radio"
          id={`flexRadioDefault${i}`}
          value={item}
        />
        <label
          className="form-check-label mathLabel"
          htmlFor={`flexRadioDefault${i}`}
        >
       <RiDragMove2Line className="drag-icon"/> 
       {item.includes("jpg") || item.includes("png") ?  <img src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${item.replace(/\#/g,'')}`} alt="" /> : <p> {item.replace(/\#/g,'')} </p> }
        </label>
      </div>
    </Col>
  ))}
</Col>
</div>
  )
}

export default OrdaringSkip
