import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trainingData: [],
  testData: [],
  chart:{}
};

export const reviewAnswerSlice = createSlice({
  name: "reviewAnswer",
  initialState,
  reducers: {
    addTrainingAnswers: (state, action) => {
      state.trainingData = action.payload;
    },
    addTestAnswers: (state, action) => {
      state.testData = action.payload;
    },
    chartDetails: (state, action) => {
      state.chart = action.payload;
    },
  },
});

export const { addTrainingAnswers, addTestAnswers ,chartDetails} = reviewAnswerSlice.actions;

export default reviewAnswerSlice.reducer;
