import React from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import PersonalInfo from "../Components/PersonalInfo/PersonalInfo";
import Specialization from "../Components/Specialization/Specialization";
import NotificationSetting from "../Components/NotificationSetting/NotificationSetting";
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FaLongArrowAltRight ,FaLongArrowAltLeft  } from "react-icons/fa";


function AccountSetting() {

  const userData = JSON.parse(localStorage.getItem("yaqees-user"));
  const userImg = localStorage.getItem("yaqees-userImage");
  const userFirstName = localStorage.getItem("yaqees-userFirstName");
  const userLastName = localStorage.getItem("yaqees-userLastName");
  const userPhone = localStorage.getItem("yaqees-userPhone");
  const userTitle = localStorage.getItem("yaqees-userTitle")

  const [active, setActive] = useState(1)


  const [selectedFile, setSelectedFile] = useState(userImg ? userImg : userData.image);

  const [specialize, setSpecialize] = useState(userTitle ? userTitle : userData.specialize === null ? "" : userData.specialize);

  const [Personal, setPersonal] = useState({ firstName: userFirstName || userFirstName === "" ? userFirstName : userData.first_name === null ? "" : userData.first_name, lastName: userLastName || userLastName === "" ? userLastName : userData.last_name === null ? "" : userData.last_name, phone: userPhone || userPhone === "" ? userPhone : userData.phone === null ? "" : userData.phone });

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  const langDir = localStorage.getItem("yaqees-language")

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleChange = (e) => {
    setPersonal({ ...Personal, [e.target.name]: e.target.value });
  };



  const handleConfirm = () => {
    (async () => {
      try {
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/storeInformation`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: selectedFile.includes("/uploads/") ? { firstname: Personal.firstName, lastname: Personal.lastName, phone: Personal.phone, specialize: specialize } : { firstname: Personal.firstName, lastname: Personal.lastName, phone: Personal.phone, image: selectedFile, specialize: specialize },
        });
        successNotify(result.data.message);
        localStorage.setItem("yaqees-userImage", result.data.data.image);
        localStorage.setItem("yaqees-userTitle", result.data.data.specialize === null ? "" : result.data.data.specialize);
        localStorage.setItem("yaqees-userFirstName", result.data.data.first_name === null ? "" : result.data.data.first_name);
        localStorage.setItem("yaqees-userLastName", result.data.data.last_name  === null ? "" : result.data.data.last_name);
        localStorage.setItem("yaqees-userPhone", result.data.data.phone === null ? "" : result.data.data.phone);
        navigate("/profile");
      } catch (err) {
        ErrorNotify(err.response.data.message);
      }
    })();
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };



  return (
    <div className="account-setting">
      <Container>
        <div className="setting-sec">
          <div className="d-flex align-items-center justify-content-between">
            <h5> {t("account-settings")} </h5>
            <Link to="/profile">
              {langDir === "ar" ? <FaLongArrowAltLeft className='back-icon' />  :   <FaLongArrowAltRight className='back-icon' /> }
          
           
            </Link>
          </div>
          <div className="setting-head">
            <h6 className={active === 1 ? 'active-class' : ""} onClick={() => setActive(1)}> {t("personal-information")} </h6>
            <h6 className={active === 2 ? 'active-class' : ""} onClick={() => setActive(2)}> {t("specialization")} </h6>
            <h6 className={active === 3 ? 'active-class' : ""} onClick={() => setActive(3)}> {t("notifications")} </h6>
          </div>

          {active === 1 && <PersonalInfo handleChange={handleChange} setSelectedFile={setSelectedFile} selectedFile={selectedFile} Personal={Personal} setActive={setActive} setPersonal={setPersonal}/>}
          {active === 2 && <Specialization setActive={setActive} setSpecialize={setSpecialize} />}
          {active === 3 && <NotificationSetting handleConfirm={handleConfirm} />}
        </div>
      </Container>
    </div>
  );
}

export default AccountSetting;
