import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavBarr from "./Components/NavBar/NavBarr";
import Header from "./Components/Header/Header";
import Tests from "./Pages/Tests";
import Footer from "./Components/Footer/Footer";
import NotFound from "./Components/NotFound/NotFound";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import { useState, useEffect } from "react";
import Cart from "./Components/Cart/Cart";
import TestQuestions from "./Pages/TestQuestions";
import TestInfo from "./Pages/TestInfo";
import TrainingQuestions from "./Pages/TrainingQuestions";
import BasicNavbar from "./Components/BasicNavbar/BasicNavbar";
import BasicFooter from "./Components/BasicFooter/BasicFooter";
import ConfirmYourAnswersTraining from "./Pages/ConfirmYourAnswersTraining";
import ConfirmYourAnswersTest from "./Pages/ConfirmYourAnswersTest";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import VerificationCodeForgetPw from "./Components/VerificationCodeForgetPw/VerificationCodeForgetPw";
import NewPassword from "./Components/NewPassword/NewPassword";
import ActivationCodeRegister from "./Components/ActivationCodeRegister/ActivationCodeRegister";
import Profile from "./Pages/Profile";
import AccountSetting from "./Pages/AccountSetting";
import NavBarLogin from "./Components/NavBarLogin/NavBarLogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TestReports from "./Pages/TestReports";
import TrainingReports from "./Pages/TrainingReports";
import axios from "axios";
import AttemptReport from "./Pages/AttemptReport";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import Packages from "./Pages/Packages";
import PackageInfo from "./Pages/PackageInfo";
import Blog from './Pages/Blog';
import BlogInfo from "./Pages/BlogInfo";
import PrintReports from "./Pages/PrintReports";
import Privacy from "./Pages/Privacy";
import TermsService from "./Pages/TermsService";



function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("yaqees-user")
  );

  const [userRegisterMail, setUserRegisterMail] = useState("");

  const [userForgetMail, setUserForgetMail] = useState("");

  const [show, setShow] = useState(false);

  const [cartData, setCartData] = useState([]);

  const [cartNum, setCartNum] = useState("");

  const [refresh, setRefresh] = useState(false);

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/cart-getAll`,
            headers: {
              Accept: "application/json",
              Username : "administrator",
              Password : "yaqess123456",
              Authorization: "Bearer " + token,
              token: token,
            }
          });
          setCartData(result.data.data);
          setCartNum(+result.data.data?.reduce((acc, item) => {
            const packageItem = item.package;
            if (Array.isArray(packageItem)) {
                if (!item.is_package) {
                    packageItem.forEach(pkg => acc.push(pkg.exam_id));
                }
            } else {
                if (!packageItem.is_package) {
                    acc.push(...packageItem.exams_ids);
                }
            }
            return acc;
        }, []).length + (+result.data.data.reduce((acc, item) => {
          const packageItem = item.package;
          if (Array.isArray(packageItem)) {
              packageItem.forEach(pkg => {
                  if (pkg.package_id !== null) {
                      acc.push(pkg.package_id);
                  }
              });
          } else {
              if (packageItem.package_id !== null) {
                  acc.push(packageItem.package_id);
              }
          }
          return acc;
      }, []).length));
        } catch (err) {
        }
      })();
    }

  }, [refresh, isLoggedIn]);

  useEffect(() => {
    (async () => {
      try {
        const purchase = localStorage.getItem("yaqees-purchase")
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/check-payment?ref=${purchase}`,
          headers: {
            Accept: "application/json",
            Username : "administrator",
            Password : "yaqess123456",
            Authorization: "Bearer " + token,
            token: token,
          },
        });
        if (result.data.data.status === "PURCHASED") {
          try {
            await Promise.all(
              result.data.data.data.map(async (item) => {
                const result2 = await axios({
                  method: "POST",
                  url: `${process.env.REACT_APP_BASE_URL}/add-exam-to-user`,
                  headers: {
                    Accept: "application/json",
                    Username : "administrator",
                    Password : "yaqess123456",
                    Authorization: "Bearer " + token,
                    token: token,
                  },
                  data: { exam_ids: item.exams.map((item) => item.exam_id).toString(), package_id: item.package_id },
                });
              })
            );
            localStorage.removeItem("yaqees-purchase");
            setRefresh(!refresh);
          } catch (error) {
          }
        }
        setRefresh(!refresh);
      } catch (err) {
      }
    })();
  }, []);



  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {isLoggedIn ? (
                  <>
                    <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                    <Profile />
                  </>
                ) : (
                  <>
                    <Header />
                    <NavBarr />
                    <Home />
                  </>
                )}
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/register"
            element={<Register setUserRegisterMail={setUserRegisterMail} setIsLoggedIn={setIsLoggedIn}/>}
          />
          <Route
            path="/activation-code-register"
            element={
              <ActivationCodeRegister
                userRegisterMail={userRegisterMail}
                setUserRegisterMail={setUserRegisterMail}
              />
            }
          />
          <Route
            path="/forget-password"
            element={<ForgetPassword setUserForgetMail={setUserForgetMail} />}
          />
          <Route
            path="/forget-password-code"
            element={
              <VerificationCodeForgetPw userForgetMail={userForgetMail} />
            }
          />
          <Route
            path="/new-passowrd"
            element={
              <NewPassword
                userForgetMail={userForgetMail}
                setUserForgetMail={setUserForgetMail}
              />
            }
          />
          <Route
            path="/exams"
            element={
              <>
                {isLoggedIn ? (
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <NavBarr />
                )}
                <Tests setRefresh={setRefresh} refresh={refresh} cartData={cartData} />
                <Footer />
              </>
            }
          />
          <Route
            path="/packages"
            element={
              <>
                {isLoggedIn ? (
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <NavBarr />
                )}
                <Packages setRefresh={setRefresh} refresh={refresh} cartData={cartData} />
                <Footer />
              </>
            }
          />
          <Route
            path="/blog"
            element={
              <>
                {isLoggedIn ? (
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <NavBarr />
                )}
                <Blog setRefresh={setRefresh} refresh={refresh} cartData={cartData} />
                <Footer />
              </>
            }
          />
          <Route
            path="/exam-info/:id/:type"
            element={
              <>
                {isLoggedIn ? (
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <NavBarr />
                )}
                <TestInfo setRefresh={setRefresh} refresh={refresh} />
                <Footer />
              </>
            }
          />
          <Route
            path="/package-info/:id"
            element={
              <>
                {isLoggedIn ? (
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <NavBarr />
                )}
                <PackageInfo setRefresh={setRefresh} refresh={refresh} />
                <Footer />
              </>
            }
          />
          <Route
            path="/blog-info/:id"
            element={
              <>
                {isLoggedIn ? (
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                ) : (
                  <NavBarr />
                )}
                <BlogInfo setRefresh={setRefresh} refresh={refresh} />
                <Footer />
              </>
            }
          />
          <Route
            path="/cart"
            element={
              <>
                {isLoggedIn ? <>
                  <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                  <Cart refresh={refresh} setRefresh={setRefresh} cartData={cartData} />
                  <Footer />
                </> : <Navigate to="/login" />}
              </>
            }
          />
          <Route
            path="/profile"
            element={
              isLoggedIn ? (
                <>
                  {isLoggedIn ? (
                    <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                  ) : (
                    <NavBarr />
                  )}
                  <Profile refresh={refresh} />
                  <Footer />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/account-setting"
            element={
              isLoggedIn ? (
                <>
                  {isLoggedIn ? (
                    <NavBarLogin cartNum={cartNum} setIsLoggedIn={setIsLoggedIn} />
                  ) : (
                    <NavBarr />
                  )}
                  <AccountSetting />
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/training-questions/:id"
            element={
              isLoggedIn ? <TrainingQuestions /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/exam-questions/:id"
            element={isLoggedIn ? <TestQuestions /> : <Navigate to="/login" />}
          />
          <Route
            path="/review-training-answers"
            element={<ConfirmYourAnswersTraining />}
          />
          <Route
            path="/review-exam-answers"
            element={
              isLoggedIn ? <ConfirmYourAnswersTest /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/exam-report"
            element={isLoggedIn ? <TestReports /> : <Navigate to="/login" />}
          />
          <Route path="/training-report" element={isLoggedIn ? <TrainingReports /> : <Navigate to="/login" />} />
          <Route path="/print-report/:attempId/:examType" element={isLoggedIn ? <PrintReports /> : <Navigate to="/login" />} />

          <Route
            path="/attempt-report/:attempId/:examType"
            element={isLoggedIn ? <AttemptReport /> : <Navigate to="/login" />}
          />
          <Route path="/about" element={<>
            {isLoggedIn ? (
              <>
                <NavBarLogin setIsLoggedIn={setIsLoggedIn} />
                <About />
              </>
            ) : (
              <>
                <NavBarr />
                <About />
              </>
            )}
            <Footer />
          </>
          } />
          <Route path="/contact-us" element={<>
            {isLoggedIn ? (
              <>
                <NavBarLogin setIsLoggedIn={setIsLoggedIn} />
                <ContactUs />
              </>
            ) : (
              <>
                <NavBarr />
                <ContactUs />
              </>
            )}
            <Footer />
          </>
          } />
          <Route
            path="*"
            element={
              <>
                <BasicNavbar /> <NotFound /> <BasicFooter />
              </>
            }
          />
                    <Route
            path="/privacy"
            element={
              <>
                <BasicNavbar /> <Privacy /> <BasicFooter />
              </>
            }
          />
                         <Route
            path="/terms-of-service"
            element={
              <>
                <BasicNavbar /> <TermsService /> <BasicFooter />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        style={{
          marginTop: "12px",
          textAlign: "center",
          zIndex: "99999999999999",
        }}
      />
    </div>
  );
}

export default App;
