import { combineReducers, configureStore } from "@reduxjs/toolkit";
import reviewsAnswerReducer from "../Reducers/reviewsAnswerSlice";
import storage from "redux-persist/lib/storage"
import { persistReducer ,  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,} from "redux-persist";

const persistConfig = {
  key:"root",
  version: 1,
  storage
}

const reducer = combineReducers({
   review: reviewsAnswerReducer,
})

const persistedReducer = persistReducer(persistConfig, reducer )

export const store = configureStore({
  reducer: persistedReducer, 
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
