import React, { useState, useEffect } from "react";
import { Navbar, Container } from "react-bootstrap";
import "./BasicNavbar.css";
import { Link } from "react-router-dom";
import logo from "../../Assets/images/logo.png";

function BasicNavbar() {
    const [navbar, setNavbar] = useState(false);

    const changeNavColor = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    useEffect(() => {
        changeNavColor();
        window.addEventListener("scroll", changeNavColor);
    });

    return (
        <Navbar expand="lg" className={navbar ? "navbar-dark" : "navbar"}>
            <Container>
                <Link className="mob-logo" to="/">
                    <img className="logo" src={logo} alt="" />
                </Link>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Link className="not-mob-logo" to="/">
                        <img className="logo" src={logo} alt="" />
                    </Link>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default BasicNavbar;
