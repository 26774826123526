import React from "react";
import "./ImproveSection.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import img1 from "../../Assets/images/report1.png";
import img2 from "../../Assets/images/report2.png";
import { useTranslation } from "react-i18next";

function ImproveSection() {

  const { t } = useTranslation();

  return (
    <Container className="improve-sec">
      <h5> {t("develop-yaqees")} </h5>
      <Row>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title> {t("accurate-reports")} </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {t("accurate-info")}
              </Card.Subtitle>
              <Card.Text className="text3"><img className="w-100 h-100" src={img1} alt="" /></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title> {t("real-simulation")}  </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {t("real-info")}
              </Card.Subtitle>
              <Card.Text className="text4"><img className="w-100 h-100" src={img2} alt="" /></Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ImproveSection;
