import React , { useEffect } from 'react'
import Parse from "html-react-parser";
import { Col, Row } from 'react-bootstrap';

function MultiChooseSkip({ showQuestion, checked, setChecked, selectedAnswer, setSelectedAnswer ,setCorrectOptions}) {

  const chooseArray = JSON.parse(showQuestion?.QUS_OPTION)

  const addAnswer = (value) => {
    if(selectedAnswer.length > 0){
      const d = JSON.parse(selectedAnswer)
      setSelectedAnswer(JSON.stringify(([...d, value])))
    }else{
      setSelectedAnswer(JSON.stringify([...selectedAnswer, value]))
    }
    
  };

  const deleteAnswer = (value) => {
    const newAnswers = JSON.parse(selectedAnswer).filter((item) => item !== value);

    setSelectedAnswer(JSON.stringify(newAnswers));
  };

  const handleChooseChange = (value) => {
    if (selectedAnswer.includes(value)) {
      deleteAnswer(value);
    } else {
      addAnswer(value);
    }
  };

  setCorrectOptions(!Object.values(JSON.parse(showQuestion.QUS_CORRECT_RESPONSE)).map(element =>selectedAnswer.includes(JSON.stringify(element))).includes(false) && JSON.parse(selectedAnswer).length === Object.values(JSON.parse(showQuestion.QUS_CORRECT_RESPONSE)).length ? "true" :"")

  let str = showQuestion.QUESTION_TEXT
  let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');

  useEffect(() => {

    const elements2 = document.getElementsByClassName("preview");

    const elements3 = document.getElementsByClassName("mathLabel");

    for (let i = 0; i < elements2.length; i++) {
      const element = elements2[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements3.length; i++) {
      const element = elements3[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }

    }


  }, []);

  return (

    <div className='ques pb-2'>

       <h5 className='preview'>{showQuestion.QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${showQuestion.QUESTION_TEXT}`)}</h5>

      {showQuestion.QUESTION_FILE_NAME && (
        <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${showQuestion.QUES_TYPE}/${showQuestion.QUESTION_FILE_NAME}`} controls style={{ width: "100%", margin: "20px 0" }} />
      )}

      <Row>
        {Object.values(chooseArray).map((item, i) => (
          <Col key={i} md={6} >
            <div className={checked.includes(i) ? "active-radio" : "form-check"}>
              <input
                className="form-check-input"
                type="radio"
                name="answer"
                id={`flexRadioDefault${i}`}
                value={item}
                checked=""
                onClick={() => {
                  setChecked(checked.includes(i) ? checked.filter(item => item !== i) : [...checked, i]);
                }}
                onChange={() => handleChooseChange(item)}

              />
              <label
                className="form-check-label mathLabel"
                htmlFor={`flexRadioDefault${i}`}
              >
                {Parse(`${item}`)}
              </label>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default MultiChooseSkip

