import React from 'react'
import { Col, Row } from 'react-bootstrap'
import img1 from "../../Assets/images/favorite-chart.png"
import img2 from "../../Assets/images/clipboard-text.png"
import img3 from "../../Assets/images/clock-g.png"
import img4 from "../../Assets/images/clock-y.png"
import "./ProfileAnalysis.css"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend, PointElement, LineElement, Filler, } from 'chart.js';
import { PolarArea, Radar } from 'react-chartjs-2';


function ProfileAnalysis() {

    const percentage = 50;

    const correctAnswersPercentage = 40;
    const wrongAnswersPercentage = 40;
    const skipAnswersPercentage = 20;


    ChartJS.register(RadialLinearScale, ArcElement, PointElement, LineElement, Filler, Tooltip, Legend);


    const data = {
        labels: [`إجابات صحيحة ${correctAnswersPercentage}%`, `إجابات خطأ ${wrongAnswersPercentage}%`, ` تم تخطي السؤال ${skipAnswersPercentage}%`],
        datasets: [
            {
                data: [`${correctAnswersPercentage}`, `${wrongAnswersPercentage}`, `${skipAnswersPercentage}`],
                backgroundColor: [
                    '#4DB847',
                    '#ED254E',
                    '#D9D9D9',
                ],
                borderWidth: 3,
            },
        ],
    };

    const data2 = {
        labels: ['التذكر', 'التحليل', 'التطبيق', 'التركيب', 'الفهم', 'التقويم'],
        datasets: [{
            label: 'My First Dataset',
            data: [65, 59, 90, 81, 56, 55],
            backgroundColor: '#84cdff82',
            borderColor: '#84CDFF',
            borderWidth: 1,
            pointRadius: 1,

        }, {
            label: 'My Second Dataset',
            data: [28, 48, 40, 19, 96, 27],
            backgroundColor: '#ffa5af85',
            borderColor: '#FFA5AF',
            borderWidth: 1,
            pointRadius: 1,
        }, {
            label: 'My third Dataset',
            data: [48, 88, 60, 19, 26, 87],
            backgroundColor: '#aaffa591',
            borderColor: '#AAFFA5',
            borderWidth: 1,
            pointRadius: 1,
        }, {
            label: 'My four Dataset',
            data: [88, 48, 70, 92, 26, 87],
            backgroundColor: '#ffe5998f',
            borderColor: '#FFE599',
            borderWidth: 1,
            pointRadius: 1,
        }],
    };

    return (
        <div className='profile-analysis'>
            <Row>
                <Col xs={12} md={3}>
                    <div className='card-info'>
                        <div className='bg-icon'>
                            <img src={img1} alt='' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p>أعلى درجة</p>
                            <h6>30/30</h6>
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={3}>
                    <div className='card-info'>
                        <div className='bg-icon'>
                            <img src={img2} alt='' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p>عدد الاختبارات المحلولة</p>
                            <h6>12</h6>
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={3}>
                    <div className='card-info'>
                        <div className='bg-icon-g'>
                            <img src={img3} alt='' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p>متوسط الوقت للامتحان</p>
                            <h6>23 دقيقة</h6>
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={3}>
                    <div className='card-info'>
                        <div className='bg-icon-y'>
                            <img src={img4} alt='' />
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p>متوسط الوقت للسؤال</p>
                            <h6>21 ثانية</h6>
                        </div>
                    </div>

                </Col>
            </Row>

            <Row>
                <Col xs={12} md={4}>
                    <div className='analysis-charts'>
                        <div>
                            <h5>متوسط الدرجات</h5>
                            <p>تحقق من درجاتك لقياس مستوى استعدادك</p>
                        </div>
                        <hr />
                        <div style={{ width: 200, margin: "40px auto" }}>
                            <CircularProgressbar minValue value={percentage} text={`${percentage}%`} strokeWidth={8} styles={buildStyles({ pathColor: `#005C9A`, textColor: '#1B1A1D', trailColor: '#d6d6d67d' })} />
                        </div>

                        <div className='d-flex align-items-center justify-content-between'>
                            <p>أقل درجة <span>19</span></p>
                            <p>أعلى درجة <span>30</span></p>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={4}>
                    <div className='analysis-charts'>
                        <div>
                            <h5>تحليل الأداء</h5>
                            <p>يعتمد تحليل الأداء على مستوى صعوبة الامتحان</p>
                        </div>
                        <hr />
                        <div>
                            <PolarArea data={data} options={{
                                plugins: {
                                    legend: {
                                        labels: {
                                            font: {
                                                family: "'Noto Naskh Arabic', sans-serif",
                                            },
                                            color: "black",
                                        },
                                        position: "bottom"
                                    }
                                },
                            }} />
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={4}>
                    <div className='analysis-charts'>
                        <div>
                            <h5>نقاط القوة والضعف</h5>
                            <p>تحليل نقاط القوة والضعف وفق المستوى المعرفي  </p>
                        </div>
                        <hr />
                        <div>
                            <Radar data={data2} options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    }
                                }
                            }} />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ProfileAnalysis