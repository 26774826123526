import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function ContactUs() {

    const { t } = useTranslation();

    return (
        <div className='parent-contact'>
            <Container>
                <div className="section1">  <h5> {t("contact-us")} </h5> </div>
                <div className="divider-border divider-border-center element-top-10 element-bottom-10 os-animation animated fadeIn" data-os-animation="fadeIn" data-os-animation-delay="0.1s" style={{ height: "3px", animationDelay: "0.1s" }}>
                    <div className="divider-border-inner" style={{ background: "black", width: "60px" }}></div>
                </div>
                <Row>
                    <Col md={4}>
                        <h2 className="text-left  element-top-20 element-bottom-20 text-normal normal default" data-os-animation="none" data-os-animation-delay="0s"> {t("country1")} </h2>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p> {t("address1")} <br />
                            {t("city1")} </p>
                            <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                                <p> {t("phone1")} </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h2 className="text-left  element-top-20 element-bottom-20 text-normal normal default" data-os-animation="none" data-os-animation-delay="0s"> {t("country2")} </h2>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p> {t("address2")} <br />
                            {t("city2")} </p>
                        </div>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p className="font_8"> {t("phone2")} </p>
                        </div>

                    </Col>
                    <Col md={4}>
                        <h2 className="text-left  element-top-20 element-bottom-10 text-normal normal default" data-os-animation="none" data-os-animation-delay="0s"> {t("country3")} </h2>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-10" data-os-animation="none" data-os-animation-delay="0s">
                            <p> {t("address3")} <br />
                            {t("city3")} </p>
                        </div>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p className="font_8"> {t("phone3")} </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h2 className="text-left  element-top-20 element-bottom-20 text-normal normal default" data-os-animation="none" data-os-animation-delay="0s">
                        {t("country4")}</h2><div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p className='address-ksa'> {t("address4")} <br />
                            {t("city4")} </p>
                        </div>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p className="font_8"> {t("phone4")} </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h2 className="text-left  element-top-20 element-bottom-20 text-normal normal default" data-os-animation="none" data-os-animation-delay="0s">
                        {t("country5")} </h2><div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p> {t("address5")} </p>
                        </div>
                        <div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p> {t("phone5")} </p>
                            <div id="gtx-trans" style={{ position: "absolute", left: "418px", top: "-16.6px" }}>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <h2 className="text-left  element-top-20 element-bottom-20 text-normal normal default" data-os-animation="none" data-os-animation-delay="0s">
                        {t("country6")} </h2><div className="col-text-1 text-normal  element-top-20 element-bottom-20" data-os-animation="none" data-os-animation-delay="0s">
                            <p> {t("phone6")} </p>
                            <div> {t("mobile6")} </div>
                        </div>
                    </Col>
                </Row>

                <div className="row vertical-default">
                    <div className="col-md-12     text-default small-screen-default">
                        <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                            <div className="wpb_wrapper">
                                <iframe src="https://www.google.com/maps/d/embed?mid=1fG76opj2m71LSHLbBxxdXTafd18" width="100%" height="480"></iframe>
                            </div>
                        </div>
                    </div> </div>
            </Container>
        </div>
    )
}

export default ContactUs
