import React, { useState } from "react";
import "./Header.css";
import { IoIosClose } from 'react-icons/io';

function Header() {

  const [hide, setHide] = useState(false)

  const hideHeader = () => {
    setHide(true)
  }

  return (
    <div className={hide ? "hide-header" : "header"}>
      <p> سجّل الآن لتحصل على خصم 25 % على جميع الاختبارات </p>
      <IoIosClose className="close-header-icon" onClick={hideHeader} />
    </div>
  );
}

export default Header;
