import React, { useState } from "react";
import "./PackageInfoHeader.css";
import { Col, Container, Row } from "react-bootstrap";
import { addTestAnswers, addTrainingAnswers } from "../../Redux/Reducers/reviewsAnswerSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FcRules } from "react-icons/fc";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";




function PackageInfoHeader({ data, id, promoData, promoRefresh, setPromoRefresh, addToCart, submittingHeader, setSubmittingHeader, handleBuyFree }) {

  const [promoCode, setPromoCode] = useState("");

  const [submittingPromo, setSubmittingPromo] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const token = JSON.parse(localStorage.getItem("yaqees-user"))?.token;

  

  const discount = promoData.map(item =>item.discount).reduce((a,b)=> a + b , 0);

  const startTest = () => {
    (async () => {
      if (token) {
        return null
      } else {
        ErrorNotify("يجب تسجيل الدخول اولا");
      }
    })();
  };


  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: 'Almarai' }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };


  return (
    <div className="test-info-header">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col md={8}>
            <h5>{data.name}</h5>
            {/* <p>
              {data.description}
            </p> */}
            {data.user_package === true ? <p className="buy-done-text"> <FaCheck className="check-done" /> {t("purchased")} </p> :
              <>
                {data.price === 0 || promoData[0]?.is_availability === true ? (
                  <div className="d-flex flex-column buy-btn start-test">
                                   <div className="d-flex align-items-center mb-4">
                <p className="mb-0">  السعر :<br/>  </p>  <p className="text-price">  {t("free")} </p></div>
                    <Button
                      htmlType="submit"
                      loading={submittingHeader}
                      className="start-free-btn"
                      onClick={() => handleBuyFree(setSubmittingHeader, data.exams_ids, data.id)}
                    >
                      {!submittingHeader && t("buy-package")}
                    </Button>
                  </div>
                ) : (
                  <div className=" d-flex flex-column buy-btn">
                  <div className="d-flex align-items-center justify-content-start mb-4"><p>  السعر :<br/>  </p>  <p className="text-price"> {discount ? (data.price - discount) : data.price} $
                     {discount ?  <span> - {t("instead")} {data.price} $ </span> : ""} </p> </div>
                                    <Button
                      htmlType="submit"
                      loading={submittingHeader}
                      className="add-test-btn"
                      onClick={() => addToCart(setSubmittingHeader)}
                    >
                      {!submittingHeader && <p> {t("buy-now")} </p>}
                    </Button>
                  </div>
                )}</>}
          </Col>
          <Col md={4}>
            <img className="sec-img" src={data.image} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PackageInfoHeader;

