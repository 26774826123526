import React from 'react'
import { useStopwatch } from 'react-timer-hook';
import "./TimerReview.css"


function TimerReview() {

    const time = JSON.parse(localStorage.getItem("timer"))

    const stopwatchOffset = new Date(); stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + ((time.hours * 3600) + (time.minutes * 60) + (time.seconds)));

    const { seconds, minutes, hours } = useStopwatch({ autoStart: true , offsetTimestamp:stopwatchOffset});

    localStorage.setItem("fullTimer", JSON.stringify({ seconds, minutes, hours }))
   
   
    const formatTime = (time) => {
        return String(time).padStart(2, '0')
    }

    
    return (
        <div className='timer'>
            <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:<span>{formatTime(seconds)}</span>
        </div>

    )
}


export default TimerReview
