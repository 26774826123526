import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ChooseQues.css";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";
import Parse from "html-react-parser";
import axios from "axios";
import { RiNotificationBadgeFill } from "react-icons/ri";
import { TbBulbFilled } from "react-icons/tb";
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";


function ChooseQues({
  data,
  index,
  setChangeBtn,
  checked,
  setChecked,
  showAnswer,
  selectedAnswer,
  setSelectedAnswer,
  setCorrectOptions,
  type,
  renderMathType,
  showQuestionSection,
  setShowQuestionSection
}) {

  const { t } = useTranslation();

  const chooseArray = JSON.parse(data[index]?.QUS_OPTION);

  const handleChooseChange = (e) => {
    setSelectedAnswer(e.target.value);
    setChangeBtn(2);
    setCorrectOptions(Object.values(JSON.parse(data[index].QUS_CORRECT_RESPONSE)).toString())
  };


  let str = data[index].QUESTION_TEXT
  let newStr = str.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');



  let strAnswer = data[index].ANSWER_EXPLANATION
  let newStrAnswer = strAnswer.replace(/src\s*=\s*"/g, 'src="https://test4you.me/');


  const [reminderCard, setReminderCard] = useState([]);

  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `https://testcards.weghat.com/public/api/reminder-card/get/${data[index].CAT_ID}/${data[index].ST_LO_ID}`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        setReminderCard(result.data);
        setLoading(false);
      } catch (err) {
      }
    })();
  }, [index]);


  useEffect(() => {

    setShowQuestionSection(true);

    const elements = document.getElementsByClassName("explain");

    const elements2 = document.getElementsByClassName("preview");

    const elements3 = document.getElementsByClassName("mathLabel");

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements2.length; i++) {
      const element = elements2[i];
      
      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }
    }

    for (let i = 0; i < elements3.length; i++) {
      const element = elements3[i];

      if (element) {
        if (window.com && window.com.wiris && window.com.wiris.js && window.com.wiris.js.JsPluginViewer) {
          window.com.wiris.js.JsPluginViewer.parseElement(element, true, function () { });
        }
      }

    }


  }, [index, renderMathType ,showQuestionSection]);

  return (
    <div className="choose-question d-flex  justify-content-center">
      <Container>
      {showQuestionSection &&
        <div className="ques pt-5 pb-2">
          <div className="d-flex align-items-start justify-content-between">
          <div className="preview">
          
            <h5 >{data[index].QUESTION_TEXT.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${data[index].QUESTION_TEXT}`)}</h5>
         </div>
         
            {reminderCard.message === "success" && type === "training" ? <div className="bulb-icon"> <TbBulbFilled className="reminder-icon" onClick={handleShow} />  </div> : ""}
          </div>

          {data[index].QUESTION_FILE_NAME && (
            <audio src={`https://d1rjx43971wdif.cloudfront.net/CONTENT/${data[index].QUES_TYPE}/${data[index].QUESTION_FILE_NAME}`} controls />
          )}

          <Row>
            {Object.values(chooseArray).map((item, i) => {
              if (item.includes("src")) {
                let newStr = item.replace(/src\s*=\s*"/g, 'src="https://test4you.me/')
                return <Col key={i} md={3}>
                  <div className={checked === i ? "active-radio" : "form-check"} style={{ height: "100%", padding: "10px 0" }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="answer"
                      id={`flexRadioDefault${index}${i}`}
                      value={item}
                      checked=""
                      onClick={() => {
                        if (showAnswer === false) {
                          setChecked(i);
                        } else {
                          return null;
                        }
                      }}
                      onChange={showAnswer === false ? handleChooseChange : null}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault${index}${i}`}
                      style={{ height: "100%" }}
                    >
                      {item.includes('src="/public') ? Parse(`${newStr}`) : Parse(`${item}`)}
                    </label>
                  </div>
                </Col>
              } else {

                return <Col key={i} md={6}>
                  <div className={checked === i ? "active-radio" : "form-check"}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="answer"
                      id={`flexRadioDefault${index}${i}`}
                      value={item}
                      checked=""
                      onClick={() => {
                        if (showAnswer === false) {
                          setChecked(i);
                        } else {
                          return null;
                        }
                      }}
                      onChange={showAnswer === false ? handleChooseChange : null}
                    />
                    <label
                      className="form-check-label mathLabel"
                      htmlFor={`flexRadioDefault${index}${i}`}
                    >
                      {Parse(`${item}`)}

                    </label>
                  </div>
                </Col>
              }
            })}

            {showAnswer === true && (
              <div>
                {selectedAnswer === Object.values(JSON.parse(data[index].QUS_CORRECT_RESPONSE)).toString() ? (
                  <div className="parent-true">
                    <h5> {t("correct-answer")} <IoIosCheckmarkCircle className="true-icon" /></h5>
                    <div className="true-answer">
                      <h4> {t("explanation")} </h4>
                      <div className="my-3 explain">{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                    </div>
                  </div>
                ) : (
                  <div className="parent-false">
                    <h5> {t("incorrect-answer")} <IoIosCloseCircle className="false-icon" /> </h5>
                    <div className="false-answer">
                      <h4> {t("explanation")} </h4>
                      <div className="my-3 explain" >{data[index].ANSWER_EXPLANATION.includes('src="/public') ? Parse(`${newStrAnswer}`) : Parse(`${data[index].ANSWER_EXPLANATION}`)}</div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Row>
        </div>
         } 
      </Container>
      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: "9999999999999", fontFamily: 'Noto Naskh Arabic' }}>
        <Modal.Header closeButton>
        <Modal.Title style={{color:"#005C9A"}}> {t("reminder-card")} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "80vh", overflowY: "auto" }}>
          {loading ? <Loader/> :
            <div>
              {reminderCard.message === "success" && Parse(`${reminderCard?.data[0]?.content}`)}
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChooseQues;
